import React, { useEffect, useState } from "react"
import { animated, useTransition } from "react-spring"
import AddNewBudget from "./Add and Edit/AddNewBudget"
import { FaPlus } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getBudgets } from "../../Redux/slices/budgetSlice"
import ReactLoading from "react-loading"

const CfoBudgetScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBudgets())
  }, [])
  const { loading, budgets } = useSelector((state) => state.budgetReducer)
  console.log(budgets)

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full px-10 border-b-2 pb-3 mb-5">
        <h2 className="semi-bold text-lg lightFont">Budget</h2>
      </div>
      <>
        <div className="w-full my-10 px-10 flex border-b-2  justify-between">
          <h2 className="semi-bold text-lg py-3  text-[#061d5a]">History</h2>
          <button
            className="bg-[#061d5a] text-white rounded-3xl semi-bold my-2 flex gap-2 items-center	 px-8 py-0 text-[14px]"
            onClick={() => navigate("/add-new-budget")}
          >
            <FaPlus /> Add Budget
          </button>
        </div>
        <div className="w-full px-10 ">
          {loading && (
            <div className="w-full h-full flex items-center justify-center">
              <ReactLoading type="spin" color="#061d5a" width={"3%"} height={"3%"} />{" "}
            </div>
          )}
          {!loading && budgets && (
            <table className="w-full">
              <thead>
                <tr>
                  <th className="bg-[#e4e7eb] p-2 rounded-xl border-x-4 border-[#fff]">From</th>
                  <th className="bg-[#e4e7eb] p-2 rounded-xl border-x-4 border-[#fff]">To</th>
                  <th className="bg-[#e4e7eb] p-2 rounded-xl border-x-4 border-[#fff]">Status</th>

                  {/* <th className="bg-[#e4e7eb] p-2 rounded-xl border-x-4 border-[#fff]">Total caps</th>
                  <th className="bg-[#e4e7eb] p-2 rounded-xl border-x-4 border-[#fff] ">Deparments completed</th> */}
                </tr>
              </thead>
              <tbody>
                {budgets.map((budget, i) => (
                  <tr key={i}>
                    <td className="p-2 text-center">{budget.start_date}</td>
                    <td className="p-2 text-center">{budget.end_date}</td>
                    <td className="p-2 text-center">
                      <button
                        onClick={() => navigate(`/cfo-preview/${budget.id}${!budget.active ? `/open` : ``}`)}
                        className="bg-[#071c59] text-white  rounded-3xl semi-bold px-10 py-1 my-3"
                      >
                        preview
                      </button>
                      {/* <button
                        onClick={() => navigate(`/cfo/edit-budget/${budget.id}$`)}
                        className="bg-[#071c59] text-white  rounded-3xl semi-bold px-10 py-1 my-3"
                      >
                        Edit
                      </button> */}
                    </td>

                    {/* <td className="p-2 text-center">31000</td>
                    <td className="p-2 text-center">4/6</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </>
    </div>
  )
}

export default CfoBudgetScreen
