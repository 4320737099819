import React from "react"
import { useSelector } from "react-redux"
import FirstTable from "./Tables/FirstTable"
import SecondTable from "./Tables/SecondTable"

const Index = () => {
  const { dashboardData } = useSelector((state) => state.dashboardReducer)
  return (
    <div className="flex pl-20  gap-5 justify-between">
      <FirstTable dashboardData={dashboardData.top_premium} />
      <SecondTable dashboardData={dashboardData.top_broker} />
    </div>
  )
}

export default Index
