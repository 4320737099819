import React from "react"
import { useSelector } from "react-redux"
import BarChart2FirstRow from "./Charts/BarChart2FirstRow"
import BarChart3FirstRow from "./Charts/BarChart3FirstRow"
import BarChart4FirstRow from "./Charts/BarChart4FirstRow"
import BarChartFirstRow from "./Charts/BarChartFirstRow"

const FirstRow = () => {
  const { dashboardData } = useSelector((state) => state.dashboardReducer)

  return (
    <>
      <div className="flex flex-wrap justify-center gap-5 my-7 w-full">
        <div className="w-[48%] flex">
          {/* <p className="mb-5 border-b w-[50%] font-semibold pb-3"> Bar Chart Title</p> */}
          <div className="h-auto flex justify-start items-center border-r relative w-20 mx-2">
            <div
              className="absolute w-full lightFont"
              style={{ transform: "rotate(270deg)", whiteSpace: "nowrap", fontFamily: "boldDin", letterSpacing: "1rem", left: "10%", bottom: "40%" }}
            >
              {dashboardData.aging.title}
            </div>
          </div>
          <BarChartFirstRow dashboardData={dashboardData.aging} />
        </div>
        <div className="w-[48%] flex">
          <div className="h-auto flex justify-start items-center border-r relative w-20 mx-2">
            <div
              className="absolute w-full lightFont"
              style={{ transform: "rotate(270deg)", whiteSpace: "nowrap", fontFamily: "boldDin", letterSpacing: "1rem", left: "10%", bottom: "20%" }}
            >
              Chart Title
            </div>
          </div>
          <BarChart2FirstRow dashboardData={dashboardData.sales_type_count_per_policy_type} />
        </div>
      </div>

      <div className="flex flex-wrap justify-center gap-5 my-7 w-full">
        <div className="w-[48%] flex">
          {/* <p className="mb-5 border-b w-[50%] font-semibold pb-3"> Bar Chart Title</p> */}
          <div className="h-auto flex justify-start items-center border-r relative w-20 mx-2">
            <div
              className="absolute w-full lightFont"
              style={{ transform: "rotate(270deg)", whiteSpace: "nowrap", fontFamily: "boldDin", letterSpacing: "1rem", left: "10%", bottom: "20%" }}
            >
              Chart Title
            </div>
          </div>
          <BarChart3FirstRow dashboardData={dashboardData.sales_type_revenue_per_policy_type} />
        </div>
        <div className="w-[48%] flex">
          {/* <p className="mb-5 border-b w-[50%] font-semibold pb-3"> Bar Chart Title</p> */}
          <div className="h-auto flex justify-start items-center border-r relative w-20 mx-2">
            <div
              className="absolute w-full lightFont"
              style={{ transform: "rotate(270deg)", whiteSpace: "nowrap", fontFamily: "boldDin", letterSpacing: "1rem", left: "10%", bottom: "20%" }}
            >
              Chart Title
            </div>
          </div>
          <BarChart4FirstRow />
        </div>
      </div>
    </>
  )
}

export default FirstRow
