import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useDispatch } from "react-redux"
import { getOneBudget } from "../../../../Redux/slices/budgetSlice"
import { useParams } from "react-router-dom"

const FilterHeader = ({ type, setType, departments, withType, value, setValue }) => {
  //   const [tabValue, setTabValue] = useState("2")
  //   const options = [
  //     { department: "op", id: 1 },
  //     { department: "op2", id: 2 },
  const dispatch = useDispatch()
  //   ]
  const [inputValue, setInputValue] = React.useState("")
  const { id } = useParams()

  const handleChange = (event) => {
    let val = event.target.value
    dispatch(getOneBudget({ id, filter: val }))
    setType(event.target.value)
  }
  return (
    <div className="flex gap-7">
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
        getOptionLabel={(option) => {
          return option.name
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        id="controllable-states-demo"
        options={departments}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Department" />}
      />
      {withType && (
        <div className="w-[20%]">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={type} label="Age" onChange={handleChange}>
              <MenuItem value={"category"}>By Category</MenuItem>
              <MenuItem value={"account"}>By Account</MenuItem>
              {/* <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  )
}

export default FilterHeader
