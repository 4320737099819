import React, { useState } from "react"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Tab } from "@mui/material"

const DashboardTabs = ({value,setValue}) => {
  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          TabIndicatorProps={{ sx: { height: 4, backgroundColor: "#af1027" } }}
          sx={{
            "& button": { fontSize: "70%" },
            // width: "100%",
            "& button.Mui-selected": { fontWeight: "600", color: "#af1027" },
          }}
          onChange={handleChangeTab}
          aria-label="lab API tabs example"
        >
          <Tab label="Revenue" value="1" />
          <Tab label="Expenses" value="2" />
          <Tab label="Utilization" value="3" />
          <Tab label="Payroll" value="4" />

          {/* <Tab label="Category 4" value="4" /> */}
        </TabList>
      </Box>

      {/* <TabPanel value="1">Cat 1</TabPanel>
      <TabPanel value="2">Cat 2</TabPanel>
      <TabPanel value="3">Cat 3</TabPanel> */}
      {/* <TabPanel value="4">Cat 4</TabPanel> */}
    </TabContext>
  )
}

export default DashboardTabs
