import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

export default function DoughnutChartSecondRow({ dashboardData }) {
  const data = {
    labels: dashboardData?.data?.map((item) => item.policy_type__name) || [],

    datasets: [
      {
        // label: "Total is",
        data: dashboardData?.data?.map((item) => item.policy_holder__count),
        backgroundColor: ["#1d2448", "#023d68", "#007ea7"],
      },
      // {
      //   label: "Total is",
      //   data: [12, 19, 3],
      //   backgroundColor: ["#1d2448", "#023d68", "#007ea7"],
      // },
    ],
  }
  return (
    <div>
      <p className="border-b">{dashboardData?.title}</p>
      <div className="m-0 p-0" style={{ width: "20rem", height: "20rem" }}>
        <Doughnut
          options={{
            plugins: {
              legend: {
                position: "right",
              },
            },
          }}
          data={data}
        />
      </div>
    </div>
  )
}
