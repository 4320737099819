import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API } from "../API/api"

export const loginAction = createAsyncThunk("loginAction", async (user, { rejectWithValue }) => {
  try {
    const { data } = await API.post(`/auth/login/`, {
      username: user.email,
      password: user.password,
    })
    localStorage.setItem("userInfo", JSON.stringify(data))
    return data
  } catch (error) {
    return rejectWithValue(error.response.data.non_field_errors[0])
  }
})

const initialState = {
  userInfo: null,
  loading: false,
  error: null,
}

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    resetLoginUser: (state) => {
      state.userInfo = null
      state.error = null
    },
  },
  extraReducers: {
    [loginAction.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [loginAction.fulfilled]: (state, action) => {
      // console.log(action.payload)
      state.loading = false
      state.userInfo = action.payload
    },
    [loginAction.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = postsSlice.actions

export const { resetLoginUser } = loginSlice.actions
export default loginSlice.reducer
