import React, { useEffect, useState } from "react"
import { InputAdornment, IconButton } from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import styles from "./loginScreen.module.css"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FaArrowRight } from "react-icons/fa"
import { loginAction } from "../../Redux/slices/loginSlice"
import ReactLoading from "react-loading"
import { addErrorMessage } from "../../Redux/slices/messageSlice"

const LoginScreen = () => {
  const [showPassword, setshowPassword] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  let navigate = useNavigate()

  const dispatch = useDispatch()

  const { error: err, loading } = useSelector((state) => state.loginInfo)
  let userInfo = JSON.parse(localStorage.getItem("userInfo"))

  const submitHandler = (e) => {
    setError(null)
    if (email === "" || password === "") {
      setError("Please Fill All Fields!")
      setTimeout(() => {
        setError("")
      }, 3000)
    } else {
      // let email = "admin"
      // let password = "admin"
      dispatch(loginAction({ email, password }))
    }
  }
  useEffect(() => {
    if (err) {
      setError(err)
    }
    if (userInfo) {
      navigate("/")
    }
  }, [err, userInfo])

  // useEffect(() => {
  //   // console.log("hello");
  //   const errorSetter = () => {
  //     setError(err)

  //     setTimeout(() => {
  //       setError("")
  //     }, 3000)
  //   }
  //   if (err) {
  //     errorSetter()
  //   } else if (userInfo) {
  //     // console.log(userInfo)
  //     navigate("/")
  //   }
  // }, [err, navigate, userInfo])

  return (
    <div className={styles.loginContainer}>
      <div className="w-screen p-5 py-7">
        <img src="logo-white.png" alt="logo" className="w-52" />
      </div>
      <div className={`p-4 lg:p-14 w-full ${styles.innerContainer}`}>
        <div className=" playfair-font mt-0  xl:mt-20 mb-14">
          <h1 className=" text-2xl xl:text-5xl text-white font-semibold">
            <span className="custom-gold block  xl:text-3xl text-[#af1027] mb-5 ">Med Right ERP ,</span> Our system to manage, analyze, <br />
            observe and monitor our data.
          </h1>
        </div>
        <div className={styles.form}>
          <p className="text-white text-xl mb-3">Login to your account</p>
          {/* <img src="logo-white.png" alt="logo" className="w-52" /> */}
          <input
            value={email}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submitHandler()
              }
            }}
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className={styles.password}>
            <input
              value={password}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitHandler()
                }
              }}
              type={showPassword ? "text" : "Password"}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputAdornment position="end" className={styles.visibilityIcon}>
              <IconButton
                onClick={() => {
                  setshowPassword(!showPassword)
                }}
              >
                {showPassword ? <VisibilityOff style={{ fill: "white" }} /> : <Visibility style={{ fill: "white" }} />}
              </IconButton>
            </InputAdornment>
          </div>
          {error && <p className="text-white ml-2">{error}</p>}
          {loading ? (
            <div className="w-full flex justify-end">
              <ReactLoading type="spin" color="#af1027" height={"8%"} width={"8%"} />
            </div>
          ) : (
            <div className={`${styles.buttons} flex items-end justify-end`}>
              <button className={styles.signinBtn} onClick={submitHandler}>
                Login <FaArrowRight />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LoginScreen
