import React from "react"
import styles from "../inputs.module.css"
import { FaChevronDown } from "react-icons/fa"
import NestedMenu from "../../../components/NestedMenu"
import { useDispatch, useSelector } from "react-redux"
import { insertFile } from "../../../Redux/slices/inputsSlice"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import moment from "moment"

const ThirdStep = ({ data, selectedType, files }) => {
  // const { loading, uploadedFile } = useSelector((state) => state.inputsReducer)
  const ourDate = new Date(43466)
  // console.log(moment(ourDate).format("YYYY-MM-DD"))

  ///////////////////// start date
  const [fromDate, setFromDate] = React.useState(dayjs(new Date()))
  const [toDate, setToDate] = React.useState(dayjs(new Date()))
  const [cfoTableView, setCfoTableView] = React.useState(false)
  const handleChange = (newValue) => {
    setFromDate(new Date(newValue))
  }
  const startDate = new Date()

  ///////////////////end date

  const dispatch = useDispatch()
  const handleInsert = () => {
    dispatch(
      insertFile({
        excel: files,
        settings: selectedType.default_settings.id,
      })
    )
  }
  return (
    <>
      {selectedType.name === "Payroll" && (
        <div className="flex gap-5">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              minDate={startDate}
              label="Starting from"
              inputFormat="DD/MM/YYYY"
              value={fromDate}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              minDate={startDate}
              label="Ending on"
              inputFormat="DD/MM/YYYY"
              value={toDate}
              onChange={(newValue) => setToDate(new Date(newValue))}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      )}
      <h3 className="m-3 mb-3 text-lg lightFont">Preview</h3>
      <div style={{ maxWidth: "90vw", overflowY: "auto" }}>
        <div className="!h-[60vh] overflow-scroll">
          <table className={`w-full border-b stickyTable  ${styles.inputsTable}`}>
            <thead>
              <tr className="bg-[#061d5a] text-white stickyRow p-1">
                {Object.keys(data[0]).map((o) => (
                  <th className=" border-x border-white p-2 bg-[#061d5a] " key={o}>
                    {o}
                    {/* <NestedMenu>
                  <div className="bg-white text-black flex px-2 justify-between items-center">
                    <p className="font-light">Mock</p>
                    <FaChevronDown />
                  </div>
                </NestedMenu> */}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => (
                <tr key={i}>
                  {Object.values(d).map((v, i) => (
                    <td key={i} className="text-center p-2 border-x">
                      {v}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full flex justify-end">
        <button onClick={handleInsert} className="bg-[#061d5a] text-white rounded-3xl semi-bold my-5 	 px-10 py-1">
          Submit
        </button>
      </div>
    </>
  )
}

export default ThirdStep
