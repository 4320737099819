import React, { useCallback, useState } from "react"
import { PieChart, Pie, Sector } from "recharts"

// const fakeData = [
//   { date: "Jan", failed: 3, name: "Jan", lossRatio: 80, processing: 2, succeeded: 3 },
//   { date: "Feb", failed: 2, name: "Frb", lossRatio: 70, processing: 1, succeeded: 7 },
//   { date: "March", failed: 3, name: "March", lossRatio: 60, processing: 3, succeeded: 4 },
//   { date: "April", failed: 2, name: "April", lossRatio: 40, processing: 6, succeeded: 5 },
//   { date: "May", failed: 3, name: "May", lossRatio: 90, processing: 3, succeeded: 5 },
//   { date: "June", failed: 2, name: "June", lossRatio: 50, processing: 5, succeeded: 3 },
// ]

const LossRatioPieChart = ({ dashboardData }) => {
  console.log(dashboardData)

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? "start" : "end"

    return (
      <g className="m-10">
        <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill="black">
          {payload.name}
        </text>
        <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill="black">
          {dashboardData?.amount * 100} %
        </text>

        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill="#061e5b"
        />
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke="#061e5b" fill="none" /> */}
        {/* <circle cx={ex} cy={ey} r={2} fill="#061e5b" stroke="none" /> */}
        {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Current Ratio`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {dashboardData.amount * 100} %
        </text> */}
      </g>
    )
  }

  const data = [
    { name: "Loss Ratio", value: dashboardData.amount * 100 },
    { name: "Renmaining", value: 100 - dashboardData.amount * 100 },
    // { name: "Loss Ratio", value: 98 },
    // { name: "Renmaining", value: 2 },
  ]
  const [activeIndex, setActiveIndex] = useState(0)
  // const onPieEnter = useCallback(
  //   (_, index) => {
  //     setActiveIndex(index)
  //   },
  //   [setActiveIndex]
  // )
  return (
    <div>
      <p className="border-b">{dashboardData.title}</p>
      <div className="m-0 p-0 flex items-center justify-center" style={{ width: "20rem", height: "20rem" }}>
        <PieChart className="mb-5 ml-5" width={300} height={200}>
          <Pie
            width={1000}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx={150}
            cy={100}
            innerRadius={60}
            outerRadius={80}
            fill="#af1027"
            dataKey="value"
            // onMouseEnter={onPieEnter}
          />
        </PieChart>
      </div>
    </div>
  )
}

export default LossRatioPieChart
