import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getOneBudget } from "../../../../Redux/slices/budgetSlice"
import { getDepartments } from "../../../../Redux/slices/dept_employees_slice"
import FilterHeader from "../../../../screens/CFObudgetScreen/CFOpreview/FilterHeader"
import PreviewByAccount from "./PreviewByAccount"
import PreviewByCategory from "./PreviewByCategory"
import ReactLoading from "react-loading"
import { useParams } from "react-router-dom"

const MainCfoPreviewTab = () => {
  const [mounted, setmounted] = useState(false)
  const [type, setType] = React.useState("category")
  const [value, setValue] = React.useState(null)
  const { loading, departments } = useSelector((state) => state.deptAndEmployeesReducer)
  const { loadingPreview: loadingBudget, cfoPreviewBudget } = useSelector((state) => state.budgetReducer)
  const { id } = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOneBudget({ id, filter: "category" }))
  }, [])

  useEffect(() => {
    if (mounted) {
      dispatch(getOneBudget({ id, filter: type, department: value?.id }))
    }
    setmounted(true)
  }, [value])

  return (
    <>
      <FilterHeader value={value} setValue={setValue} type={type} setType={setType} departments={departments} withType={true} />
      {loading || loadingBudget ? (
        <div className="w-full h-full flex items-center justify-center">
          <ReactLoading type="spin" color="#061d5a" width={"3%"} height={"3%"} />{" "}
        </div>
      ) : (
        <>
          <div className="w-full flex my-5 items-end justify-between border-b  mb-5">
            {/* <CfoBudgetTabs value={tabValue} setValue={setTabValue} /> */}
            {/* <PreviewByCategory /> */}
            {type === "category" && mounted && cfoPreviewBudget && <PreviewByCategory budget={cfoPreviewBudget} />}
            {type === "account" && mounted && cfoPreviewBudget && <PreviewByAccount budget={cfoPreviewBudget} />}
          </div>{" "}
        </>
      )}
    </>
  )
}

export default MainCfoPreviewTab
