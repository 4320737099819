import React from "react"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Bar } from "react-chartjs-2"
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChartFirstRow = ({ dashboardData }) => {
  const options = {
    maintainAspectRatio: false,
    // indexAxis: "y",
    responsive: true,
    barThickness: 10,
    scales: {
      x: {
        max: 1200,
      },
    },
    plugins: {
      // legend: {
      //   position: "top",
      // },
      legend: { display: false },
    },
  }

  // const dataTrial = {
  //   title: "T",
  //   x_label: "Months",
  //   y_label: "EGP",
  //   x_values: ["Jan", "Feb"],
  //   data: [
  //     {
  //       label: "Expenses",
  //       values: [1000, 2000],
  //     },
  //     {
  //       label: "Budget",
  //       values: [2000, 3000],
  //     },
  //   ],
  // }

  const data = {
    // labels: dashboardData.x_values,
    labels: dashboardData.data.map((d, i) => d.period),
    // datasets: [
    //   // {
    //   //   label: "Sales",
    //   //   data: salesData.map((d) => d.value),
    //   //   //   borderColor: "rgb(255, 99, 132)",
    //   //   backgroundColor: "#0a1033",
    //   //   //   barThickness: 10,
    //   //   borderRadius: 10,
    //   // },
    //   // {
    //   //   label: "Khaled",
    //   //   data: salesData.map((d) => d.value),
    //   //   //   borderColor: "rgb(255, 99, 132)",
    //   //   backgroundColor: "#AF1027",
    //   //   //   barThickness: 10,
    //   //   borderRadius: 10,
    //   // },
    //   {
    //     label: dataTrial.data[0].values[0].label,
    //     data: dataTrial.data.map((d) => d.values[0].amount),
    //     backgroundColor: "#0a1033",
    //     //   //   barThickness: 10,
    //     borderRadius: 10,
    //   },
    // ],
    // datasets: dashboardData.data.map((d, i) => {
    //   return {
    //     label: d.label,
    //     data: d.values,
    //     backgroundColor: "#0a1033",
    //     borderRadius: 10,
    //   }
    // }),
    datasets: [{ label: dashboardData.legend[0], data: dashboardData.data.map((d, i) => d.aging), backgroundColor: "#0a1033", borderRadius: 10 }],
  }

  return (
    <div className="m-0 p-0" style={{ width: "100%", height: "18rem" }}>
      <Bar options={options} data={data} />
    </div>
  )
}
export default BarChartFirstRow
