import { configureStore } from "@reduxjs/toolkit"
import loginReducer from "./slices/loginSlice"
import inputsReducer from "./slices/inputsSlice"
import deptAndEmployeesReducer from "./slices/dept_employees_slice"
import budgetReducer from "./slices/budgetSlice"
import messageReducer from "./slices/messageSlice"
import managerBudgetReducer from "./slices/managerBudgetSlice"
import commentsReducer from "./slices/commentsSlice"
import budgetStatusReducer from "./slices/budgetStatusSlice"
import dashboardReducer from "./slices/dashboardSlices/dashboardSlice"

const store = configureStore({
  reducer: {
    inputsReducer,
    loginInfo: loginReducer,
    deptAndEmployeesReducer,
    budgetReducer,
    messageReducer,
    managerBudgetReducer,
    commentsReducer,
    budgetStatusReducer,
    dashboardReducer,
  },
})

export default store
