import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { BrowserRouter, Route, Routes } from "react-router-dom"
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import "../node_modules/@fortawesome/fontawesome-free/css/all.css"
import { Provider } from "react-redux"
import store from "./Redux/store"

ReactDOM.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />}></Route>
      </Routes>
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById("root")
)
