import React from "react"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"
import { fixNum } from "../../../utils/fixNum"

const PreviewByAccount = ({ budget }) => {
  var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const months = diff()
  function diff() {
    var arr = []
    var datFrom = new Date(budget.data[0]?.accounts[0]?.months[0]?.month)
    var monthsLength = budget.data[0]?.accounts[0]?.months?.length
    var datTo = new Date(budget.data[0]?.accounts[0]?.months[monthsLength - 1]?.month)
    var fromYear = datFrom.getFullYear()
    var toYear = datTo.getFullYear()
    var diffYear = 12 * (toYear - fromYear) + datTo.getMonth()

    for (var i = datFrom.getMonth(); i <= diffYear; i++) {
      arr.push(monthNames[i % 12])
    }
    return arr
  }

  // calculate total for every month
  const totalPerCategory = (category, month) => {
    console.log(month)
    console.log(category)
    console.log(months)
    let totalActual = 0
    let totalBudget = 0
    let totalVariance = 0
    category.accounts.forEach((account) => {
      totalActual += account.months[month].expenses
      totalBudget += account.months[month].budget
      totalVariance += account.months[month].variance
    })

    return { totalActual, totalBudget, totalVariance }
  }
  return (
    <div className="  overflow-x-auto w-full">
      <div className="!h-[65vh] overflow-scroll">
        <table className=" stickyTable w-full border-b  tableTrial">
          {/* <thead>
         
        </thead> */}
          <tbody>
            <tr className="bg-[#061d5a] text-white p-1 stickyRow">
              <td rowSpan="2" style={{ width: "20%" }} className="text-center font-bold border-x-2 !bg-[#061d5a] border-white p-2 ">
                <div className="flex justify-center">
                  <p className="w-[10vw] r">Account</p>
                </div>{" "}
              </td>
              {months.map((month, index) => (
                <td colSpan="3" className=" text-center font-bold border-x border-white p-2 ">
                  {month}
                </td>
              ))}
            </tr>
            <tr className="stickyRow2">
              {/* <th className=" border-x border-white p-2 ">Category</th> */}
              {months.map((item, index) => (
                <>
                  <td style={{ zIndex: "-1" }} key={1} className="text-center p-2 px-7 border-y border-x ">
                    Actual
                  </td>
                  <td key={2} className="text-center p-2 px-7 border-y border-x ">
                    Budget
                  </td>
                  <td key={3} className="text-center p-2  px-7 border-y border-x ">
                    Variance
                  </td>
                </>
              ))}
            </tr>
            {budget.data.map((category) => (
              <>
                <tr>
                  <td className="text-center p-2 px-7 !bg-[#AF1027] font-semibold text-white w-[10rem]   ">{category.category}</td>{" "}
                  {/*  start just for empty grey spaces */}
                  {months.map((month, index) => (
                    <>
                      <td key={index} className="text-center text-white p-2  !bg-[#AF1027] ">
                        {fixNum(totalPerCategory(category, index).totalActual)}{" "}
                      </td>
                      <td key={index} className="text-center text-white p-2  !bg-[#AF1027] ">
                        {fixNum(totalPerCategory(category, index).totalBudget)}{" "}
                      </td>
                      <td key={index} className="text-center text-white p-2  !bg-[#AF1027] ">
                        {parseFloat(totalPerCategory(category, index).totalVariance).toFixed(2)} %
                      </td>
                    </>
                  ))}
                  {/* {months.map((item, index) => (
                    <td key={index} className="text-center p-2  !bg-[#AF1027] "></td>
                  ))}
                  {months.map((item, index) => (
                    <td key={index} className="text-center p-2  !bg-[#AF1027] "></td>
                  ))} */}
                  {/* end just for empty grey spaces */}
                </tr>

                {category.accounts.map((account) => (
                  <tr>
                    <td className="text-center p-2 px-7  border-y border-x ">{account.account}</td>{" "}
                    {account.months?.map((month, index) => (
                      <>
                        <td key={1} className="text-center p-2 border-x border-y ">
                          {fixNum(month.expenses)}
                        </td>
                        <td key={2} className="text-center p-2 border-x border-y  ">
                          {fixNum(month.budget)}
                        </td>
                        {(!month.expenses || !month.variance) && <td className="text-center p-2 border-x  !bg-[#ddd] border-b "> _ </td>}
                        {month.variance < 0 && (
                          <td className="text-center p-2 border-x !bg-[#39ab35] text-white border-b flex justify-center !pl-4 items-center gap-3">
                            <p className="flex items-center gap-3 w-[3.5vw]"> {parseFloat(month.variance * 100).toFixed(2)} %</p>
                            <FaArrowDown />
                          </td>
                        )}
                        {month.variance > 0 && (
                          <td className="text-center p-2 border-x !bg-[#f00e0e] text-white border-b flex justify-center !pl-4 items-center gap-3">
                            <p className="flex items-center gap-3 w-[3.5vw]">{parseFloat(month.variance * 100).toFixed(2)} %</p>
                            <FaArrowUp />
                          </td>
                        )}
                        {/* <td key={3} className="text-center p-2 border-x ">
                    {month.variance * 100}
                  </td> */}
                      </>
                    ))}
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PreviewByAccount
