import React from "react"
import DoughnutChartSecondRow from "./Charts/DoghnutChart"

const index = () => {
  return (
    <div className="flex px-20 mt-16 justify-between">
      <DoughnutChartSecondRow />
      <DoughnutChartSecondRow />
      <DoughnutChartSecondRow />
      <DoughnutChartSecondRow />
    </div>
  )
}

export default index
