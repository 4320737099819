import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ReactLoading from "react-loading"
import { getOneBudgetTypeCfo } from "../../../../Redux/slices/budgetSlice"
import { tax_and_allowance_calc } from "../../managerBudget/PayrollCalc"
import FilterHeader from "../../../../screens/CFObudgetScreen/CFOpreview/FilterHeader"
import { FaEllipsisV, FaPenAlt } from "react-icons/fa"
import CommentsSideBar from "../../../../screens/ManagerBudgestScreen/CommentsSideBar/CommentsSideBar"
import StatusMenu from "../../../../screens/CFObudgetScreen/budgetTables/StatusMenu"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import { useParams } from "react-router-dom"
import { fixNum } from "../../../utils/fixNum"

const PreviewPayrollCfo = ({ state }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { loading: loadingBudget, cfoPreviewBudget } = useSelector((state) => state.budgetReducer)
  const { loading: loadingStatus } = useSelector((state) => state.budgetStatusReducer)

  const { loading, departments } = useSelector((state) => state.deptAndEmployeesReducer)
  const [departmentValue, setDepartmentValue] = React.useState(null)

  const [mounted, setMounted] = useState(false)

  /************** start getting emlpyees *************************/
  const [rows, setRows] = useState([])
  const [payrollData, setPayrollData] = useState([])

  useEffect(() => {
    dispatch(getOneBudgetTypeCfo({ type: "payroll", id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (cfoPreviewBudget && mounted) {
      let lastArr = []
      cfoPreviewBudget.forEach((d) => {
        lastArr = [...lastArr, ...d.data]
      })
      setRows(lastArr)
      setPayrollData(cfoPreviewBudget)
    }
    setMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfoPreviewBudget])

  /************** end getting emlpyees *********************/
  /******** start departments filter *********** */
  // console.log(payrollData)
  useEffect(() => {
    if (mounted) {
      if (departmentValue) {
        let newArr = cfoPreviewBudget.filter((d) => d.department.id === departmentValue.id)
        if (newArr.length) {
          setRows(newArr[0].data)
          setPayrollData(newArr)
        } else {
          setRows([])
        }
      } else {
        let lastArr = []
        cfoPreviewBudget.forEach((d) => {
          lastArr = [...lastArr, ...d.data]
        })
        setPayrollData(cfoPreviewBudget)
        setRows(lastArr)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentValue])
  /******** end departments filter *********** */

  /*************************** start calculation functions **********************/
  const grossIncreaseFn = (i) => {
    if (rows[i].gross_increase) {
      let value = rows[i].employee.gross * (rows[i].gross_increase + 1)
      return parseInt(value)
    } else return parseInt(rows[i].employee.gross)
  }

  const allowanceIncreaseFn = (i) => {
    if (rows[i].allowance_increase) {
      let value = rows[i].employee.allowance * (rows[i].allowance_increase + 1)
      return parseInt(value)
    } else {
      return parseInt(rows[i].employee.allowance)
    }
  }

  /************** start super total function   ************** */
  const superTotalFn = () => {
    // tax_and_allowance_calc(row.employee, grossIncreaseFn(i)).nettax_and_allowance_calc(row.employee, grossIncreaseFn(i)).net

    let superTotalGross = 0
    let superTotalAllowance = 0
    let superTotalNet = 0
    let superTotalTax = 0
    let superTotalActualGross = 0
    let superTotalActualAllowance = 0
    let superTotalActualNet = 0
    let superTotalActualTax = 0
    rows.forEach((row, i) => {
      superTotalGross = superTotalGross + grossIncreaseFn(i)
      superTotalAllowance = superTotalAllowance + allowanceIncreaseFn(i)
      superTotalNet = superTotalNet + tax_and_allowance_calc(row.employee, grossIncreaseFn(i), allowanceIncreaseFn(i)).net
      superTotalTax = superTotalTax + tax_and_allowance_calc(row.employee, grossIncreaseFn(i), allowanceIncreaseFn(i)).tax
      superTotalActualGross = superTotalActualGross + row.employee.gross
      superTotalActualAllowance = superTotalActualAllowance + row.employee.allowance
      superTotalActualNet = superTotalActualNet + row.employee.net
      superTotalActualTax = superTotalActualTax + row.employee.tax
    })
    return {
      superTotalGross,
      superTotalNet,
      superTotalTax,
      superTotalAllowance,
      superTotalActualGross,
      superTotalActualAllowance,
      superTotalActualNet,
      superTotalActualTax,
    }
  }

  /************** end super total function   ************** */

  /*************************** end calculation functions **********************/

  /////
  // console.log(cfoPreviewBudget)
  // const changeStatus = (status) => {
  //   dispatch(changeBudgetStatusCfo({}))
  // }
  console.log(rows)

  return (
    <>
      {loadingBudget || loading ? (
        <div className="w-full flex justify-center">
          <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
        </div>
      ) : (
        <>
          <div className="mb-5">
            <FilterHeader value={departmentValue} setValue={setDepartmentValue} departments={departments} />
          </div>
          {rows.length ? (
            <div className="!h-[65vh] overflow-scroll">
              <table className={`w-full border-b stickyTable inputsTable`}>
                <thead>
                  <tr className="bg-[#061d5a] stickyRow text-white p-1">
                    {state && <th className=" border-x border-x-white p-2  border-b "> </th>}
                    <th className=" border-x border-white p-2 "> Department</th>
                    <th className=" border-x border-white p-2 bg-[#061d5a] min-w-[7vw]"> HR code</th>
                    <th className=" border-x border-white p-2 "> Name</th>
                    <th className=" border-x border-white p-2 "> Gross</th>
                    <th className=" border-x border-white p-2 ">Allowance </th>
                    <th className=" border-x border-white p-2 "> Tax</th>
                    <th className=" border-x border-white p-2 "> Net</th>
                    <th className=" border-x border-white p-2 "> Gross Increase (%)</th>
                    <th className=" border-x border-white p-2 "> Allowance Increase (%)</th>
                    <th className=" border-x border-white p-2 "> Increased Gross</th>
                    <th className=" border-x border-white p-2 "> Increased Allowance</th>
                    <th className=" border-x border-white p-2 "> Increased Tax</th>
                    <th className=" border-x border-white p-2 "> Increased Net</th>
                    <th className=" border-x border-white p-2 "> Effective date</th>
                  </tr>
                </thead>
                <tbody>
                  {payrollData.map((d) =>
                    d.data.map((row, i) => (
                      <tr
                        key={i}
                        className={`!border-y 
                      ${row.status === "NEGOTIATE" && "!bg-white"}
                      ${row.status === "ACCEPTED" && "!bg-[#39ab35] !text-white"}
                      ${row.status === "REJECTED" && "!bg-[#f00e0e] !text-white"}
                       `}
                      >
                        {state === "open" ? (
                          <td className={`border-x  text-center border-y p-0 "}`}>
                            <div className="flex justify-center items-center">
                              <div className="flex items-center ">
                                <StatusMenu info={{ budgetId: d.id, recordId: row.id, budgetType: "payroll" }}>
                                  <FaEllipsisV className="pointer text-black" />
                                </StatusMenu>
                                <CommentsSideBar type="record" budgetType="payroll" budgetId={d.id} recordId={row.id}>
                                  <button
                                    className="hover:bg-[#071C59] hover:text-white flex gap-2 items-center text-black flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
                                    onClick={() => {}}
                                  >
                                    <FaPenAlt style={{ width: "0.7rem" }} />
                                  </button>
                                </CommentsSideBar>
                              </div>
                            </div>
                          </td>
                        ) : null}
                        <td className={`border-x  text-center px-2  border-b "}`}>{row.employee.department?.name}</td>
                        <td className={`border-x  text-center  border-b "}`}>{row.employee.hr_code}</td>
                        {/* ${d.done ? "!bg-[#39ab35]" : "!bg-[#f00e0e]} */}
                        <td className={`border-x  text-center p-2 border-b "}`}>
                          <p className="w-[10vw]">{row.employee.name}</p>
                        </td>

                        <td className={`border-x  text-center p-2 border-b "}`}>{fixNum(row.employee.gross)} </td>
                        <td className={`border-x  text-center p-2 border-b "}`}>{fixNum(row.employee.net)}</td>
                        <td className={`border-x  text-center p-2 border-b "}`}>{fixNum(row.employee.tax)} </td>
                        <td className={`border-x  text-center p-2 border-b "}`}>{fixNum(row.employee.allowance)}</td>
                        <td className={`border-x  text-center p-2 border-b "}`}>{row.gross_increase / 0.01}</td>
                        <td className={`border-x  text-center p-2 border-b "}`}>{row.allowance_increase / 0.01}</td>
                        <td className={`border-x  text-center p-2 border-b "}`}>{fixNum(grossIncreaseFn(i))}</td>
                        <td className={`border-x  text-center p-2 border-b "}`}>
                          {fixNum(tax_and_allowance_calc(row.employee, grossIncreaseFn(i), allowanceIncreaseFn(i)).net)}
                        </td>
                        <td className={`border-x  text-center p-2 border-b "}`}>
                          {fixNum(tax_and_allowance_calc(row.employee, grossIncreaseFn(i), allowanceIncreaseFn(i)).tax)}
                        </td>
                        <td className={`border-x  text-center p-2 border-b "}`}>{fixNum(allowanceIncreaseFn(i))}</td>
                        <td className={`border-x  text-center p-2 border-b "}`}>{row.effective_date}</td>
                      </tr>
                    ))
                  )}
                  <tr className="stickyFinalRow">
                    <td className="border-x  text-center p-2">Total</td>
                    <td className="border-x  text-center p-2"></td>
                    <td className="border-x  text-center p-2"></td>
                    <td className="border-x  text-center p-2"></td>
                    <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalActualGross)} </td>
                    <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalActualAllowance)} </td>
                    <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalActualTax)} </td>
                    <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalActualNet)} </td>
                    <td className="border-x  text-center p-2"></td>
                    <td className="border-x  text-center p-2"></td>
                    <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalGross)}</td>
                    <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalAllowance)}</td>
                    <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalTax)}</td>
                    <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalNet)}</td>
                    <td className="border-x  text-center p-2"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center text-xl">No data</div>
          )}
        </>
      )}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingStatus}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default PreviewPayrollCfo
