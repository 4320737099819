import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  message: "",
  errorMessage: "",
}

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.message = action.payload
    },
    addErrorMessage: (state, action) => {
      state.errorMessage = action.payload
    },

    removeMessage: (state) => {
      state.message = ""
      state.errorMessage = ""
    },
  },
})

// Action creators are generated for each case reducer function
export const { addMessage, removeMessage, addErrorMessage } = messageSlice.actions

export default messageSlice.reducer
