import React, { useEffect, useState } from "react"
import { FaStopwatch, FaClock } from "react-icons/fa"
import PayrollTable from "./budgetTables/PayrollTable"
import HiringTable from "./budgetTables/HiringTable"
import GeneralExpensesTable from "./budgetTables/GeneralExpenses/GeneralExpensesTable"
import CapexTable from "./budgetTables/CapexTable"
import TrainingTable from "./budgetTables/TrainingTable"
import { useDispatch, useSelector } from "react-redux"
import { getManagerBudget } from "../../Redux/slices/budgetSlice"
import ReactLoading from "react-loading"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import ManagerBudgetTabs from "../../components/pagesSpec/managerBudget/Tabs"
import Countdown from "react-countdown"
import PreviewManagerBudget from "../../components/pagesSpec/managerBudget/PreviewBudget"

const ManagerBudgetScreen = () => {
  const dispatch = useDispatch()
  const { loading, managerBudget } = useSelector((state) => state.budgetReducer)

  let { user } = JSON.parse(localStorage.getItem("userInfo"))
  const [mounted, setmounted] = useState(false)
  useEffect(() => {
    dispatch(getManagerBudget(user.department))
    setmounted(true)
  }, [])
  const reset = () => {}

  /******************************* start tabs control  *************************/

  const [tabValue, setTabValue] = useState("1")

  /******************************* end tabs control  *************************/
  const mainDiv = (
    <>
      {/* {managerBudget && ( */}
      <>
        <div className="w-full flex items-end justify-between justify-between border-b  mb-5">
          <ManagerBudgetTabs value={tabValue} setValue={setTabValue} />
          <div className="mb-2 mx-5 flex gap-2 bg-[#af1027] px-5 py-1 text-white rounded-lg items-center ">
            <FaStopwatch /> Deadline:
            <div className="text-white font-bold">
              <Countdown date={managerBudget.deadline} />
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </>
  )
  return (
    <div className="flex flex-col w-full">
      <div className="w-full px-10 flex justify-between border-b-2 mb-5">
        <h2 className="semi-bold text-lg lightFont">Budget</h2>
        {managerBudget && (
          <h2 className=" text-lg py-3 font-light  ">
            New open budget from {"   "}
            <span className="semi-bold border-b-2 border-black">{managerBudget.start_date}</span> to{" "}
            <span className="semi-bold border-b-2 border-black">{managerBudget.end_date}</span>
            {/* by <span className="semi-bold  border-black">Ahmed Saadawy</span> */}
          </h2>
        )}
      </div>
      {loading && (
        <div className="w-full flex justify-center">
          <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
        </div>
      )}
      {!loading && !managerBudget && <h2 className="font-bold">No open budget to set</h2>}
      {!loading && mounted && managerBudget && (
        <>
          {mainDiv}
          {tabValue === "1" && mounted && (
            // <div>
            <PreviewManagerBudget />
            // </div>
          )}
          {tabValue === "2" && (
            <PayrollTable back={reset} id={managerBudget.payroll.id} budgetSD={managerBudget.start_date} budgetED={managerBudget.end_date} />
          )}
          {tabValue === "3" && (
            <HiringTable back={reset} id={managerBudget.new_hire.id} budgetSD={managerBudget.start_date} budgetED={managerBudget.end_date} />
          )}
          {tabValue === "4" && <TrainingTable back={reset} id={managerBudget.training.id} />}

          {tabValue === "5" && (
            <CapexTable back={reset} id={managerBudget.capex.id} budgetSD={managerBudget.start_date} budgetED={managerBudget.end_date} />
          )}
          {tabValue === "6" && (
            <GeneralExpensesTable
              back={reset}
              id={managerBudget.general_expenses.id}
              budgetSD={managerBudget.start_date}
              budgetED={managerBudget.end_date}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ManagerBudgetScreen
