import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API } from "../API/api"
import { addErrorMessage } from "./messageSlice"

/***************** start main budget comments **************************/

/// get one budget comment
export const getBudgetComments = createAsyncThunk("budgetComments/get", async (info, thunkAPI) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"))

  try {
    let res = await API.get(`/budget/${info.budgetType}/${info.budgetId}/comment/`)

    return res.data.results.reverse()
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})

//post one budget comment
export const postBudgetComment = createAsyncThunk("budgetComments/post", async (info, thunkAPI) => {
  console.log(info, "from slice")
  let userInfo = JSON.parse(localStorage.getItem("userInfo"))
  console.log(info, "from slice")

  try {
    const res = await API.post(`/budget/${info.budgetType}/${info.budgetId}/comment/`, info.comment)
    console.log(res.data, "from slice")
    const state = thunkAPI.getState().commentsReducer.budgetComments
    let latest = [...state, res.data]
    return latest
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})

/***************** end main budget comments **************************/

/*********************************************************************************************/

/***************** start budget record comments **************************/

/// get one budget record comment
export const getBudgetRecordComments = createAsyncThunk("budgetRecordComments/get", async (info, thunkAPI) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"))

  try {
    const res = await API.get(`/budget/${info.budgetType}/${info.budgetId}/record/${info.recordId}/comment`)
    return res.data.results.reverse()
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})

//post one budget record comment
export const postBudgetRecordComment = createAsyncThunk("budgetRecordComments/post", async (info, thunkAPI) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"))
  try {
    const res = await API.post(`/budget/${info.budgetType}/${info.budgetId}/record/${info.recordId}/comment/`, info.comment)
    console.log(res)
    const state = thunkAPI.getState().commentsReducer.budgetComments
    let latest = [...state, res.data]
    return latest
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})

/***************** end budget record comments **************************/

const initialState = {
  budgetComments: [],
  // recordComments: [],

  loading: false,
  error: null,
}

export const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
  extraReducers: {
    [getBudgetComments.pending]: (state) => {
      state.budgetComments = null
      state.loading = true
    },
    [getBudgetComments.fulfilled]: (state, action) => {
      state.loading = false
      state.budgetComments = action.payload
    },
    [getBudgetComments.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    [postBudgetComment.pending]: (state) => {
      state.loading = true
    },
    [postBudgetComment.fulfilled]: (state, action) => {
      state.loading = false
      state.budgetComments = action.payload
    },
    [postBudgetComment.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    [getBudgetRecordComments.pending]: (state) => {
      state.loading = true
      state.budgetComments = null
    },
    [getBudgetRecordComments.fulfilled]: (state, action) => {
      state.loading = false
      state.budgetComments = action.payload
    },
    [getBudgetRecordComments.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    [postBudgetRecordComment.pending]: (state) => {
      state.loading = true
    },
    [postBudgetRecordComment.fulfilled]: (state, action) => {
      state.loading = false
      state.budgetComments = action.payload
    },
    [postBudgetRecordComment.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})

export default commentsSlice.reducer
