import React from "react"
import styles from "../inputs.module.css"
import invoiceImg from "../../../assets/models-images/invoice.svg"

const FirstStep = ({ cards, handleComplete }) => {
  return (
    <>
      <h3 className="m-3 mb-8 text-lg lightFont">Please select the sheet type</h3>
      <div className="grid grid-cols-1	gap-4 md:grid-cols-3 w-full ">
        {cards.map((c, i) => (
          <div key={i} className={styles.card} onClick={(e) => handleComplete(e, i)}>
            <img src={invoiceImg} alt="invoice-model" />
            <div className="mx-4 ">
              <h6>{c}</h6>
              {/* <span className="lightFont">{c.description}</span> */}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default FirstStep
