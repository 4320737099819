export const tax_and_allowance_calc = (emp, newGross, newAllowance) => {
  const tax_config = { personal_limit: 750, start_date: new Date() }
  const insurance_config = { employee_percentage: 0.11, employer_percentage: 0.17, start_date: new Date() }
  const employee_share = insurance_config.employee_percentage * emp.insurance
  const stt = newGross + newAllowance - tax_config.personal_limit - employee_share
  const slices = taxSlices.filter((slice) => slice.min_limit <= stt)

  let tax = 0
  let rem = stt
  for (let i = 0; i < slices.length - 1; i++) {
    const slice = slices[i]
    let diff = slice.max_limit - slice.min_limit
    tax += diff * slice.percentage
    rem -= diff
  }
  tax += rem * slices[slices.length - 1].percentage
  if (newGross > 0) {
    return {
      tax: parseInt(tax),
      net: parseInt(newGross + newAllowance - tax - employee_share),
    }
  } else {
    return {
      tax: 0,
      net: 0,
    }
  }
}

const taxSlices = [
  { min_limit: 0, max_limit: 1250, percentage: 0 },
  { min_limit: 1250, max_limit: 2500, percentage: 0.025 },
  { min_limit: 2500, max_limit: 3750, percentage: 0.1 },
  { min_limit: 3750, max_limit: 5000, percentage: 0.15 },
  { min_limit: 5000, max_limit: 16666, percentage: 0.2 },
  { min_limit: 16666, max_limit: 33333, percentage: 0.225 },
  { min_limit: 33333, max_limit: 999999, percentage: 0.25 },
]
