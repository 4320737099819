export const fakeTable1 = (
  <>
    <p className="mb-2 semi-bold lightFont">Top Sales</p>
    <table className="w-full mt-2 shadow-2 fixedTable">
      <thead>
        <tr className="shadow-md">
          <th className="  p-2 bg-[#0e1535] text-white  ">Rank</th>
          <th className="  p-2  bg-[#0e1535] text-white ">Name</th>
          <th className="= p-2  bg-[#0e1535] text-white ">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">1</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Mohamed</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">22.2k EGP</td>
        </tr>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">2</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Rafa</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">21k EGP</td>
        </tr>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">3</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Chris</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">20.8k EGP</td>
        </tr>
      </tbody>
    </table>
  </>
)

export const fakeTable2 = (
  <>
    <p className="mb-2 semi-bold lightFont">Top Sales</p>
    <table className="w-full mt-2 shadow-2 fixedTable">
      <thead>
        <tr className="shadow-md">
          <th className="  p-2 bg-[#0e1535] text-white  ">Rank</th>
          <th className="  p-2  bg-[#0e1535] text-white ">Name</th>
          <th className="= p-2  bg-[#0e1535] text-white ">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className="hover:shadow-2 fixedTable">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">3</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Leonardo</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">22.2k EGP</td>
        </tr>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">4</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Nolan</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">21k EGP</td>
        </tr>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">5</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Andy</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">20.8k EGP</td>
        </tr>
      </tbody>
    </table>
  </>
)
export const fakeTable3 = (
  <>
    <p className="mb-2 semi-bold lightFont">Top Brokers</p>
    <table className="w-full mt-2 shadow-2 fixedTable">
      <thead>
        <tr className="shadow-md">
          <th className="  p-2 bg-[#0e1535] text-white  ">Rank</th>
          <th className="  p-2  bg-[#0e1535] text-white ">Name</th>
          <th className="= p-2  bg-[#0e1535] text-white ">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">1</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Soha</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">22.2k EGP</td>
        </tr>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">2</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Nasser</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">21k EGP</td>
        </tr>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">3</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Ali</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">20.8k EGP</td>
        </tr>
      </tbody>
    </table>
  </>
)
export const fakeTable4 = (
  <>
    <p className="mb-2 semi-bold lightFont">Top Brokers</p>
    <table className="w-full mt-2 shadow-2 fixedTable">
      <thead>
        <tr className="shadow-md">
          <th className="  p-2 bg-[#0e1535] text-white  ">Rank</th>
          <th className="  p-2  bg-[#0e1535] text-white ">Name</th>
          <th className="= p-2  bg-[#0e1535] text-white ">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">4</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Andy</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">22.2k EGP</td>
        </tr>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">5</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Buzz</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">21k EGP</td>
        </tr>
        <tr className="hover:shadow-2">
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">6</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Emily</td>
          <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">20.8k EGP</td>
        </tr>
      </tbody>
    </table>
  </>
)

export const fakeTables = [fakeTable1, fakeTable2, fakeTable3, fakeTable4]
