import React, { useEffect, useState } from "react"
import styles from "../../budget.module.css"
import { useDispatch, useSelector } from "react-redux"
import { getGeneralExpensesBudget, patchGeneralExpensesBudget } from "../../../../Redux/slices/managerBudgetSlice"
import { getAccounts } from "../../../../Redux/slices/dept_employees_slice"
import ReactLoading from "react-loading"
import { addErrorMessage } from "../../../../Redux/slices/messageSlice"
import { FaArrowDown, FaArrowUp, FaCheckCircle, FaPenAlt, FaRegTimesCircle } from "react-icons/fa"
//backdrop for loading
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import NoteModal from "../../../../components/compound components/Modals/NoteModal"
import Tooltip from "@mui/material/Tooltip"
import { fixNum } from "../../../../components/utils/fixNum"

const GeneralExpensesTable = ({ back, id }) => {
  const dispatch = useDispatch()
  const [mounted, setMounted] = useState(false)
  const [note, setNote] = useState("")
  const [openNote, setOpenNote] = React.useState(false)
  const handleClose = () => {
    setOpenNote(false)
  }

  /******************************* start calculating months *************************/
  const { budget, loadingUpdate, loading, updatedBudget, error } = useSelector((state) => state.managerBudgetReducer)
  const [categories, setCategories] = useState([])
  const categoriesSetter = () => {
    let arr = []
    let arr2 = []
    budget.data.forEach((d) => {
      if (arr2.includes(d.account.category.id)) {
        return
      } else {
        arr.push(d.account.category)
        arr2.push(d.account.category.id)
      }
    })
    let dividedData = []
    arr.forEach((category) => {
      let cat = { category, data: [] }
      let data = budget.data.filter((d) => d.account.category.id === category.id)
      cat.data = data
      dividedData.push(cat)
    })
    setCategories(dividedData)
  }
  console.log(categories)

  var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  function diff() {
    var arr = []
    console.log(budget)
    var datFrom = new Date(budget.data[0]?.months[0]?.month)
    var monthsLength = budget.data[0]?.months?.length
    var datTo = new Date(budget.data[0]?.months[monthsLength - 1]?.month)
    var fromYear = datFrom.getFullYear()
    var toYear = datTo.getFullYear()
    var diffYear = 12 * (toYear - fromYear) + datTo.getMonth()

    for (var i = datFrom.getMonth(); i <= diffYear; i++) {
      arr.push(monthNames[i % 12])
    }
    return arr
  }
  // let monthsObj = {}

  /******************************* end calculating months *************************/

  /******************************* start budget data *************************/

  const [rows, setRows] = useState([])
  const [months, setMonths] = useState([])
  // const [rows, setrows] = useState([])
  const [cap, setCap] = useState("")

  useEffect(() => {
    dispatch(getGeneralExpensesBudget(id))
    dispatch(getAccounts())
  }, [])
  useEffect(() => {
    if (budget && mounted) {
      setCap(budget.cap)
      categoriesSetter()

      setMonths(diff())
      setRows(budget.data)
    }
    setMounted(true)
  }, [budget])

  /******************************* end budget data *************************/

  /******************************* start hanlding changes *************************/

  const handleChange = (e, id, index) => {
    let value = e.target.value
    if (e.target.value) {
      value = parseInt(e.target.value)
    }
    let acc = rows.find((d) => d.id === id)
    let myMonths = [...acc.months]
    let latestMonths = myMonths.map((month, i) => {
      if (i === index) {
        return { ...month, amount: value }
      } else {
        return month
      }
    })
    let newState = rows.map((account) => (account.id === id ? { ...account, months: latestMonths } : account))
    setRows(newState)
  }

  /******************************* end hanlding changes *************************/

  /******************************* start calculation funcitons *************************/
  const totalFn = (id) => {
    // let values = Object.values(rows[i]).filter((v, i) => i > 3)
    let values = rows.find((d) => d.id === id).months
    let total = 0
    values.forEach((v) => {
      if (v.amount > 0) {
        total = total + v.amount
      }
    })
    return total
  }

  const superTotalFn = () => {
    let superTotal = 0
    rows.forEach((d) => {
      superTotal = superTotal + totalFn(d.id)
    })
    if (superTotal) return superTotal
    else return 0
  }
  const superTotalDynamic = (key) => {
    let superTotal = 0
    rows.forEach((d) => {
      superTotal = superTotal + d[key]
    })
    if (superTotal) return superTotal
    else return 0
  }
  const superIncreaseValue = () => {
    let val = parseInt(((superTotalFn() - superTotalDynamic("expenses")) * 100) / superTotalDynamic("expenses"))

    return val
  }

  const monthTotalFn = (i) => {
    let total = 0
    rows.forEach((v) => {
      if (v.months[i].amount > 0) {
        total = total + v.months[i].amount
      }
    })
    return total
  }

  /******************************* end calculation funcitons *************************/

  /******************************* start tabs control  *************************/

  // const [value, setValue] = useState("1")
  // const handleChangeTab = (event, newValue) => {
  //   setValue(newValue)
  // }
  /******************************* end tabs control  *************************/

  /******************************* start setting row  *************************/
  const tableRow = categories.map((catData) => (
    <>
      <tr>
        <td colSpan={months.length + 4} className="!bg-[#AF1027] text-center text-white font-bold border-x  p-2 border ">
          {catData.category.name}
        </td>
      </tr>
      {catData.data.map((row, i) => (
        <>
          <tr key={i}>
            <td className="text-center p-2 border-x border-y ">{row.account.name}</td>
            <td className="text-center p-2 border-x ">{row.expenses ? fixNum(row.expenses) : " _ "}</td>
            <td className="text-center p-2 border-x ">{fixNum(totalFn(row.id))}</td>
            {!row.expenses && <td className="text-center p-2 border-x !bg-[#ddd] border-b "> _ </td>}
            {row.expenses && totalFn(row.id) - row.expenses > 0 ? (
              <td className="text-center p-2 border-x !bg-[#f00e0e] text-white border-b flex !pl-4 items-center gap-3">
                <p className="flex items-center gap-3 w-[3.5vw]">{((totalFn(row.id) - row.expenses) * 100) / row.expenses} %</p>
                <FaArrowUp />
              </td>
            ) : null}
            {row.expenses && totalFn(row.id) - row.expenses < 0 ? (
              <td className="text-center p-2 border-x !bg-[#39ab35] text-white border-b flex !pl-4 items-center gap-3">
                <p className="flex items-center gap-3 w-[3.5vw]">{((totalFn(row.id) - row.expenses) * 100) / row.expenses} %</p>
                <FaArrowDown />
              </td>
            ) : null}

            {/* <td className="text-center p-2 border-x bg-[#209648] text-white border-b ">15 %</td> */}

            {row.months.map((d, index) => (
              <td key={index} style={{ minWidth: "6rem" }} className="text-center p-2 border-x 	 ">
                <input
                  value={rows.find((account) => row.id === account.id).months[index].amount}
                  onChange={(e) => handleChange(e, row.id, index)}
                  className="pl-4 border border-black w-10/12"
                  type="number"
                  min={0}
                />
              </td>
            ))}
          </tr>
        </>
      ))}
    </>
  ))
  /******************************* end setting row  *************************/

  /******************************* start submitting  *************************/

  const submit = () => {
    dispatch(
      patchGeneralExpensesBudget({
        id,
        update: { data: rows },
      })
    )
  }
  const submitDone = (val) => {
    dispatch(
      patchGeneralExpensesBudget({
        id,
        update: { done: true, data: rows },
      })
    )
  }

  useEffect(() => {
    if (updatedBudget && mounted) {
      // back()
    } else if (error && mounted) {
      dispatch(addErrorMessage(error))
      back()
    }
  }, [updatedBudget, error])
  /******************************* end setting  *************************/

  return (
    <>
      <label className="w-full px-5 semi-bold border-b-2 py-2 mb-4 flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-center">
          General Expenses Budget
          {/* <Tooltip title="Add note" arrow>
            <button
              className="hover:bg-[#071C59] hover:text-white flex gap-2 items-center text-black flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
              onClick={() => {
                setOpenNote(true)
              }}
            >
            
              <FaPenAlt style={{ width: "90%" }} />
            </button>
          </Tooltip> */}
        </div>
        <div className="flex gap-3 items-center">
          {superTotalFn() > cap && <div className="bg-[#e7cb02] rounded-xl px-3 py-1"> You exceeded the cap!</div>}
          <div>
            Your cap for general expenses is <span className="text-[#217245]">{fixNum(cap)} EGP</span>
          </div>
        </div>
      </label>

      {/* {loadingUpdate && (
        <div className="w-full h-3/4 flex flex-col items-center justify-center">
          <ReactLoading type="bars" color="#061e5b" width={"6%"} height={"6%"} />
          <h2 className="mt-2 text-xl text-[#061e5b]">Sending budget</h2>
        </div>
      )} */}
      {loading && (
        <div className="w-full flex justify-center">
          <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
        </div>
      )}
      {!loading && mounted && (
        <>
          <div className="!h-[65vh] overflow-scroll">
            <table className={`w-full border-b tableColumnsWidth h-full  stickyTable ${styles.inputsTable}`}>
              <thead>
                <tr className="bg-[#061d5a] text-white p-1 stickyRow">
                  <th className=" border-x border-white p-2 bg-[#061d5a] "> Account</th>
                  <th className=" border-x border-white p-2 px-10 "> Actual</th>
                  <th className=" border-x border-white p-2 px-10 "> Total</th>
                  <th className=" border-x border-white p-2 min-w-[7vw]">Increase %</th>
                  {months.map((month, i) => (
                    <th className=" border-x border-white p-2 " key={i}>
                      {" "}
                      {month}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableRow}
                <tr className="stickyFinalRow">
                  <td className="border-x  text-center p-2">Total</td>
                  <td className="border-x  text-center p-2">{fixNum(superTotalDynamic("expenses"))}</td>
                  <td className="border-x  text-center p-2">{fixNum(superTotalFn())}</td>
                  {!superIncreaseValue() && <td className="text-center p-2 border-x !bg-[#ddd] border-b "> _ </td>}
                  {superIncreaseValue() > 0 && <td className="border-x bg-[#f00e0e] text-white text-center p-2">{superIncreaseValue()} %</td>}
                  {superIncreaseValue() < 0 && <td className="border-x bg-[#39ab35] text-white text-center p-2">{superIncreaseValue()} %</td>}

                  {months.map((month, i) => (
                    <td className="border-x  text-center p-2" key={i}>
                      {fixNum(monthTotalFn(i))}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-full my-4 gap-2 flex justify-end">
            {!budget.done ? (
              <button
                className="bg-[#39ab35] flex items-center gap-2 text-white rounded-3xl semi-bold px-10 py-1  transition ease-in-out duration-300"
                onClick={() => submitDone(true)}
              >
                mark as Done <FaCheckCircle />
              </button>
            ) : (
              <button className="bg-[#f00e0e] flex items-center gap-2 text-white rounded-3xl semi-bold px-10 py-1" onClick={() => submitDone(false)}>
                unmark as Done <FaRegTimesCircle />
              </button>
            )}

            <button className="bg-[#061d5a] text-white rounded-3xl semi-bold px-10 py-1" onClick={submit}>
              Save
            </button>
          </div>
        </>
      )}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingUpdate}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NoteModal setOpenNote={setOpenNote} setNote={setNote} handleClose={handleClose} open={openNote} note={note} />
    </>
  )
}

export default GeneralExpensesTable
