import React from "react"
import FirstRow from "./FirstRow"
import SecondRow from "./SecondRow"
import ThirdRow from "./ThirdRow"

const index = () => {
  return (
    <>
      <SecondRow />
      <FirstRow />
      <ThirdRow />
    </>
  )
}

export default index
