import React, { useState } from "react"
// import { animated, useTransition } from "react-spring"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import BonusTable from "./ProgressTable"
import { FaArrowLeft } from "react-icons/fa"

const AddTargets = () => {
  const [fromDate, setFromDate] = React.useState(dayjs(new Date()))
  const [toDate, setToDate] = React.useState(dayjs(new Date()))
  const navigate = useNavigate()
  const [employeesTableView, setEmployeesTableView] = useState(false)
  const [department, setDepartment] = useState("")

  const startDate = new Date()

  const handleChange = (newValue) => {
    setFromDate(new Date(newValue))
  }

  //// date and department zpne

  const dateArea = (
    <div className="p-5 w-full">
      <div>
        {/* <h2 className="my-7 pb-3 border-b w-6/12 font-medium flex items-center gap-2">
          <FaArrowLeft onClick={() => setEmployeesTableView(false)} className="pointer" /> Select Duration
        </h2> */}
        <div className="flex gap-3 w-full ">
          <select defaultValue="" className="border-2 rounded-lg  pr-14 pl-2" onChange={(e) => setDepartment(e.target.value)}>
            <option value="" disabled>
              Department
            </option>
            <option value="sales">Sales</option>
            <option value="sales">Sales</option>
            <option value="sales">Sales</option>
          </select>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              minDate={startDate}
              label="Starting from"
              inputFormat="DD/MM/YYYY"
              value={fromDate}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              minDate={startDate}
              label="Ending on"
              inputFormat="DD/MM/YYYY"
              value={toDate}
              onChange={(newValue) => setToDate(new Date(newValue))}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          {department && (
            <button
              className="bg-[#061d5a] text-white rounded-3xl semi-bold px-10 py-0 m-2"
              onClick={() => {
                setEmployeesTableView(true)
              }}
            >
              Set Targets
            </button>
          )}
        </div>
      </div>
    </div>
  )

  const employeesTable = (
    <>
      <table className={`w-full border-b  inputsTable`}>
        <thead>
          <tr className="bg-[#061d5a] text-white p-1">
            <th className=" border-x border-white p-2 "> Employee</th>
            <th className=" border-x border-white p-2 "> HR Code</th>
            <th className=" border-x border-white p-2 "> Retention</th>
            <th className=" border-x border-white p-2 "> Managment</th>
            <th className=" border-x border-white p-2 "> Self Fund</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center p-2">Employee 1</td>
            <td className="text-center p-2">124234</td>
            <td className="text-center p-2 border-x ">
              <input onChange={(e) => {}} className="pl-4 border border-black w-10/12" type="number" placeholder="target" />
            </td>
            <td className="text-center p-2 border-x ">
              <input onChange={(e) => {}} className="pl-4 border border-black w-10/12" type="number" placeholder="target" />
            </td>
            <td className="text-center p-2 border-x ">
              <input onChange={(e) => {}} className="pl-4 border border-black w-10/12" type="number" placeholder="target" />
            </td>
          </tr>
        </tbody>
      </table>

      <div className="w-full my-5 flex gap-2 justify-end">
        <button
          className="bg-[#061d5a] text-white rounded-3xl semi-bold  px-10 py-1"
          onClick={() => {
            navigate("/configuration")
          }}
        >
          Save{" "}
        </button>
      </div>
    </>
  )

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full px-10 border-b-2 pb-3 mb-5">
        <h2 className="semi-bold text-lg lightFont">Set Targets</h2>
      </div>
      {dateArea}
      {employeesTableView && employeesTable}
    </div>
  )
}

export default AddTargets
