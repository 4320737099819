import React from "react"
import DoughnutChartSecondRow from "./Charts/DoghnutChart"
import DoughnutChart2SecondRow from "./Charts/Doghnut2Chart"
import DoughnutChart3SecondRow from "./Charts/Doghnut3Chart"
import DoughnutChart4SecondRow from "./Charts/Doghnut4Chart"

import { useSelector } from "react-redux"

const SecondRow = () => {
  const { dashboardData } = useSelector((state) => state.dashboardReducer)

  return (
    <div className="flex px-20 mt-16 justify-between">
      <DoughnutChartSecondRow dashboardData={dashboardData?.claims_count_per_policy_type} />
      <DoughnutChart2SecondRow dashboardData={dashboardData?.cost_per_claim_per_policy_type} />
      {/* <DoughnutChart3SecondRow dashboardData={dashboardData?.loss_ratio} /> */}
      {/* <DoughnutChart4SecondRow dashboardData={dashboardData?.clients_count} /> */}
    </div>
  )
}

export default SecondRow
