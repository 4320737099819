export const fixNum = (num) => {
  num = num * 1
  // convert num to int
  num = parseInt(num)
  // convert numb to string
  let numStr = num.toString()
  // put comma after every 3 digits
  let numStrArr = numStr.split("")
  let numStrArrRev = numStrArr.reverse()
  let numStrArrRevWithComma = []
  for (let i = 0; i < numStrArrRev.length; i++) {
    if (i % 3 === 0 && i !== 0) {
      numStrArrRevWithComma.push(",")
    }
    numStrArrRevWithComma.push(numStrArrRev[i])
  }
  let numStrArrRevWithCommaRev = numStrArrRevWithComma.reverse()
  let numStrArrRevWithCommaRevStr = numStrArrRevWithCommaRev.join("")
  return numStrArrRevWithCommaRevStr
}
