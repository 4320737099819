import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"
import FlagErrorMessage from "../components/FlagErrorMessage"
import FlagMessage from "../components/FlagMessage"
import ProtectedRoute from "../components/Routes/ProtectedRoute"
import Sidebar from "../components/sidebar/Sidebar"
import { removeMessage } from "../Redux/slices/messageSlice"
import AddNewBudget from "./CFObudgetScreen/Add and Edit/AddNewBudget"
import EditCfoBudget from "./CFObudgetScreen/Add and Edit/EditCfoBudget"
import CfoBudgetScreen from "./CFObudgetScreen/CfoBudgetScreen"
import CFOpreview from "./CFObudgetScreen/CFOpreview"
import AddingConfig from "./configurationScreen/AddingConfig"
import AddTargets from "./configurationScreen/AddTargets"
import ConfigScreen from "./configurationScreen/ConfigScreen"
import ProgressPreview from "./configurationScreen/ProgressPreview"
import DashboardScreen from "./dashboard"
import InputsScreen from "./InputsScreen/InputsScreen"
import InputsTableScreen from "./InputsScreen/InputsTableScreen"
import ManagerBudgetScreen from "./ManagerBudgestScreen/ManagerBudgetScreen"
import ReportsScreen from "./ReportsScreen/ReportsScreen"

const PrivateRoutes = () => {
  const dispatch = useDispatch()
  const [showMessage, setShowMessage] = useState(false)
  const [showErrMessage, setShowErrMessage] = useState(false)
  let userLocal = JSON.parse(localStorage.getItem("userInfo"))
  let user = userLocal?.user
  const { message, errorMessage } = useSelector((state) => state.messageReducer)

  useEffect(() => {
    if (message) {
      setShowMessage(true)
      setTimeout(() => {
        setShowMessage(false)
        dispatch(removeMessage())
      }, 3000)
    }
  }, [message])
  useEffect(() => {
    if (errorMessage) {
      setShowErrMessage(true)
      setTimeout(() => {
        setShowErrMessage(false)
        dispatch(removeMessage())
      }, 3000)
    }
  }, [errorMessage])

  return (
    <div className="flex relative h-full">
      {user && <Sidebar />} <FlagMessage message={message} show={showMessage} />
      <FlagErrorMessage message={errorMessage} show={showErrMessage} />
      <div className="innerScreen !pt-10 !pb-5">
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <DashboardScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/data-insertion"
            element={
              <ProtectedRoute>
                <InputsTableScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-files"
            element={
              <ProtectedRoute>
                <InputsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/budget"
            element={
              <ProtectedRoute>
                <CfoBudgetScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-new-budget"
            element={
              <ProtectedRoute>
                <AddNewBudget />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cfo/edit-budget/:id"
            element={
              <ProtectedRoute>
                <EditCfoBudget />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cfo-preview/:id/:state"
            element={
              <ProtectedRoute>
                <CFOpreview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cfo-preview/:id"
            element={
              <ProtectedRoute>
                <CFOpreview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manager-budget"
            element={
              <ProtectedRoute>
                <ManagerBudgetScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/configuration"
            element={
              <ProtectedRoute>
                <ConfigScreen />
              </ProtectedRoute>
            }
          />

          <Route
            path="/configuration/add-configuration"
            element={
              <ProtectedRoute>
                <AddingConfig />
              </ProtectedRoute>
            }
          />
          <Route
            path="/configuration/preview-progress"
            element={
              <ProtectedRoute>
                <ProgressPreview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/configuration/add-targets"
            element={
              <ProtectedRoute>
                <AddTargets />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedRoute>
                <ReportsScreen />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  )
}

export default PrivateRoutes
