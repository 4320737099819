import React from "react"
import BarChartFirstRow from "./Charts/BarChartFirstRow"
import placeHolder from "../../../../../../assets/placeholder.jpeg"
import { ReactComponent as Trophy } from "../../../../../../assets/trophy.svg"
import trophySvg from "../../../../../../assets/trophy.svg"

const index = () => {
  return (
    <>
      <div className="flex flex-wrap justify-center gap-5 my-7 w-full">
        <div className="w-[70%] flex">
          {/* <p className="mb-5 border-b w-[50%] font-semibold pb-3"> Bar Chart Title</p> */}
          <div className="h-auto flex justify-start items-center border-r relative w-20 mx-2">
            <div
              className="absolute w-full lightFont"
              style={{ transform: "rotate(270deg)", whiteSpace: "nowrap", fontFamily: "boldDin", letterSpacing: "1rem", left: "10%", bottom: "20%" }}
            >
              Chart Title
            </div>
          </div>
          <BarChartFirstRow />
        </div>

        <div style={{ width: "28%" }} className=" flex gap-5 rounded-xl  border-2 !border-[#FF9910] p-2">
          <div className="h-full flex flex-col justify-center items-end">
            <div className="w-full flex justify-center">
              <p className="font-bold mb-5 text-xl text-center">Top Performer</p>
            </div>
            <img style={{ width: "12rem", maxWidth: "none" }} src={trophySvg} alt="img" />
          </div>
          <div className="flex  flex-col  h-full  ">
            <img src={placeHolder} alt="img" className="rounded-lg border" />
            <div className="mx-2 my-2">
              <p className="text-black text-xl font-semibold">John Doe</p>
              <p className="text-black text-lg">CEO</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default index
