import React from "react"
import CfoBudgetTabs from "../../../components/pagesSpec/cfoBudget/Tabs"

const CFOpreview = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="w-full px-10 border-b-2 pb-3 mb-5">
        <h2 className="semi-bold text-lg lightFont">Budget Preview</h2>
      </div>
      <CfoBudgetTabs />
    </div>
  )
}

export default CFOpreview
