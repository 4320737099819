import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepButton from "@mui/material/StepButton"
import FirstStep from "./stepsScreens/FirstStep"
import { animated, useSpring, useTransition } from "react-spring"
import SecondStep from "./stepsScreens/SecondStep"
import ThirdStep from "./stepsScreens/ThirdStep"
import { useDispatch, useSelector } from "react-redux"
import { getSettings } from "../../Redux/slices/inputsSlice"
import ReactLoading from "react-loading"
import { useNavigate } from "react-router-dom"

const InputsScreen = () => {
  const navigate = useNavigate()
  const steps = ["Select sheet type", "Select your file", "Save data"]
  // const steps = ["Select sheet type", "Select your file"]

  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const [data, setData] = useState("")
  const [inputTypes, setInputTypes] = useState([])
  const [selectedType, setselectedType] = useState("")
  const [files, setFiles] = useState("")
  const dispatch = useDispatch()
  const { loading, settings, loadingUpload, uploadedFile } = useSelector((state) => state.inputsReducer)

  // getting settings data
  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])
  useEffect(() => {
    if (settings) {
      const newArr = settings.map((input) => input.name)
      setInputTypes(newArr)
    }
  }, [settings])

  //////////////////////////////////// start stepper

  const handleNext = () => {
    const newActiveStep = activeStep + 1
    setActiveStep(newActiveStep)
  }

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1)
  //   const newCompleted = completed
  //   newCompleted[activeStep - 1] = false
  //   setCompleted(newCompleted)
  // }
  const handleComplete = (e, id) => {
    if (id >= 0) {
      console.log(settings[id])
      setselectedType(settings[id])
    }
    const newCompleted = completed
    newCompleted[activeStep] = true
    handleNext()
  }

  const transitionFirst = useTransition(activeStep === 0, {
    config: { duration: 100 },
    from: { x: 500, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    leave: { x: -500 },
  })
  const transitionSecond = useTransition(activeStep === 1, {
    config: { duration: 100 },
    from: { x: 500, opacity: 0 },
    enter: { x: 0, opacity: 1 },
  })
  const transitionThird = useTransition(activeStep === 2, {
    config: { duration: 1000 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
  })
  //////////////////////////////////// end stepper

  //////////////////////////////////// file uploaded
  // const [successful, setSuccessful] = useState(false)
  const [failed, setFailed] = useState(false)

  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    if (uploadedFile && mounted) {
      navigate("/data-insertion")
    }
    setMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile])

  const errorMessage = failed && (
    <div className="w-full">
      <div className="border-2 w-auto inline-block border-[#209648] bg-[#ddffde] text-black text-[#209648] semi-bold rounded-lg text-lg px-2">
        {/* {error} */}
      </div>
    </div>
  )

  return (
    <div className="flex flex-col h-full items-center w-full">
      <div className="w-full px-10 border-b-2 pb-3 mb-5">
        <h2 className="semi-bold text-lg lightFont">Insert Data</h2>
      </div>
      {/* {doneDiv} */}
      {loadingUpload && (
        <div className="w-full h-3/4 flex flex-col items-center justify-center">
          <ReactLoading type="bars" color="#061e5b" width={"6%"} height={"6%"} />
          <h2 className="mt-20 text-xl text-[#061e5b]">Sending File</h2>
        </div>
      )}
      {!loadingUpload && (
        <>
          <Box sx={{ width: "70%", marginTop: "1rem" }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton
                    // onClick={handleBack}
                    sx={{
                      "& text": { fontSize: "1rem" },
                      cursor: "default",
                      "& .Mui-active svg": {
                        borderRadius: "50%",
                        border: "solid 2px #061e5b",
                        color: "#fff",
                      },
                      "& .Mui-active text": {
                        fill: "#061e5b",
                        fontSize: "1rem",
                      },
                      "& .Mui-completed svg": {
                        borderRadius: "50%",
                        color: "#061e5b",
                      },
                    }}
                    color="inherit"
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div className="mt-12 w-full px-10">
            {transitionFirst(
              (style, item) =>
                item && (
                  <animated.div style={style}>
                    {loading ? (
                      <div className="">
                        <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
                      </div>
                    ) : (
                      <FirstStep selectedType={selectedType} setselectedType={setselectedType} cards={inputTypes} handleComplete={handleComplete} />
                    )}
                  </animated.div>
                )
            )}
            {transitionSecond((style, item) =>
              item ? (
                <animated.div style={style}>
                  <SecondStep selectedType={selectedType} files={files} setFiles={setFiles} setData={setData} handleComplete={handleComplete} />
                </animated.div>
              ) : (
                ""
              )
            )}
            {/* {activeStep === 2 && <animated.div style={props}>hellooo</animated.div>} */}
            {transitionThird((style, item) =>
              item ? (
                <animated.div style={style}>
                  <ThirdStep files={files} selectedType={selectedType} data={data} />
                </animated.div>
              ) : (
                ""
              )
            )}{" "}
          </div>
        </>
      )}
    </div>
  )
}

export default InputsScreen
