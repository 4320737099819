import React from "react"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import AddBudgetTable from "../budgetTables/AddBudgetTable"
import { FaArrowLeft } from "react-icons/fa"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import EditBudgetTable from "../budgetTables/EditBudgetTable"

const EditCfoBudget = ({ budget }) => {
  /******************* start radion handling ***************** */
  // const [radioValue, setRadioValue] = React.useState("default")

  // const handleChangeRadio = (event) => {
  //   setRadioValue(event.target.value)
  // }
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full px-10 border-b-2 pb-3 mb-5 flex justify-between">
        <h2 className="semi-bold text-lg lightFont">Edit Budget</h2>
        {/* {cfoTableView && ( */}
        {/* <FormControl>
          <RadioGroup
            value={radioValue}
            onChange={handleChangeRadio}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="default" control={<Radio />} label="Per budget" />
            <FormControlLabel value="department" control={<Radio />} label="Per department" />
          </RadioGroup>
        </FormControl> */}
        {/* )} */}
      </div>
      <div className="w-full">
        {/* {!cfoTableView && ( */}

        {/* )} */}
        <EditBudgetTable />
      </div>
    </div>
  )
}

export default EditCfoBudget
