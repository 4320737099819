import * as React from "react"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
}

export default function NoteModal({ open, handleClose, setNote, setOpenNote, note }) {
  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <h2 className="semi-bold  lightFont py-2 border-b-2">Add Note</h2>
          <textarea value={note} placeholder="enter your note" className="w-full border mt-4 p-2 h-full" onChange={(e) => setNote(e.target.value)} />
          <div className="w-100 flex justify-end mt-2">
            {/* <button className="bg-[#fff] text-[#061d5a] border border-[#061d5a] rounded-3xl  px-10 py-1 m-2" onClick={() => handleClose()}>
              Cancel
            </button> */}
            <button className="bg-[#061d5a] text-white rounded-3xl semi-bold px-10 py-2 m-2" onClick={() => setOpenNote(false)}>
              Save{" "}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
