import React, { useEffect, useState } from "react"
import styles from "../budget.module.css"
import { useDispatch, useSelector } from "react-redux"
import { getDepartments } from "../../../Redux/slices/dept_employees_slice"
import ReactLoading from "react-loading"
import { getOneBudget, getOneBudgetForPatch, patchCfoBudget, postCfoBudget } from "../../../Redux/slices/budgetSlice"
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { FaArrowLeft, FaCaretDown, FaEllipsisV, FaPenAlt } from "react-icons/fa"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import StatusMenu from "./StatusMenu"
import CommentsSideBar from "../../ManagerBudgestScreen/CommentsSideBar/CommentsSideBar"
//backdrop for loading
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

const EditBudgetTable = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [fromDate, setFromDate] = React.useState("")
  const [toDate, setToDate] = React.useState("")
  const [deadline, setDeadline] = React.useState("")

  const startDate = new Date()

  const handleChangeDate = (newValue) => {
    setFromDate(new Date(newValue))
  }

  // const { loading: loadingBudget, cfoPreviewBudget } = useSelector((state) => state.budgetReducer)
  const { loading: loadingBudget, budget: budgetReducer, updatedBudget, loadingUpdate } = useSelector((state) => state.budgetReducer)
  const { loading: loadingDepartments, departments } = useSelector((state) => state.deptAndEmployeesReducer)
  const { id } = useParams()

  useEffect(() => {
    dispatch(getOneBudgetForPatch({ id }))
    dispatch(getDepartments())
  }, [])

  /*************************** start handle change  ****************************** */

  const [budget, setBudget] = useState("")
  const handleChange = (department, index, cap, field) => {
    let newArr = budget[field]
    // newArr[index] = obj
    let arr = newArr.map((o) => (o.department === department.id ? { ...o, cap } : o))
    const final = { ...budget, [field]: arr }
    setBudget(final)
    // } else {
    //   departments.forEach((d, i) => {
    //     const obj = { department: d.id, cap }
    //     let newArr = budget[field]
    //     newArr[i] = obj
    //     const final = { ...budget, [field]: newArr }
    //     setBudget(final)
    //   })
    // }
  }
  /*************************** start handle change  ****************************** */

  ////setting initial value
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    if (budgetReducer && departments && mounted) {
      let general_expenses = budgetReducer.general_expenses
      let capex = budgetReducer.capex
      let new_hire = budgetReducer.new_hire
      let payroll = budgetReducer.payroll
      let training = budgetReducer.training

      const initialArr = {
        general_expenses,
        capex,
        new_hire,
        payroll,
        training,
      }
      setFromDate(dayjs(budgetReducer.start_date).toDate())
      setToDate(dayjs(budgetReducer.end_date).toDate())
      setDeadline(dayjs(budgetReducer.deadline).toDate())
      setBudget(initialArr)
    }
    setMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetReducer, departments])
  console.log(budgetReducer)

  //submiting
  const [error, setError] = useState("")
  const submitBudget = () => {
    setError("")

    dispatch(
      patchCfoBudget({
        id,
        budget: {
          ...budget,
          start_date: moment(fromDate).format("YYYY-MM-DD"),
          end_date: moment(toDate).format("YYYY-MM-DD"),
          deadline: moment(deadline).format("YYYY-MM-DD"),
        },
      })
    )
  }
  console.log(budget)
  /************* start value finder **************/
  const valueFinder = (field, department) => {
    let value = 0
    if (budget[field]) {
      budget[field].forEach((o) => {
        if (o.department === department.id) {
          value = o
        }
      })
    }
    return value
  }

  /************* end value finder **************/

  ////// hanlding response state

  useEffect(() => {
    if (updatedBudget && mounted) {
      // navigate("/budget")
    }
    setMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedBudget])

  console.log(loadingUpdate)
  return (
    <>
      {loadingBudget || loadingDepartments ? (
        <div className="">
          <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
        </div>
      ) : (
        <>
          {" "}
          {budget && (
            <>
              <div className="pb-5 ">
                <div>
                  <div className="flex gap-5">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // minDate={new Date()}
                        label="Starting from"
                        inputFormat="DD/MM/YYYY"
                        value={fromDate}
                        onChange={(newValue) => setFromDate(new Date(newValue))}
                        renderInput={(params) => <TextField {...params} />}
                      />

                      <DesktopDatePicker
                        minDate={fromDate}
                        label="Ending on"
                        inputFormat="DD/MM/YYYY"
                        value={toDate}
                        onChange={(newValue) => setToDate(new Date(newValue))}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <DesktopDatePicker
                        minDate={new Date()}
                        label="Deadline"
                        inputFormat="DD/MM/YYYY"
                        value={deadline}
                        onChange={(newValue) => setDeadline(new Date(newValue))}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                {/* <div className="w-2/5 my-5 flex justify-start">
            <button
              className="bg-[#061d5a] text-white rounded-3xl semi-bold  mr-20 px-10 py-1"
              onClick={() => {
                setCfoTableView(true)
              }}
            >
              Next
            </button>
          </div> */}
              </div>
              <table className={`w-full border-b  ${styles.inputsTable}`}>
                <thead>
                  <tr className="bg-[#061d5a] text-white p-1">
                    <th className=" border-x border-white p-2 "> Department</th>
                    <th className=" border-x border-white p-2 "> Hiring</th>
                    <th className=" border-x border-white p-2 "> Payroll</th>
                    <th className=" border-x border-white p-2 "> Training</th>
                    <th className=" border-x border-white p-2 "> Capex</th>
                    <th className=" border-x border-white p-2 "> General expenses</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {radioValue === "department" && ( */}
                  <>
                    {departments.map((department, i) => (
                      <tr key={i}>
                        <td className="text-center p-2 border-x">{department.name}</td>
                        <td className={`text-center p-2 border-x ${valueFinder("new_hire", department).done ? "!bg-[#39ab35]" : "!bg-[#f00e0e]"}`}>
                          <div className="flex gap-2 items-center justify-end ">
                            <input
                              value={valueFinder("new_hire", department).cap}
                              onChange={(e) => handleChange(department, i, e.target.value, "new_hire")}
                              className="pl-4 border border-black w-10/12"
                              type="number"
                              placeholder="0"
                            />
                            <div className="flex items-center">
                              <CommentsSideBar type="budget" budgetType="new-hire" budgetId={valueFinder("new_hire", department).id}>
                                <button
                                  className="   flex gap-2 items-center text-white flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
                                  onClick={() => {}}
                                >
                                  <FaPenAlt style={{ width: "90%" }} />
                                </button>
                              </CommentsSideBar>
                              {/* <StatusMenu>
                                <FaEllipsisV className="text-white pointer" />
                              </StatusMenu> */}
                            </div>
                          </div>
                        </td>
                        <td className={`text-center  p-2 border-x ${valueFinder("payroll", department).done ? "!bg-[#39ab35]" : "!bg-[#f00e0e]"}`}>
                          <div className="flex gap-2 items-center justify-end ">
                            <input
                              value={valueFinder("payroll", department).cap}
                              onChange={(e) => handleChange(department, i, e.target.value, "payroll")}
                              className="pl-4 border border-black w-10/12"
                              type="text"
                              placeholder="0"
                            />
                            <div className="flex items-center">
                              <CommentsSideBar type="budget" budgetType="payroll" budgetId={valueFinder("new_hire", department).id}>
                                <button
                                  className="   flex gap-2 items-center text-white flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
                                  onClick={() => {}}
                                >
                                  <FaPenAlt style={{ width: "90%" }} />
                                </button>
                              </CommentsSideBar>
                              {/* <StatusMenu>
                                <FaEllipsisV className="text-white pointer" />
                              </StatusMenu> */}
                            </div>
                          </div>
                        </td>
                        <td className={`text-center p-2 border-x ${valueFinder("training", department).done ? "!bg-[#39ab35]" : "!bg-[#f00e0e]"}`}>
                          <div className="flex gap-2 items-center justify-end ">
                            <input
                              value={valueFinder("training", department).cap}
                              onChange={(e) => handleChange(department, i, e.target.value, "training")}
                              className="pl-4 border border-black w-10/12"
                              type="number"
                              placeholder="0"
                            />
                            <div className="flex items-center">
                              <CommentsSideBar type="budget" budgetType="training" budgetId={valueFinder("new_hire", department).id}>
                                <button
                                  className="   flex gap-2 items-center text-white flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
                                  onClick={() => {}}
                                >
                                  <FaPenAlt style={{ width: "90%" }} />
                                </button>
                              </CommentsSideBar>
                              {/* <StatusMenu>
                                <FaEllipsisV className="text-white pointer" />
                              </StatusMenu> */}
                            </div>
                          </div>
                        </td>
                        <td className={`text-center p-2 border-x ${valueFinder("capex", department).done ? "!bg-[#39ab35]" : "!bg-[#f00e0e]"}`}>
                          <div className="flex gap-2 items-center justify-end ">
                            <input
                              value={valueFinder("capex", department).cap}
                              onChange={(e) => handleChange(department, i, e.target.value, "capex")}
                              className="pl-4 border border-black w-10/12"
                              type="number"
                              placeholder="0"
                            />
                            <div className="flex items-center">
                              <CommentsSideBar type="budget" budgetType="capex" budgetId={valueFinder("new_hire", department).id}>
                                <button
                                  className="   flex gap-2 items-center text-white flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
                                  onClick={() => {}}
                                >
                                  <FaPenAlt style={{ width: "90%" }} />
                                </button>
                              </CommentsSideBar>
                              {/* <StatusMenu>
                                <FaEllipsisV className="text-white pointer" />
                              </StatusMenu> */}
                            </div>
                          </div>
                        </td>
                        <td
                          className={`text-center p-2 border-x ${
                            valueFinder("general_expenses", department).done ? "!bg-[#39ab35]" : "!bg-[#f00e0e]"
                          }`}
                        >
                          <div className="flex gap-2 items-center justify-end ">
                            <input
                              value={valueFinder("general_expenses", department).cap}
                              onChange={(e) => handleChange(department, i, e.target.value, "general_expenses")}
                              className="pl-4 border border-black w-10/12"
                              type="number"
                              placeholder="0"
                            />
                            <div className="flex items-center">
                              {/* <CommentsSideBar>
                                <button
                                  className="   flex gap-2 items-center text-white flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
                                  onClick={() => {}}
                                >
                                  <FaPenAlt style={{ width: "90%" }} />
                                </button>
                              </CommentsSideBar> */}
                              {/* <StatusMenu>
                                <FaEllipsisV className="text-white pointer" />
                              </StatusMenu> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                  {/* )} */}
                  {/* {radioValue !== "department" && (
                <tr>
                  <td className="text-center p-2 border-x ">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "new_hire")}
                      className="pl-4 border border-black w-10/12"
                      type="number"
                      placeholder="0"
                    />
                  </td>
                  <td className="border-x  text-center p-2">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "payroll")}
                      className="pl-4 border border-black w-10/12"
                      type="text"
                      placeholder="0"
                    />
                  </td>
                  <td className=" border-x  text-center p-2">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "training")}
                      className="pl-4 border border-black w-10/12"
                      type="number"
                      placeholder="0"
                    />
                  </td>
                  <td className="border-x  text-center p-2 ">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "capex")}
                      className="pl-4 border border-black w-10/12"
                      type="number"
                      placeholder="0"
                    />
                  </td>
                  <td className="border-x  text-center p-2">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "general_expenses")}
                      className="pl-4 border border-black w-10/12"
                      type="number"
                      placeholder="0"
                    />
                  </td>
                </tr>
              )} */}
                </tbody>
              </table>
              {error && <p className="text-danger text-lg  m-2 text-[#000]">{error}</p>}
              <div className="w-full my-5 flex gap-2 justify-end">
                <button
                  className="bg-[#fff] text-[#061d5a] border border-[#061d5a] rounded-3xl  px-10 py-1"
                  onClick={() => {
                    // setCfoTableView(false)
                    navigate("/budget")
                  }}
                >
                  Back
                </button>
                <button className="bg-[#061d5a] text-white rounded-3xl semi-bold  px-10 py-1" onClick={submitBudget}>
                  Save
                </button>
              </div>
            </>
          )}
        </>
      )}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingUpdate}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default EditBudgetTable
