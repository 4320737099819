import * as React from "react"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import { useDispatch, useSelector } from "react-redux"
import ReactLoading from "react-loading"
import { getBudgetComments, getBudgetRecordComments, postBudgetComment, postBudgetRecordComment } from "../../../Redux/slices/commentsSlice"
import moment from "moment"

export default function CommentsSideBar({ children, budgetId, recordId, budgetType, type }) {
  const [state, setState] = React.useState(false)
  const menuRef = React.useRef(null)
  const dispatch = useDispatch()
  const { loading: loadingComments, budgetComments } = useSelector((state) => state.commentsReducer)
  const [comment, setComment] = React.useState("")
  let { user } = JSON.parse(localStorage.getItem("userInfo"))
  const [mounted, setMounted] = React.useState(false)

  const toggleDrawer = (anchor, open) => (event) => {
    if (menuRef.current?.contains(event.target)) {
      // setMounted(false)
      return
    }
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      // setMounted(false)
      return
    }
    setState(open)
    if (!open) {
      setMounted(false)
    }

    if (type === "budget" && open) {
      dispatch(
        getBudgetComments({
          budgetId,
          budgetType,
        })
      )
      setMounted(true)
    } else if (type === "record" && open) {
      dispatch(
        getBudgetRecordComments({
          budgetId,
          recordId,
          budgetType,
        })
      )
      setMounted(true)
    }
  }

  const addComment = () => {
    if (type === "budget") {
      dispatch(
        postBudgetComment({
          budgetId,
          budgetType,
          comment: {
            content: comment,
          },
        })
      )
    } else if (type === "record") {
      dispatch(
        postBudgetRecordComment({
          budgetId,
          recordId,
          budgetType,
          comment: {
            content: comment,
          },
        })
      )
    }
    setComment("")
  }

  const list = (anchor) => (
    <Box ref={menuRef} sx={{ width: 350 }} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <div className="w-full h-[100vh] p-4 bg-[#fff]  flex flex-col">
        <div className="p-2 py-3  rounded-xl text-center bg-[#071C59] text-white font-bold"> Budget Comments </div>

        <div className="flex-1 border rounded-lg  my-3 border-[#d2d6d4] p-2  bg-[#eaeeec]">
          {/* {budgetComments.map((message, index) => {
            if (user.email === message.user_obj.email) {
              return (
                <div key={index} className="flex">
                  <div className="bg-white border border-[#d2d6d4] px-2 py-1 rounded-md rounded-tl-none ">{message.content}</div>
                </div>
              )
            } else {
              return (
                <div key={index} className="flex flex-row-reverse">
                  <div className="bg-[#071C59] px-2 py-1 rounded-md rounded-tr-none text-white">{message.content}</div>
                </div>
              )
            }
          })} */}
          {budgetComments && mounted && (
            <>
              {budgetComments.map((message, index) => {
                if (user.email === message.user_obj.email) {
                  return (
                    <div key={index} className="flex">
                      <div className="mb-2">
                        {/* <div className="w-full flex justify-end text-[0.8rem] text-[#656565]">hamada</div> */}
                        <div className="bg-white border border-[#d2d6d4] px-2 py-1 rounded-md rounded-tl-none ">{message.content}</div>
                        <div className="w-full flex justify-start ml-2 text-[0.8rem] text-[#656565]">
                          {moment(new Date(message.created)).format("DD/MM/YYYY HH:mm")}
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div key={index} className="flex flex-row-reverse">
                      <div className="mb-2">
                        <div className="w-full flex justify-end text-[0.8rem] text-[#656565]">{message.user_obj.first_name}</div>
                        <div className="bg-[#071C59] px-2 py-1 rounded-md rounded-tr-none text-white">{message.content}</div>
                        <div className="w-full flex justify-end text-[0.8rem] text-[#656565]">
                          {moment(new Date(message.created)).format("DD/MM/YYYY HH:mm")}
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </>
          )}
        </div>
        <div className="flex gap-2 ">
          <input
            onChange={(e) => setComment(e.target.value)}
            className="border border-[#d2d6d4] w-[78%] px-2 py-3 rounded-md	"
            type="text"
            placeholder="your comment"
            value={comment}
          />
          {loadingComments ? (
            <div className="flex  items-center justify-center w-[4vw]">
              <ReactLoading className="flex  items-center justify-center" type="spin" color="#AF1027" width={"2rem"} height={"2rem"} />
            </div>
          ) : (
            <button onClick={addComment} className="bg-[#AF1027]  text-white px-4 font-bold  rounded-md">
              Send
            </button>
          )}
        </div>
      </div>
    </Box>
  )

  return (
    <div>
      <React.Fragment>
        {/* <Button >right</Button> */}
        <div onClick={toggleDrawer("right", true)}>{children}</div>
        <Drawer anchor="right" open={state} onClose={toggleDrawer("right", false)}>
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
