import React from "react"
import { fixNum } from "../../../../utils/fixNum"
import styles from "../../dashboard.module.css"

const Cards = ({ dashboardData }) => {
  return (
    <div className=" flex gap-2 w-full max-w-full flex-col 	md:flex-row">
      {Object.values(dashboardData).map(
        (card) =>
          (card.title === "Total Premium" ||
            card.title === "Earning Premium" ||
            card.title === "OI Management Insurance Company" ||
            card.title === "OI Retention" ||
            card.title === "OI Self Funded") && (
            // ||
            // card.title === "Loss Ratio"
            <div className={`${styles.card} !w-full md:!w-[20%]`}>
              <div className="w-full flex flex-col justify-between">
                <span className="text-white text-lg semi-bold"> {card.title === "OI Management Insurance Company" ? "OI MIC" : card.title}</span>
                <span className=" text-[#cfcfb8] semi-bold text-xl">{card.is_percentage ? `${card.amount * 100} %` : fixNum(card.amount)}</span>
              </div>
            </div>
          )
      )}
      {/* <div className={`${styles.card} !w-full md:!w-[20%]`}>
        <div className="w-full flex justify-between items-center">
          <span className="text-white text-lg semi-bold">Earning Premium</span>
          <span className="text-white text-[#cfcfb8] semi-bold text-xl">80.8k</span>
        </div>
        <div>
          <span className="text-gray-200">this month</span>
          <div className="w-full bg-gray-200 h-2 my-1 rounded-lg">
            <div className="bg-[#af1027] h-2 w-[60%] rounded-lg"></div>
          </div>
          <div className="w-full flex justify-between">
            <span className="text-gray-200">last month</span>
            <span className="text-gray-200">60%</span>
          </div>
        </div>
      </div>
      <div className={`${styles.card} !w-full md:!w-[20%]`}>
        <div className="w-full flex justify-between items-center">
          <span className="text-white text-lg semi-bold">OI Self Fund</span>
          <span className="text-white text-[#cfcfb8] semi-bold text-xl">52.6k</span>
        </div>
        <div>
          <span className="text-gray-200">this month</span>
          <div className="w-full bg-gray-200 h-2 my-1 rounded-lg">
            <div className="bg-[#af1027] h-2 w-[60%] rounded-lg"></div>
          </div>
          <div className="w-full flex justify-between">
            <span className="text-gray-200">last month</span>
            <span className="text-gray-200">60%</span>
          </div>
        </div>
      </div>
      <div className={`${styles.card} !w-full md:!w-[20%]`}>
        <div className="w-full flex justify-between items-center">
          <span className="text-white text-lg semi-bold">OI Retention</span>
          <span className="text-white text-[#cfcfb8] semi-bold text-xl">20.1k</span>
        </div>
        <div>
          <span className="text-gray-200">this month</span>
          <div className="w-full bg-gray-200 h-2 my-1 rounded-lg">
            <div className="bg-[#af1027] h-2 w-[60%] rounded-lg"></div>
          </div>
          <div className="w-full flex justify-between">
            <span className="text-gray-200">last month</span>
            <span className="text-gray-200">60%</span>
          </div>
        </div>
      </div>
      <div className={`${styles.card} !w-full md:!w-[20%]`}>
        <div className="w-full flex justify-between items-center">
          <span className="text-white text-lg semi-bold">OI Ins Company</span>
          <span className="text-white text-[#cfcfb8] semi-bold text-xl ">30.7k</span>
        </div>
        <div>
          <span className="text-gray-200">this month</span>
          <div className="w-full bg-gray-200 h-2 my-1 rounded-lg">
            <div className="bg-[#af1027] h-2 w-[60%] rounded-lg"></div>
          </div>
          <div className="w-full flex justify-between">
            <span className="text-gray-200">last month</span>
            <span className="text-gray-200">60%</span>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Cards
