import React from "react"
import { Route, Routes } from "react-router-dom"
import LoginScreen from "./screens/loginScreen/LoginScreen"
import PrivateRoutes from "./screens/PrivateRoutes"
function App() {
  // const clickHandler = () => {
  //   console.log(fixNum(1786234.234))
  // }
  return (
    <div className="app p-48">
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/*" element={<PrivateRoutes />} />
      </Routes>
      {/* <button onClick={clickHandler}>Click</button> */}
    </div>
  )
}

export default App
