import React, { useState } from "react"
import { FaSignOutAlt, FaFileContract, FaRegChartBar, FaDatabase, FaMoneyCheckAlt, FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa"
import { BsTools } from "react-icons/bs"
import styles from "./sidebar.module.css"
import { NavLink, useNavigate } from "react-router-dom"
import { resetLoginUser } from "../../Redux/slices/loginSlice"
import { useDispatch } from "react-redux"
import logoImg from "../../assets/Med-Right-Logo.png"
import smallLogoImg from "../../assets/Logo-Small.png"
const Sidebar = ({ children }) => {
  let { user } = JSON.parse(localStorage.getItem("userInfo"))
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(resetLoginUser())
    localStorage.removeItem("userInfo")
    navigate("/login")
  }
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  let menuItem = [
    {
      path: "/",
      name: "Dashboard",
      icon: <FaRegChartBar size={"1.5em"} />,
    },
    {
      path: "/data-insertion",
      name: "Inputs",
      icon: <FaDatabase size={"1.5em"} />,
    },
  ]
  if (user.role === "CFO") {
    menuItem = [
      ...menuItem,
      {
        path: "/budget",
        name: "Budget",
        icon: <FaMoneyCheckAlt size={"1.5em"} />,
      },
      {
        path: "/configuration",
        name: "Configuration",
        icon: <BsTools size={"1.5em"} />,
      },
      {
        path: "/reports",
        name: "Reports",
        icon: <FaFileContract size={"1.5em"} />,
      },
    ]
  }
  if (user.role === "MANAGER") {
    menuItem = [
      ...menuItem,
      {
        path: "/manager-budget",
        name: "Budget",
        icon: <FaMoneyCheckAlt size={"1.5em"} />,
      },
      {
        path: "/configuration",
        name: "Configuration",
        icon: <BsTools size={"1.5em"} />,
      },
      {
        path: "/reports",
        name: "Reports",
        icon: <FaFileContract size={"1.5em"} />,
      },
    ]
  }
  return (
    <div>
      <div className={styles.sidebar} style={{ width: isOpen ? "15vw" : "5vw" }}>
        <div
          style={{ justifyContent: !isOpen && "center", height: "5rem", alignItems: "center" }}
          className={`${styles.top_section} flex-col lg:flex-row`}
        >
          <img style={{ display: isOpen ? "block" : "none" }} src={logoImg} alt="logo" className="w-32 mt-3 sm:min-w-[7rem] lg:min-w-[10rem]	" />
          <img style={{ display: !isOpen ? "block" : "none" }} src={smallLogoImg} alt="logo" className="w-10 mt-3  lg:min-w-[1rem] rounded	" />
        </div>
        <div style={{ flex: 1 }} className="flex flex-col mt-5 items-center">
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              style={{ justifyContent: !isOpen && "center", minWidth: isOpen && "10rem" }}
              key={index}
              className={({ isActive }) => (isActive ? `${styles.link} ${styles.active}` : `${styles.link}`)}
            >
              <div className={styles.icon}>{item.icon}</div>
              <div style={{ display: isOpen ? "block" : "none" }} className={styles.link_text}>
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        <div onClick={logoutHandler} className="w-full   absolute bottom-12 pointer text-[#071c59]">
          {!isOpen ? (
            <div className="flex  align-center justify-center">
              <FaSignOutAlt size={"1.2rem"} />
            </div>
          ) : (
            <div className="flex !px-5  w-full gap-2">
              <div>
                <FaSignOutAlt size={"1.3rem"} />
              </div>
              <div style={{ display: isOpen ? "block" : "none" }} className={styles.link_text}>
                Logout
              </div>
            </div>
          )}
        </div>
        <div className=" py-5  ">
          {isOpen ? (
            <div className="w-full flex justify-end px-2">
              <FaAngleDoubleLeft className="pointer text-[#061e5b] " onClick={toggle} />
            </div>
          ) : (
            <div className="w-full flex justify-center">
              <FaAngleDoubleRight className="pointer text-[#061e5b]" onClick={toggle} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
