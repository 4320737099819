import React, { useEffect, useState } from "react"
import { FaPlus, FaArrowLeft, FaMinusCircle, FaPenAlt, FaEllipsisV } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import ReactLoading from "react-loading"
import { getOneBudgetTypeCfo } from "../../../../Redux/slices/budgetSlice"
import FilterHeader from "../../../../screens/CFObudgetScreen/CFOpreview/FilterHeader"
import CommentsSideBar from "../../../../screens/ManagerBudgestScreen/CommentsSideBar/CommentsSideBar"
import StatusMenu from "../../../../screens/CFObudgetScreen/budgetTables/StatusMenu"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import { useParams } from "react-router-dom"
import { fixNum } from "../../../utils/fixNum"

const PreviewTrainingCfo = ({ state }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { loading: loadingBudget, cfoPreviewBudget } = useSelector((state) => state.budgetReducer)
  const { loading, departments } = useSelector((state) => state.deptAndEmployeesReducer)
  const [departmentValue, setDepartmentValue] = React.useState(null)
  const { loading: loadingStatus } = useSelector((state) => state.budgetStatusReducer)

  const [mounted, setMounted] = useState(false)
  const [rows, setRows] = useState([])

  useEffect(() => {
    dispatch(getOneBudgetTypeCfo({ type: "training", id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (cfoPreviewBudget && mounted) {
      setRows(cfoPreviewBudget)
    }
    setMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfoPreviewBudget])

  /******** start departments filter *********** */

  useEffect(() => {
    if (mounted) {
      if (departmentValue) {
        let newArr = cfoPreviewBudget.filter((d) => d.department.id === departmentValue.id)
        if (newArr) {
          setRows(newArr)
        } else {
          setRows([])
        }
      } else {
        setRows(cfoPreviewBudget)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentValue])
  /******** end departments filter *********** */
  /******** end departments filter *********** */
  ///////// calculation functions
  const superTotalFn = () => {
    let superTotal = 0
    rows.forEach((row, i) => {
      if (rows[i].training) {
        // superTotal = superTotal + trainings?.find((t) => t.id === parseInt(row.training))?.price
      }
    })

    rows.forEach((d, i) => {
      d.data.forEach((row, i) => {
        if (row.training_obj.price) {
          superTotal = superTotal + row.training_obj.price
        }
      })
    })
    if (superTotal) return superTotal
    else return 0
  }
  /////

  return (
    <>
      {loadingBudget || loading ? (
        <div className="w-full flex justify-center">
          <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
        </div>
      ) : (
        <>
          <div className="mb-5">
            <FilterHeader value={departmentValue} setValue={setDepartmentValue} departments={departments} />
          </div>
          {rows.length ? (
            <table className={`w-full border-b  inputsTable`}>
              <thead>
                <tr className="bg-[#061d5a] text-white p-1">
                  {state && <th className=" border-x border-x-white p-2 border-b "> </th>}
                  <th className=" border-x border-white p-2 "> Department</th>
                  <th className=" border-x border-white p-2 "> Name</th>
                  <th className=" border-x border-white p-2 "> HR code</th>
                  <th className=" border-x border-white p-2 "> Training</th>
                  <th className=" border-x border-white p-2 ">Price </th>
                  <th className=" border-x border-white p-2 ">Date </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((d) =>
                  d.data.map((row, i) => (
                    <tr
                      key={i}
                      className={`!border-y 
                  ${row.status === "NEGOTIATE" && "!bg-white"}
                  ${row.status === "ACCEPTED" && "!bg-[#39ab35] !text-white"}
                  ${row.status === "REJECTED" && "!bg-[#f00e0e] !text-white"}
                   `}
                    >
                      {state === "open" ? (
                        <td className={`border-x  text-center border-y p-0 bg-white "}`}>
                          <div className="flex justify-center items-center">
                            <div className="flex items-center ">
                              <StatusMenu info={{ budgetId: d.id, recordId: row.id, budgetType: "training" }}>
                                <FaEllipsisV className="pointer" />
                              </StatusMenu>
                              <CommentsSideBar type="record" budgetType="training" budgetId={d.id} recordId={row.id}>
                                <button
                                  className="hover:bg-[#071C59] hover:text-white flex gap-2 items-center text-black flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
                                  onClick={() => {}}
                                >
                                  <FaPenAlt style={{ width: "0.7rem" }} />
                                </button>
                              </CommentsSideBar>
                            </div>
                          </div>
                        </td>
                      ) : null}
                      {/* <td className={`border-x  text-center  border-b "}`}>{row.department.name}</td> */}

                      {/* <td className="text-center p-2 border-x ">{employees[row.employee]?.hr_code}</td> */}
                      <td className="text-center p-2 border-x ">{d.department.name}</td>
                      <td className="text-center p-2 border-x ">{row.employee_obj.name}</td>

                      <td className="text-center p-2 border-x ">{row.training}</td>

                      {/* <td className="text-center p-2 border-x ">{trainings?.find((t) => t.id === parseInt(row.training))?.price} EGP</td> */}
                      <td className="text-center p-2 border-x ">{row.training_obj.name}</td>
                      <td className="text-center p-2 border-x ">{fixNum(row.training_obj.price)}</td>

                      <td className="text-center p-2 border-x ">{row.effective_date}</td>
                    </tr>
                  ))
                )}
                <tr>
                  <td className="border-x  text-center p-2 max-w-[1vw]">Total</td>
                  <td className="border-x  text-center p-2"></td>
                  <td className="border-x  text-center p-2"></td>
                  <td className="border-x  text-center p-2"></td>
                  <td className="border-x  text-center p-2"></td>
                  <td className="border-x  text-center p-2">{fixNum(superTotalFn())}</td>
                  <td className="border-x  text-center p-2"></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div className="text-center text-xl">No data</div>
          )}
        </>
      )}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingStatus}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default PreviewTrainingCfo
