import React from "react"
import { useNavigate } from "react-router-dom"

const BonusTable = () => {
  const navigate = useNavigate()

  const employeesTable = (
    <>
      <table className={`w-full border-b  inputsTable`}>
        <thead>
          <tr className="bg-[#061d5a] text-white p-1">
            <th className=" border-x border-white p-2 "> Employee</th>
            <th className=" border-x border-white p-2 "> HR Code</th>
            <th className=" border-x border-white p-2 "> Retention</th>
            <th className=" border-x border-white p-2 "> Managment</th>
            <th className=" border-x border-white p-2 "> Self Fund</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center p-2">Employee 1</td>
            <td className="text-center  p-2 border-x ">2000</td>
            <td className="text-center  p-2 border-x ">4000</td>
            <td className="text-center  p-2 border-x ">3000</td>
            <td className="text-center  p-2 border-x ">2000</td>
          </tr>
        </tbody>
      </table>

      <div className="w-full my-5 flex gap-2 justify-end">
        <button
          className="bg-[#061d5a] text-white rounded-3xl semi-bold  px-10 py-1"
          onClick={() => {
            navigate("/configuration")
          }}
        >
          Done
        </button>
      </div>
    </>
  )
  return employeesTable
}

export default BonusTable
