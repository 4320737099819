import React, { useState } from "react"
import { FaPlus } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import RequestModalReport from "./RequestReportModal"

const ReportsScreen = () => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const mainDiv = (
    <>
      <div className="w-full mt-10 px-10 flex gap-3  justify-between">
        <h2 className="semi-bold text-lg py-3  text-[#061d5a]">Requested Reports</h2>
        <div className="flex gap-3 ">
          <button className="bg-[#061d5a] flex gap-2 items-center text-white rounded-3xl semi-bold px-10 py-2 my-3">
            <FaPlus /> <div onClick={() => handleOpen()}>Request report</div>
          </button>
        </div>
      </div>
      <div className="w-full  px-10 ">
        <table className="w-full shadow-2">
          <thead>
            <tr className="shadow-md">
              <th className="  p-4  ">Type</th>
              <th className="  p-4  ">Request Date</th>
              <th className="= p-4  ">Status</th>
              <th className="p-4  "></th>

              {/* <th className="bg-[#061d5a] text-white p-2 rounded-xl border-x-4 border-[#fff]">From</th>{" "}
              <th className="bg-[#061d5a] text-white p-2 rounded-xl border-x-4 border-[#fff]">To</th> */}
            </tr>
          </thead>
          <tbody>
            <tr className="hover:shadow-2">
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Report type</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">10/12/2022</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center text-[#ebc26c] semi-bold">Pending</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">
                <button disabled className="bg-[#808080] text-black  rounded-3xl semi-bold px-10 py-1 my-3">
                  view
                </button>
              </td>
            </tr>
            <tr className="hover:shadow-2">
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Report type</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">10/12/2022</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center text-[#1dd050] semi-bold">Completed</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">
                <button className="bg-[#24a0ed] text-white  rounded-3xl semi-bold px-10 py-1 my-3">view</button>
              </td>
            </tr>
            <tr className="hover:shadow-2">
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Report type</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">10/12/2022</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center text-[#ebc26c] semi-bold">Pending</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">
                <button disabled className="bg-[#808080] text-black  rounded-3xl semi-bold px-10 py-1 my-3">
                  view
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
  //   const reset = () => {
  //     setselectedTable("")
  //   }
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full px-10 border-b-2 pb-3 mb-5">
        <h2 className="semi-bold text-lg lightFont">Reports</h2>
      </div>
      {mainDiv}
      <RequestModalReport open={open} handleOpen={handleOpen} handleClose={handleClose} />
    </div>
  )
}

export default ReportsScreen
