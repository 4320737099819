import React, { useState } from "react"
import { FaPlus } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

const ConfigScreen = () => {
  const navigate = useNavigate()

  const mainDiv = (
    <>
      <div className="w-full mt-10 px-10 flex gap-3  justify-between">
        <h2 className="semi-bold text-lg py-3  text-[#061d5a]">History</h2>
        <div className="flex gap-3 ">
          <button
            onClick={() => navigate("/configuration/add-configuration")}
            className="bg-[#061d5a] flex gap-2 items-center text-white rounded-3xl semi-bold px-10 py-2 my-3"
          >
            <FaPlus /> <div>Add configuration</div>
          </button>
          <button
            onClick={() => navigate("/configuration/add-targets")}
            className="bg-[#061d5a] flex gap-2 items-center text-white rounded-3xl semi-bold px-10 py-2 my-3"
          >
            <FaPlus />
            <div>Add Targets</div>
          </button>
          <button
            onClick={() => navigate("/configuration/preview-progress")}
            className="bg-[#fff] text-[#061d5a] border border-[#061d5a] rounded-3xl px-10 py-2 my-3"
          >
            Preview progress
          </button>
        </div>
      </div>
      <div className="w-full  px-10 ">
        <table className="w-full shadow-2">
          <thead>
            <tr className="shadow-md">
              <th className="  p-4  ">Department</th>
              <th className="  p-4  ">Added on</th>
              <th className="= p-4  ">From</th>
              <th className="p-4  ">To</th>

              {/* <th className="bg-[#061d5a] text-white p-2 rounded-xl border-x-4 border-[#fff]">From</th>{" "}
              <th className="bg-[#061d5a] text-white p-2 rounded-xl border-x-4 border-[#fff]">To</th> */}
            </tr>
          </thead>
          <tbody>
            <tr className="hover:shadow-2">
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Sales</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">10/12/2022</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">1/1/2023</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">31/12/2023</td>
            </tr>
            <tr className="hover:shadow-2">
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Sales</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">10/12/2022</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">1/1/2023</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">31/12/2023</td>
            </tr>
            <tr className="hover:shadow-2">
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">Sales</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">10/12/2022</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">1/1/2023</td>
              <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">31/12/2023</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
  //   const reset = () => {
  //     setselectedTable("")
  //   }
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full px-10 border-b-2 pb-3 mb-5">
        <h2 className="semi-bold text-lg lightFont">Configuration</h2>
      </div>
      {mainDiv}
    </div>
  )
}

export default ConfigScreen
