import axios from "axios"
export const url = process.env.REACT_APP_API_URL || "http://ml.med-right.net:8001/api"
// export const url = "https://9d7b-41-42-35-3.eu.ngrok.io/api"

export const API = axios.create({
  baseURL: url,
})
API.interceptors.request.use(
  (config) => {
    // check for token in localstorage first
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if (userInfo && userInfo?.token) {
      config.headers = { Authorization: `Token ${userInfo.token}` }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
