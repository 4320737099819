import React, { useState } from "react"
import styles from "./dashboard.module.css"
import FirstPage from "../../components/pagesSpec/Dashboard/FirstPage"
import FirstPageX from "../../components/pagesSpec/Dashboard/FirstPageX"

import SecondPage from "../../components/pagesSpec/Dashboard/SecondPage"
import ThirdPage from "../../components/pagesSpec/Dashboard/ThirdPage"
import FourthPage from "../../components/pagesSpec/Dashboard/FourthPage"

import DashboardTabs from "../../components/pagesSpec/Dashboard/Tabs"

const Dashboard = () => {
  const [tabValue, setTabValue] = useState("1")
  return (
    <div className={`${styles.dashboard} flex p-4 pt-0`}>
      <div className="w-full px-10 flex items-end justify-between border-b-2  mb-5">
        <h2 className="semi-bold text-lg lightFont mb-3">Dashboard</h2>
        <DashboardTabs value={tabValue} setValue={setTabValue} />
      </div>
      {tabValue === "1" && <FirstPageX />}
      {tabValue === "2" && <SecondPage />}
      {tabValue === "3" && <ThirdPage />}
      {tabValue === "4" && <FourthPage />}
    </div>
  )
}

export default Dashboard
