import React, { useState } from "react"
// import { Menu, MenuItem, Typography } from "@material-ui/core"
//backdrop for loading
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

import NestedMenuItem from "material-ui-nested-menu-item"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { List } from "reactstrap"
import { Divider } from "@mui/material"
import { useDispatch } from "react-redux"
import { changeBudgetStatusCfo } from "../../../Redux/slices/budgetStatusSlice"

export const StatusMenu = ({ children, info }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  // const [status, setStatus] = useState("")
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // const handleClick = (event) => {
  //   if (menuPosition) {
  //     return
  //   }
  //   event.preventDefault()
  //   console.log(event.pageX)
  //   console.log(event.pageY - 200)

  //   setMenuPosition({
  //     top: event.pageY + 20,
  //     left: event.pageX,
  //   })
  // }

  const handleItemClick = (status) => {
    dispatch(changeBudgetStatusCfo({ ...info, status }))
    setAnchorEl(null)
  }

  return (
    <>
      <div
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className=" pointer"
        onClick={handleClick}
        // onMouseOver={handleClick}
      >
        {children}
      </div>

      <Menu
        // style={{ backgroundColor: "black", padding: "0px", margin: "0px" }}
        // open={!!menuPosition}
        // onClose={() => setMenuPosition(null)}
        // anchorReference="anchorPosition"
        // anchorPosition={menuPosition}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorPosition={{ top: 0, left: 0 }}
      >
        <div onMouseLeave={handleClose}>
          {/* <MenuItem onClick={() => handleComment("Comment")}>Comment</MenuItem> */}

          {/* <Divider /> */}
          <MenuItem onClick={() => handleItemClick("NEGOTIATE")}>Negotiate</MenuItem>
          <MenuItem onClick={() => handleItemClick("ACCEPTED")}>Accepted</MenuItem>
          <MenuItem onClick={() => handleItemClick("REJECTED")}>Rejected</MenuItem>
          <Divider />
        </div>

        {/* <NestedMenuItem label="Status" parentMenuOpen={!!menuPosition} onClick={handleItemClick}>
          <MenuItem onClick={handleItemClick}>Negotiable</MenuItem>
          <MenuItem onClick={handleItemClick}>Accepted</MenuItem>
          <MenuItem onClick={handleItemClick}>Rejected</MenuItem>
        </NestedMenuItem> */}
      </Menu>
    </>
  )
}

export default StatusMenu
