import React from "react"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Bar } from "react-chartjs-2"
// import faker from "faker"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  maintainAspectRatio: false,
  // indexAxis: "y",
  responsive: true,
  barThickness: 10,
  scales: {
    x: {
      max: 1200,
    },
  },
  Tooltip: {
    enabled: true,
  },
  plugins: {
    // legend: {
    //   position: "top",
    // },
    legend: { display: false },
  },
}

const salesData = [
  { label: "Jan", value: 100 },
  { label: "Feb", value: 200 },
  { label: "Mar", value: 300 },
  { label: "Apr", value: 200 },
  { label: "May", value: 500 },
  { label: "Jun", value: 500 },
  { label: "Jul", value: 1200 },
  { label: "Aug", value: 800 },
  { label: "Sep", value: 900 },
  { label: "Oct", value: 1000 },
  { label: "Nov", value: 1100 },
  { label: "Dec", value: 900 },
]
// const brokersData = [
//   { label: "Retention", value: 400 },
//   { label: "Self-fund", value: 1100 },
//   { label: "Insurance", value: 700 },
// ]

// const dataTrial = {
//   title: "T",
//   x_label: "Months",
//   y_label: "EGP",
//   data: [
//     {
//       label: "Jan",
//       values: [
//         {
//           label: "Expenses",
//           amount: 1000,
//         },
//         {
//           label: "Budget",
//           amount: 1000,
//         },
//       ],
//     },
//   ],
// }

const dataTrial = {
  title: "T",
  x_label: "Months",
  y_label: "EGP",
  x_values: ["Jan", "Feb"],
  data: [
    {
      label: "Expenses",
      values: [1000, 2000],
    },
    {
      label: "Budget",
      values: [2000, 3000],
    },
  ],
}

export const data = {
  // labels: salesData.map((d) => d.label),
  labels: dataTrial.x_values,
  // datasets: [
  //   // {
  //   //   label: "Sales",
  //   //   data: salesData.map((d) => d.value),
  //   //   //   borderColor: "rgb(255, 99, 132)",
  //   //   backgroundColor: "#0a1033",
  //   //   //   barThickness: 10,
  //   //   borderRadius: 10,
  //   // },
  //   // {
  //   //   label: "Khaled",
  //   //   data: salesData.map((d) => d.value),
  //   //   //   borderColor: "rgb(255, 99, 132)",
  //   //   backgroundColor: "#AF1027",
  //   //   //   barThickness: 10,
  //   //   borderRadius: 10,
  //   // },
  //   {
  //     label: dataTrial.data[0].values[0].label,
  //     data: dataTrial.data.map((d) => d.values[0].amount),
  //     backgroundColor: "#0a1033",
  //     //   //   barThickness: 10,
  //     borderRadius: 10,
  //   },
  // ],
  datasets: dataTrial.data.map((d, i) => {
    return {
      label: d.label,
      data: d.values,
      backgroundColor: "#0a1033",
      borderRadius: 10,
    }
  }),
}

const BarChart4FirstRow = () => {
  return (
    <div className="m-0 p-0" style={{ width: "100%", height: "18rem" }}>
      <Bar options={options} data={data} />
    </div>
  )
}
export default BarChart4FirstRow
