import React from "react"
import { fixNum } from "../../../../utils/fixNum"
import styles from "../../dashboard.module.css"

const Cards = ({ dashboardData }) => {
  return (
    <div className=" flex gap-2 w-full max-w-full flex-col 	md:flex-row">
      {Object.values(dashboardData).map(
        (card) =>
          (card.title === "Total Acquisition" || card.title === "Total G&A") && (
            <div className={`${styles.card} !w-full md:!w-[20%]`}>
              <div className="w-full flex justify-between items-center">
                <span className="text-white text-lg semi-bold">{card.title}</span>
                <span className=" text-[#cfcfb8] semi-bold text-xl">{fixNum(card.amount)}</span>
              </div>
            </div>
          )
      )}
    </div>
  )
}

export default Cards
