import * as XLSX from "xlsx"

export const readExcel = (file, setData) => {
  console.log(file)
  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)

    fileReader.onload = (e) => {
      console.log(e.target.result)
      const bufferArray = e.target.result
      // get only first 100 rows FROM THE SHEET
      const wb = XLSX.read(bufferArray, { type: "buffer", range: 1, sheetRows: 101 })

      // const wb = XLSX.read(bufferArray, { type: "buffer" })

      const wsname = wb.SheetNames[0]

      const ws = wb.Sheets[wsname]

      const data = XLSX.utils.sheet_to_json(ws)

      resolve(data)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })

  promise.then((d) => {
    setData(d)
  })
}
