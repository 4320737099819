import React, { useEffect, useState } from "react"
import styles from "../budget.module.css"
import { useDispatch, useSelector } from "react-redux"
import { getDepartments } from "../../../Redux/slices/dept_employees_slice"
import ReactLoading from "react-loading"
import { postCfoBudget } from "../../../Redux/slices/budgetSlice"
import moment from "moment"
import { useNavigate } from "react-router-dom"

const AddBudgetTable = ({ setCfoTableView, fromDate, toDate, deadline, radioValue }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  /*************************** start handle change  ****************************** */

  const [budget, setBudget] = useState({})
  const handleChange = (department, index, cap, field) => {
    if (radioValue === "department") {
      const obj = { department: department.id, cap }
      let newArr = budget[field]
      newArr[index] = obj
      const final = { ...budget, [field]: newArr }
      setBudget(final)
    } else {
      departments.forEach((d, i) => {
        const obj = { department: d.id, cap }
        let newArr = budget[field]
        newArr[i] = obj
        const final = { ...budget, [field]: newArr }
        setBudget(final)
      })
    }
  }
  console.log(budget)

  /*************************** start handle change  ****************************** */

  //departments get
  const { loading, departments } = useSelector((state) => state.deptAndEmployeesReducer)
  useEffect(() => {
    dispatch(getDepartments())
  }, [dispatch])
  ////setting initial value
  useEffect(() => {
    let general_expenses = []
    let capex = []
    let new_hire = []
    let payroll = []
    let training = []
    let obj = {
      department: "",
      cap: 0,
    }
    if (departments) {
      departments.forEach((department) => {
        general_expenses.push({ ...obj, department: department.id })
        new_hire.push({ ...obj, department: department.id })
        capex.push({ ...obj, department: department.id })
        payroll.push({ ...obj, department: department.id })
        training.push({ ...obj, department: department.id })
      })
    }
    const initialArr = {
      general_expenses,
      capex,
      new_hire,
      payroll,
      training,
    }
    setBudget(initialArr)
  }, [departments])

  //submiting
  const [error, setError] = useState("")
  const submitBudget = () => {
    setError("")
    // if (
    //   budget.capex < departments.length ||
    //   budget.new_hire < departments.length ||
    //   budget.payroll < departments.length ||
    //   budget.general_expenses < departments.length ||
    //   budget.training < departments.length
    // ) {
    //   console.log("errorrr")
    //   setError("please fill all fields")
    //   return
    // }
    dispatch(
      postCfoBudget({
        ...budget,
        start_date: moment(fromDate).format("YYYY-MM-DD"),
        end_date: moment(toDate).format("YYYY-MM-DD"),
        deadline: moment(deadline).format("YYYY-MM-DD"),
      })
    )
  }
  useEffect(() => {
    setError("")
  }, [budget])

  ////// hanlding response state
  const { loading: loadingBudget, budget: resBudget, error: budgetError } = useSelector((state) => state.budgetReducer)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if ((resBudget && mounted) || (budgetError && mounted)) {
      navigate("/budget")
    }
    setMounted(true)
  }, [resBudget, budgetError])

  return (
    <>
      {loading || loadingBudget ? (
        <div className="">
          <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
        </div>
      ) : (
        <>
          <table className={`w-full border-b  ${styles.inputsTable}`}>
            <thead>
              <tr className="bg-[#061d5a] text-white p-1">
                {radioValue === "department" && <th className=" border-x border-white p-2 "> Department</th>}{" "}
                <th className=" border-x border-white p-2 "> Hiring</th>
                <th className=" border-x border-white p-2 "> Payroll</th>
                <th className=" border-x border-white p-2 "> Training</th>
                <th className=" border-x border-white p-2 "> Capex</th>
                <th className=" border-x border-white p-2 "> General expenses</th>
              </tr>
            </thead>
            <tbody>
              {radioValue === "department" && (
                <>
                  {departments.map((department, i) => (
                    <tr key={i}>
                      <td className="text-center p-2">{department.name}</td>
                      <td className="text-center p-2 border-x ">
                        <input
                          onChange={(e) => handleChange(department, i, e.target.value, "new_hire")}
                          className="pl-4 border border-black w-10/12"
                          type="number"
                          placeholder="0"
                        />
                      </td>
                      <td className="border-x  text-center p-2">
                        <input
                          onChange={(e) => handleChange(department, i, e.target.value, "payroll")}
                          className="pl-4 border border-black w-10/12"
                          type="text"
                          placeholder="0"
                        />
                      </td>
                      <td className=" border-x  text-center p-2">
                        <input
                          onChange={(e) => handleChange(department, i, e.target.value, "training")}
                          className="pl-4 border border-black w-10/12"
                          type="number"
                          placeholder="0"
                        />
                      </td>
                      <td className="border-x  text-center p-2 ">
                        <input
                          onChange={(e) => handleChange(department, i, e.target.value, "capex")}
                          className="pl-4 border border-black w-10/12"
                          type="number"
                          placeholder="0"
                        />
                      </td>
                      <td className="border-x  text-center p-2">
                        <input
                          onChange={(e) => handleChange(department, i, e.target.value, "general_expenses")}
                          className="pl-4 border border-black w-10/12"
                          type="number"
                          placeholder="0"
                        />
                      </td>
                    </tr>
                  ))}
                </>
              )}
              {radioValue !== "department" && (
                <tr>
                  <td className="text-center p-2 border-x ">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "new_hire")}
                      className="pl-4 border border-black w-10/12"
                      type="number"
                      placeholder="0"
                    />
                  </td>
                  <td className="border-x  text-center p-2">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "payroll")}
                      className="pl-4 border border-black w-10/12"
                      type="text"
                      placeholder="0"
                    />
                  </td>
                  <td className=" border-x  text-center p-2">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "training")}
                      className="pl-4 border border-black w-10/12"
                      type="number"
                      placeholder="0"
                    />
                  </td>
                  <td className="border-x  text-center p-2 ">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "capex")}
                      className="pl-4 border border-black w-10/12"
                      type="number"
                      placeholder="0"
                    />
                  </td>
                  <td className="border-x  text-center p-2">
                    <input
                      onChange={(e) => handleChange("", "", e.target.value, "general_expenses")}
                      className="pl-4 border border-black w-10/12"
                      type="number"
                      placeholder="0"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {error && <p className="text-danger text-lg  m-2 text-[#000]">{error}</p>}
          <div className="w-full my-5 flex gap-2 justify-end">
            <button
              className="bg-[#fff] text-[#061d5a] border border-[#061d5a] rounded-3xl  px-10 py-1"
              onClick={() => {
                // setCfoTableView(false)
                navigate("/budget")
              }}
            >
              Back
            </button>
            <button className="bg-[#061d5a] text-white rounded-3xl semi-bold  px-10 py-1" onClick={submitBudget}>
              Save
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default AddBudgetTable
