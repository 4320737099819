import React, { useEffect, useState } from "react"
import { FaPlus, FaFileDownload, FaArrowCircleDown } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getExcelFiles } from "../../Redux/slices/inputsSlice"
import ReactLoading from "react-loading"
import moment from "moment"

const InputsTableScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, files } = useSelector((state) => state.inputsReducer)
  const [filteredFiles, setFilteredFiles] = useState([])

  useEffect(() => {
    dispatch(getExcelFiles())
  }, [])

  useEffect(() => {
    if (files) {
      setFilteredFiles([...files].slice(0, 20))
    }
  }, [files])
  const mainDiv = (
    <>
      <div className="w-full mt-10 px-10 flex gap-3  justify-between">
        <h2 className="semi-bold text-lg py-3  text-[#061d5a]">Files</h2>
        <div className="flex gap-3 ">
          <button
            onClick={() => navigate("/add-files")}
            className="bg-[#061d5a] flex gap-2 items-center text-white rounded-3xl semi-bold px-10 py-2 my-3"
          >
            <FaPlus /> <div>Upload File</div>
          </button>
        </div>
      </div>
      <div className="w-full  px-10 ">
        <div className="!h-[70vh] overflow-scroll">
          <table className="w-full shadow-2 stickyTable fixedTable">
            <thead>
              <tr className="shadow-md stickyRow">
                {/* <th className=" p-2  xl:p-4  ">Department</th> */}
                <th className="  p-2 xl:p-4 bg-white  ">Added on</th>
                <th className="= p-2 xl:p-4 bg-white  ">Model</th>
                <th className="= p-2 xl:p-4 bg-white  ">Status</th>

                {filteredFiles.find((f) => f.status === "HAS_ERRORS") && <th className="= p-2 xl:p-4 bg-white "> </th>}

                {/* <th className="bg-[#061d5a] text-white p-2 rounded-xl border-x-4 border-[#fff]">From</th>{" "}
                  <th className="bg-[#061d5a] text-white p-2 rounded-xl border-x-4 border-[#fff]">To</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredFiles.map((file, i) => (
                <tr key={i} className="hover:shadow-2">
                  {/* <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">
                  {file.excel.replace("https://med-right.duckdns.org/media/invoice/", "")}
                </td> */}
                  <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">{moment(file.created).format("YYYY/MM/DD")}</td>
                  <td className="px-2 py-3 border-t-4  border-[#fff] text-center ">{file.model}</td>
                  <td
                    className={`px-2 py-3 border-t-4  border-[#fff] text-center 
                ${file.status === "FINISHED" && "text-[#1dd050] semi-bold"}
                ${file.status === "PENDING" && "text-[#ebc26c] semi-bold"} 
                ${file.status === "HAS_ERRORS" && "text-[#af1027] semi-bold"} 
                
                `}
                  >
                    {file.status}
                  </td>
                  {file.status === "HAS_ERRORS" ? (
                    <td className="px-2 py-3 border-t-4  border-[#fff] text-center  ">
                      <a href={file.error} target="_blank" rel="noreferrer" className="flex items-center justify-center ">
                        <button className="bg-[#af1027] text-white rounded-3xl semi-bold  flex items-center justify-center gap-2 pointer px-10 py-1">
                          Download <FaArrowCircleDown />
                        </button>
                      </a>
                    </td>
                  ) : (
                    <td className="px-2 py-3 border-t-4  border-[#fff] text-center"></td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
  //   const reset = () => {
  //     setselectedTable("")
  //   }
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full px-10 border-b-2 pb-3 mb-5">
        <h2 className="semi-bold text-lg lightFont">Data Insertion</h2>
      </div>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <ReactLoading type="spin" color="#061d5a" width={"3%"} height={"3%"} />{" "}
        </div>
      ) : (
        <>{mainDiv}</>
      )}
    </div>
  )
}

export default InputsTableScreen
