import React, { useEffect, useState } from "react"
import styles from "../budget.module.css"
import { FaPlus, FaArrowLeft, FaMinusCircle, FaCheckCircle, FaRegTimesCircle, FaPenAlt } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { getAssets, getReasons } from "../../../Redux/slices/dept_employees_slice"
import ReactLoading from "react-loading"
import moment from "moment"
import { getCapexBudget, patchCapexBudget } from "../../../Redux/slices/managerBudgetSlice"
import { addErrorMessage } from "../../../Redux/slices/messageSlice"
import { CapMessage } from "./TrainingTable"
import NoteModal from "../../../components/compound components/Modals/NoteModal"
import Tooltip from "@mui/material/Tooltip"
import CommentsSideBar from "../CommentsSideBar/CommentsSideBar"
import MainAutoCompleteComponent from "../../../components/compound components/Modals/MainAutoCompleteComponent"
import { fixNum } from "../../../components/utils/fixNum"

const CapexTable = ({ back, id, budgetSD, budgetED }) => {
  const dispatch = useDispatch()
  const [mounted, setMounted] = useState(false)
  const { loading: loadingASsets, assets, loadingReasons, reasons } = useSelector((state) => state.deptAndEmployeesReducer)
  const { budget, loadingUpdate, loading, updatedBudget, error } = useSelector((state) => state.managerBudgetReducer)
  const [note, setNote] = useState("")
  const [openNote, setOpenNote] = React.useState(false)
  const handleClose = () => {
    setOpenNote(false)
  }

  ////////////getting budget data
  const obj = {
    asset: "",
    reason: "",
    quantity: 1,
    effective_date: moment(new Date()).format("YYYY-MM-DD"),
  }
  const [rows, setRows] = useState([])
  const [cap, setCap] = useState("")

  useEffect(() => {
    dispatch(getCapexBudget(id))
    dispatch(getAssets())
    dispatch(getReasons())
    setMounted(true)
  }, [])
  useEffect(() => {
    if (assets && mounted) {
      // const data = budget.data.map((d) => d.employee)
      setCap(budget.cap)
      if (budget.data.length) {
        setRows(budget.data)
      } else {
        setRows([obj])
      }
    }
  }, [budget])

  /////response actions

  useEffect(() => {
    if (updatedBudget && mounted) {
      back()
    } else if (error && mounted) {
      dispatch(addErrorMessage(error))
      back()
    }
    setMounted(true)
  }, [updatedBudget, error])
  //add row function
  const addRow = () => {
    const newRow = [...rows, obj]
    setRows(newRow)
  }
  //remove row function
  const removeRow = (index) => {
    const newRow = rows.filter((row, i) => i !== index)
    setRows(newRow)
  }
  /////

  /******************************* start hanlding changes *************************/

  const handleChange = (e, index, name) => {
    let value = e.target.value

    // if(name===)

    //  value = parseInt(e.target.value)

    let newState = [...rows]
    newState[index] = {
      ...newState[index],
      [name]: value,
    }
    setRows(newState)
  }

  /******************************* end hanlding changes *************************/

  ///////// calculation functions

  const superTotalFn = () => {
    let superTotal = 0
    rows.forEach((row, i) => {
      if (rows[i].asset) {
        superTotal = superTotal + row.quantity * assets?.find((t) => t.id === parseInt(row.asset))?.price
      }
    })
    if (superTotal) return superTotal
    else return 0
  }
  /////

  const submit = () => {
    dispatch(
      patchCapexBudget({
        id,
        update: { data: rows },
      })
    )
  }
  const submitDone = (val) => {
    dispatch(
      patchCapexBudget({
        id,
        update: { done: true, data: rows },
      })
    )
  }
  console.log(rows)

  return (
    <>
      <label className="w-full px-5 semi-bold border-b-2 py-2 mb-4 flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-center">
          <p className="mb-2"> CAPEX Budget</p>
          <CommentsSideBar type="budget" budgetType="capex" budgetId={budget.id}>
            <Tooltip title="Add comment" arrow>
              <button className="hover:bg-[#071C59] hover:text-white flex gap-2 items-center text-black flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out">
                <FaPenAlt style={{ width: "90%" }} />
              </button>
            </Tooltip>
          </CommentsSideBar>
        </div>{" "}
        <div className="flex gap-3 items-center">
          {superTotalFn() > cap && <div className="bg-[#e7cb02] rounded-xl px-3 py-1"> You exceeded the cap!</div>}
          <div>
            Your cap for CAPEX is <span className="text-[#061d5a]">{fixNum(cap)} EGP</span>
          </div>
        </div>
      </label>
      {loadingUpdate && (
        <div className="w-full h-3/4 flex flex-col items-center justify-center">
          <ReactLoading type="bars" color="#061e5b" width={"6%"} height={"6%"} />
          <h2 className="mt-2 text-xl text-[#061e5b]">Sending budget</h2>
        </div>
      )}
      {(loading || loadingASsets || loadingReasons) && (
        <div className="w-full flex justify-center">
          <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
        </div>
      )}
      {!loading && !loadingUpdate && mounted && (
        <table className={`w-full border-b  ${styles.inputsTable}`}>
          <thead>
            <tr className="bg-[#061d5a] text-white p-1">
              <th className=" border-x border-white p-2 "> </th>
              <th className=" border-x border-white p-2 "> Asset</th>
              <th className=" border-x border-white p-2 "> Price</th>
              <th className=" border-x border-white p-2 "> Quantity</th>
              <th className=" border-x border-white p-2 ">Total </th>
              <th className=" border-x border-white p-2 ">Reason </th>
              <th className=" border-x border-white p-2 ">Date </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={i} className={`${row.status === "ACCEPTED" && `acceptedRow`} ${row.status === "REJECTED" && "rejectedRow"}`}>
                <td className={`border-x  text-center border-y p-0 "}`}>
                  <div className="flex justify-center items-center">
                    <div className="flex items-center ">
                      {row.id && (
                        <CommentsSideBar type="record" budgetType="capex" budgetId={budget.id} recordId={row.id}>
                          <button
                            className="hover:bg-[#071C59] hover:text-white flex gap-2 items-center text-black flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
                            onClick={() => {}}
                          >
                            <FaPenAlt style={{ width: "0.7rem" }} />
                          </button>
                        </CommentsSideBar>
                      )}
                    </div>
                  </div>
                </td>
                <td className="text-center p-2 border-x flex items-center gap-2">
                  <FaMinusCircle style={{ fill: "#061d5a" }} className="pointer" onClick={() => removeRow(i)} />
                  {/* <select
                    value={row.asset}
                    onChange={(e) => handleChange(e, i, "asset")}
                    className="pl-4 border border-black rounded-l py-1 w-10/12"
                    type="text"
                  >
                    <option disabled value="">
                      Asset
                    </option>
                    {assets.map((asset, i) => (
                      <option value={asset.id} key={i}>
                        {asset.name}
                      </option>
                    ))}
                  </select> */}
                  <MainAutoCompleteComponent
                    myOptions={assets}
                    myLabel="asset"
                    value={assets.find((asset) => asset.id === row.asset) ? assets.find((asset) => asset.id === row.asset) : null}
                    setValue={(v) => handleChange(v, i, "asset")}
                  />
                </td>
                <td className="text-center p-2 border-x ">{row.asset ? fixNum(assets?.find((t) => t.id === parseInt(row.asset))?.price) : 0} EGP </td>
                <td className="text-center p-2 border-x ">
                  <input
                    onChange={(e) => handleChange(e, i, "quantity")}
                    className="pl-4 border border-black w-10/12 py-4"
                    type="number"
                    value={row.quantity}
                    min={1}
                    placeholder="quantity"
                  />
                </td>
                <td className="text-center p-2 border-x ">
                  {row.quantity && row.asset ? row.quantity * assets?.find((t) => t.id === parseInt(row.asset))?.price : 0} EGP
                </td>
                <td className="text-center p-2 border-x ">
                  <select
                    value={row.reason}
                    onChange={(e) => handleChange(e, i, "reason")}
                    className="pl-4 border border-black !rounded py-1 w-10/12 py-4"
                    type="text"
                  >
                    <option disabled value="">
                      Reason
                    </option>
                    {reasons?.map((reason, i) => (
                      <option value={reason.id} key={i}>
                        {reason?.name}
                      </option>
                    ))}
                  </select>
                  {/* <MainAutoCompleteComponent
                    myOptions={reasons}
                    myLabel="reason"
                    value={reasons.find((tr) => tr.id === row.reason) ? reasons.find((tr) => tr.id === row.reason) : null}
                    setValue={(v) => handleChange(v, i, "reason")}
                  /> */}
                </td>
                <td className="text-center p-2 border-x ">
                  <input
                    value={row.effective_date}
                    onChange={(e) => handleChange(e, i, "effective_date")}
                    className="pl-1 border border-black w-10/12 py-4"
                    type="date"
                    min={budgetSD}
                    max={budgetED}
                  />
                </td>
              </tr>
            ))}
            <tr className="stickyFinalRow">
              <td className="border-x  text-center p-2">Total</td>
              <td className="border-x  text-center p-2"></td>
              <td className="border-x  text-center p-2"></td>
              <td className="border-x  text-center p-2"></td>
              <td className="border-x  text-center p-2"></td>
              <td className="border-x  text-center p-2">{fixNum(superTotalFn())}</td>
              <td className="border-x  text-center p-2"></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className=" p-1 text-center flex justify-end">
                <button onClick={addRow} className="bg-[#061d5a] px-1 rounded-lg flex gap-2 items-center py-1 !px-3 semi-bold text-white">
                  Add row
                  <FaPlus />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="w-full my-4 flex justify-end gap-2 ">
        {!budget.done ? (
          <button
            className="bg-[#39ab35] flex items-center gap-2 text-white rounded-3xl semi-bold px-10 py-1  transition ease-in-out duration-300"
            onClick={() => submitDone(true)}
          >
            mark as Done <FaCheckCircle />
          </button>
        ) : (
          <button className="bg-[#f00e0e] flex items-center gap-2 text-white rounded-3xl semi-bold px-10 py-1" onClick={() => submitDone(false)}>
            unmark as Done <FaRegTimesCircle />
          </button>
        )}
        <button className="bg-[#061d5a] text-white rounded-3xl semi-bold px-10 py-1" onClick={submit}>
          Save
        </button>
      </div>
      <NoteModal setOpenNote={setOpenNote} setNote={setNote} handleClose={handleClose} open={openNote} note={note} />
    </>
  )
}

export default CapexTable
