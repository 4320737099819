import React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useDispatch } from "react-redux"
// import { getOneBudget } from "../../../../Redux/slices/budgetSlice"
import { useParams } from "react-router-dom"

const MainAutoCompleteComponent = ({ value, setValue, myOptions, myLabel }) => {
  const [inputValue, setInputValue] = React.useState("")

  const onChangeEmployee = (event, newValue) => {
    setValue({ target: { value: newValue.id } })
  }
  console.log(value)

  return (
    <div className="flex gap-7 w-full my-2">
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          onChangeEmployee(event, newValue)
        }}
        // we are getting label from the myOptions prop
        getOptionLabel={(option) => {
          return option.name
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        id="controllable-states-demo"
        // we are showing options from the myOptions prop
        options={myOptions}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label={myLabel} />}
      />
    </div>
  )
}

export default MainAutoCompleteComponent
