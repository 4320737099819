import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { resetLoginUser } from "../../Redux/slices/loginSlice"

export default function ProtectedRoute({ children }) {
  // const { userInfo } = useSelector((state) => state.loginInfo)
  let userInfo = JSON.parse(localStorage.getItem("userInfo"))

  const dispatch = useDispatch()

  if (userInfo) {
    if (localStorage.getItem("userInfo")) {
      // const jwtPayload = JSON.parse(window.atob(userInfo.accessToken.split(".")[1]))
      // const exp = new Date(jwtPayload.exp * 1000 - 60000)
      let exp = userInfo.expiry
      // let exp = "2022-12-09T14:42:55.340618Z" //for testing
      if (new Date(exp) < Date.now()) {
        dispatch(resetLoginUser())
        localStorage.removeItem("userInfo")
        return <Navigate to="/login" />
      }
    }
  }

  return userInfo ? children : <Navigate to="/login" />
}
