import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API } from "../../API/api"
import { addErrorMessage } from "../messageSlice"

/***************** start main budget comments **************************/

/// get one budget comment
export const getDashboardData = createAsyncThunk("budgetComments/get", async (info, thunkAPI) => {
  try {
    let res = await API.get(`/dashboard/${info.params}`)
    console.log(res)
    return res.data
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})

const initialState = {
  dashboardData: [],
  loading: false,
  error: null,
}

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: {
    [getDashboardData.pending]: (state) => {
      state.dashboardData = null
      state.loading = true
    },
    [getDashboardData.fulfilled]: (state, action) => {
      state.loading = false
      state.dashboardData = action.payload
    },
    [getDashboardData.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})

export default dashboardSlice.reducer
