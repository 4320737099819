import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API } from "../API/api"
import { addMessage } from "./messageSlice"

export const insertFile = createAsyncThunk("inputs/insertfile", async (data, thunkAPI) => {
  console.log(data)
  const bodyform = new FormData()
  bodyform.append("excel", data.excel)
  bodyform.append("settings", data.settings)
  // console.log(bodyform)
  const res = await API.post(`/upload/excel/`, bodyform, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  })
  thunkAPI.dispatch(addMessage("filed is uploaded and being processed"))
  return res.data
})
export const getExcelFiles = createAsyncThunk("inputs/getFiles", async (_, thunkAPI) => {
  const res = await API.get(`/upload/excel/`)
  return res.data.results
})
export const getSettings = createAsyncThunk("inputs/getSettings", async () => {
  const res = await API.get(`/upload/excel-model/`)
  return res.data.results
})

const initialState = {
  files: [],
  settings: [],
  uploadedFile: null,
  loading: false,
  loadingUpload: false,
  error: null,
}

export const inputsSlice = createSlice({
  name: "inserting ",
  initialState,
  reducers: {},
  extraReducers: {
    //posting file
    [insertFile.pending]: (state) => {
      state.loadingUpload = true
      state.uploadedFile = null
    },
    [insertFile.fulfilled]: (state, action) => {
      state.loadingUpload = false
      state.uploadedFile = action.payload
    },
    [insertFile.rejected]: (state, action) => {
      state.loadingUpload = false
      state.error = action.error.message
    },
    //getting settings
    [getSettings.pending]: (state) => {
      state.loading = true
    },
    [getSettings.fulfilled]: (state, action) => {
      state.loading = false
      state.settings = action.payload
    },
    [getSettings.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    //getting files
    [getExcelFiles.pending]: (state) => {
      state.loading = true
    },
    [getExcelFiles.fulfilled]: (state, action) => {
      state.loading = false
      state.files = action.payload
    },
    [getExcelFiles.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})

export default inputsSlice.reducer
