import React from "react"
import BarChartFirstRow from "./Charts/BarChartFirstRow"

const index = () => {
  return (
    <>
      <div className="flex flex-wrap justify-center gap-5 my-7 w-full">
        <div className="w-[48%] flex">
          {/* <p className="mb-5 border-b w-[50%] font-semibold pb-3"> Bar Chart Title</p> */}
          <div className="h-auto flex justify-start items-center border-r relative w-20 mx-2">
            <div
              className="absolute w-full lightFont"
              style={{ transform: "rotate(270deg)", whiteSpace: "nowrap", fontFamily: "boldDin", letterSpacing: "1rem", left: "10%", bottom: "20%" }}
            >
              Chart Title
            </div>
          </div>
          <BarChartFirstRow />
        </div>
        <div className="w-[48%] flex">
          {/* <p className="mb-5 border-b w-[50%] font-semibold pb-3"> Bar Chart Title</p> */}
          <div className="h-auto flex justify-start items-center border-r relative w-20 mx-2">
            <div
              className="absolute w-full lightFont"
              style={{ transform: "rotate(270deg)", whiteSpace: "nowrap", fontFamily: "boldDin", letterSpacing: "1rem", left: "10%", bottom: "20%" }}
            >
              Chart Title
            </div>
          </div>
          <BarChartFirstRow />
        </div>
      </div>

      <div className="flex flex-wrap justify-center gap-5 my-7 w-full">
        <div className="w-[48%] flex">
          {/* <p className="mb-5 border-b w-[50%] font-semibold pb-3"> Bar Chart Title</p> */}
          <div className="h-auto flex justify-start items-center border-r relative w-20 mx-2">
            <div
              className="absolute w-full lightFont"
              style={{ transform: "rotate(270deg)", whiteSpace: "nowrap", fontFamily: "boldDin", letterSpacing: "1rem", left: "10%", bottom: "20%" }}
            >
              Chart Title
            </div>
          </div>
          <BarChartFirstRow />
        </div>
        <div className="w-[48%] flex">
          {/* <p className="mb-5 border-b w-[50%] font-semibold pb-3"> Bar Chart Title</p> */}
          <div className="h-auto flex justify-start items-center border-r relative w-20 mx-2">
            <div
              className="absolute w-full lightFont"
              style={{ transform: "rotate(270deg)", whiteSpace: "nowrap", fontFamily: "boldDin", letterSpacing: "1rem", left: "10%", bottom: "20%" }}
            >
              Chart Title
            </div>
          </div>
          <BarChartFirstRow />
        </div>
      </div>
    </>
  )
}

export default index
