import React from "react"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Bar } from "react-chartjs-2"
// import faker from "faker"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  maintainAspectRatio: false,
  indexAxis: "y",

  responsive: true,

  barThickness: 10,
  scales: {
    x: {
      max: 1200,
    },
  },

  plugins: {
    legend: {
      position: "top",
    },
  },
}

const salesData = [
  { label: "Retention", value: 500 },
  { label: "Self-fund", value: 1000 },
  { label: "Insurance", value: 900 },
]
const brokersData = [
  { label: "Retention", value: 400 },
  { label: "Self-fund", value: 1100 },
  { label: "Insurance", value: 700 },
]

export const data = {
  labels: salesData.map((d) => d.label),
  datasets: [
    {
      label: "Sales",
      data: salesData.map((d) => d.value),
      //   borderColor: "rgb(255, 99, 132)",
      backgroundColor: "#0a1033",
      //   barThickness: 10,
      borderRadius: 10,
    },
    {
      label: "Brokers",
      data: brokersData.map((d) => d.value),
      backgroundColor: "#af1027",
      borderRadius: 10,
    },
  ],
}

const VerticalSalesChart = () => {
  return (
    <div className="m-0 p-0" style={{ width: "25rem", height: "18rem" }}>
      <Bar options={options} data={data} />
    </div>
  )
}
export default VerticalSalesChart
