import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API } from "../API/api"
import { updateCfoPreviewManually } from "./budgetSlice"
import { addErrorMessage, addMessage } from "./messageSlice"

/***************** start budget record status **************************/

/// get one budget record comment

//post one budget record comment
export const changeBudgetStatusCfo = createAsyncThunk("budgetRecordStatus/patch", async (info, thunkAPI) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"))
  try {
    const res = await API.patch(`/budget/${info.budgetType}/${info.budgetId}/record/${info.recordId}/status/`, { status: info.status })
    thunkAPI.dispatch(addMessage("status changed successfully"))
    const old = thunkAPI.getState().budgetReducer?.cfoPreviewBudget
    const newPreview = old.map((item) => {
      const newData = item.data.map((item) => (item.id === res.data.id ? (item = res.data) : item))
      return {
        ...item,
        data: newData,
      }
    })
    thunkAPI.dispatch(updateCfoPreviewManually(newPreview))
    return res.data
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})

/***************** end budget record comments **************************/

const initialState = {
  budget: "",
  loading: false,
  error: null,
}

export const budgetStatusSlice = createSlice({
  name: "budgetStatus",
  initialState,
  reducers: {},
  extraReducers: {
    [changeBudgetStatusCfo.pending]: (state) => {
      state.loading = true
    },
    [changeBudgetStatusCfo.fulfilled]: (state, action) => {
      state.loading = false
      state.budgetComments = action.payload
    },
    [changeBudgetStatusCfo.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})

export default budgetStatusSlice.reducer
