import React from "react"
import FirstTable from "./Tables/FirstTable"
import SecondTable from "./Tables/SecondTable"

const index = () => {
  return (
    <div className="flex pl-20  gap-5 justify-between">
      <FirstTable />
    </div>
  )
}

export default index
