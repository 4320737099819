import React, { useEffect, useState } from "react"
import { Routes, Route, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ReactLoading from "react-loading"
import { getOneBudgetTypeCfo } from "../../../../Redux/slices/budgetSlice"
import FilterHeader from "../../../../screens/CFObudgetScreen/CFOpreview/FilterHeader"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"
import { fixNum } from "../../../utils/fixNum"

const PreviewGeneralExpenses = ({ back, id, budgetSD, budgetED }) => {
  const { budgetiId } = useParams()

  var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dispatch = useDispatch()
  const [mounted, setMounted] = useState(false)
  const { loading: loadingBudget, cfoPreviewBudget } = useSelector((state) => state.budgetReducer)
  const { loading, departments } = useSelector((state) => state.deptAndEmployeesReducer)
  const [departmentValue, setDepartmentValue] = React.useState(null)
  const [months, setMonths] = useState([])
  function diff() {
    var arr = []
    var datFrom = new Date(cfoPreviewBudget[0].data[0]?.months[0]?.month)
    var monthsLength = cfoPreviewBudget[0].data[0]?.months?.length
    var datTo = new Date(cfoPreviewBudget[0].data[0]?.months[monthsLength - 1]?.month)
    var fromYear = datFrom.getFullYear()
    var toYear = datTo.getFullYear()
    var diffYear = 12 * (toYear - fromYear) + datTo.getMonth()

    for (var i = datFrom.getMonth(); i <= diffYear; i++) {
      arr.push(monthNames[i % 12])
    }
    return arr
  }

  ////////////getting budget data
  const [rows, setRows] = useState([])
  const [tableRows, setTableRows] = useState([])

  useEffect(() => {
    dispatch(getOneBudgetTypeCfo({ type: "general-expenses", id: budgetiId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (cfoPreviewBudget && mounted) {
      let lastArr = []
      cfoPreviewBudget.forEach((d) => {
        lastArr = [...lastArr, ...d.data]
      })
      setRows(lastArr)
      setTableRows(cfoPreviewBudget)
      setMonths(diff())
    }
    setMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfoPreviewBudget])

  /******** start departments filter *********** */

  useEffect(() => {
    if (mounted) {
      if (departmentValue) {
        setTableRows(cfoPreviewBudget.filter((d) => d.department.id === departmentValue.id))
        let newArr = cfoPreviewBudget.find((d) => d.department.id === departmentValue.id)
        if (newArr) {
          setRows(newArr.data)
        } else {
          setRows([])
        }
      } else {
        let lastArr = []
        cfoPreviewBudget.forEach((d) => {
          lastArr = [...lastArr, ...d.data]
        })
        setRows(lastArr)
        setTableRows(cfoPreviewBudget)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentValue])
  /******** end departments filter *********** */

  /******************************* start calculation funcitons *************************/
  const totalFn = (id) => {
    // let values = Object.values(rows[i]).filter((v, i) => i > 3)
    let values = rows.find((d) => d.id === id).months
    let total = 0
    values.forEach((v) => {
      if (v.amount > 0) {
        total = total + v.amount
      }
    })
    return total
  }

  const superTotalFn = () => {
    let superTotal = 0
    rows.forEach((d) => {
      superTotal = superTotal + totalFn(d.id)
    })
    if (superTotal) return superTotal
    else return 0
  }
  const superTotalDynamic = (key) => {
    let superTotal = 0
    rows.forEach((d) => {
      superTotal = superTotal + d[key]
    })
    if (superTotal) return parseFloat(superTotal).toFixed(2)
    else return 0
  }
  const superIncreaseValue = () => {
    let val = parseInt(((superTotalFn() - superTotalDynamic("expenses")) * 100) / superTotalDynamic("expenses"))

    return val
  }

  const monthTotalFn = (i) => {
    let total = 0
    rows.forEach((v) => {
      if (v.months[i].amount > 0) {
        total = total + v.months[i].amount
      }
    })
    return total
  }

  /******************************* end calculation funcitons *************************/

  const tableRow = tableRows.map((d, i) =>
    d?.data.map((row, i) => (
      <tr key={i}>
        <td className="text-center p-2 border-x border-y ">{row.account.name}</td>
        <td className="text-center p-2 border-x border-y ">{d.department.name}</td>

        <td className="text-center p-2 border-x ">{row.expenses ? fixNum(row.expenses) : " _ "}</td>
        <td className="text-center p-2 border-x ">{totalFn(row.id)}</td>
        {!row.expenses ? <td className="text-center p-2 border-x !bg-[#ddd] border-b "> _ </td> : null}
        {row.expenses && totalFn(row.id) - row.expenses > 0 ? (
          <td className="text-center p-2 border-x !bg-[#af1027] text-white border-b flex !pl-4 items-center gap-3">
            <p className="flex items-center gap-3 w-[3.5vw]">{fixNum(((totalFn(row.id) - row.expenses) * 100) / row.expenses)} %</p>
            <FaArrowUp />
          </td>
        ) : null}
        {row.expenses && totalFn(row.id) - row.expenses < 0 ? (
          <td className="text-center p-2 border-x !bg-[#209648] text-white border-b flex !pl-4 items-center gap-3">
            <p className="flex items-center gap-3 w-[3.5vw]">{fixNum(((totalFn(row.id) - row.expenses) * 100) / row.expenses)} %</p>
            <FaArrowDown />
          </td>
        ) : null}

        {row.months.map((d, index) => (
          <td key={index} style={{ minWidth: "6rem" }} className="text-center p-2 border-x 	 ">
            {rows.find((account) => row.id === account.id).months[index].amount}
            {/* <input
            value={rows.find((account) => row.id === account.id).months[index].amount}
            onChange={(e) => handleChange(e, row.id, index)}
            className="pl-4 border border-black w-10/12"
            type="number"
            min={0}
          /> */}
          </td>
        ))}
      </tr>
    ))
  )

  return (
    <>
      {loadingBudget || loading ? (
        <div className="w-full flex justify-center">
          <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
        </div>
      ) : (
        <>
          <div className="mb-5">
            <FilterHeader value={departmentValue} setValue={setDepartmentValue} departments={departments} />
          </div>
          {rows.length ? (
            <div className="!h-[65vh] overflow-scroll">
              <table className={`w-full border-b tableColumnsWidth h-full  stickyTable inputsTable`}>
                <thead>
                  <tr className="bg-[#061d5a] text-white p-1 stickyRow">
                    <th className=" border-x border-white p-2 bg-[#061d5a] "> Account</th>
                    <th className=" border-x border-white p-2 bg-[#061d5a] "> Department</th>

                    <th className=" border-x border-white p-2 px-10 "> Actual</th>
                    <th className=" border-x border-white p-2 px-10 "> Total</th>
                    <th className=" border-x border-white p-2 ">Increase %</th>
                    {months.map((month, i) => (
                      <th className=" border-x border-white p-2 " key={i}>
                        {" "}
                        {month}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableRow}
                  <tr className="stickyFinalRow ">
                    <td className="border-x  text-center p-2 !border-b">Total</td>
                    <td className="border-x  text-center p-2 border-b"></td>
                    <td className="border-x  text-center p-2 border-b">{fixNum(superTotalDynamic("expenses"))}</td>
                    <td className="border-x  text-center p-2 border-b">{fixNum(superTotalFn())}</td>
                    {!superIncreaseValue() && <td className="text-center p-2 border-x !bg-[#ddd] border-b "> _ </td>}
                    {superIncreaseValue() > 0 && (
                      <td className="border-x bg-[#f00e0e] text-white text-center p-2 border-b">{superIncreaseValue()} %</td>
                    )}
                    {superIncreaseValue() < 0 && (
                      <td className="border-x bg-[#39ab35] text-white text-center p-2 border-b">{superIncreaseValue()} %</td>
                    )}

                    {months.map((month, i) => (
                      <td className="border-x  text-center p-2 border-b" key={i}>
                        {fixNum(monthTotalFn(i))}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center text-xl">No data</div>
          )}
        </>
      )}
    </>
  )
}

export default PreviewGeneralExpenses
