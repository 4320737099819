import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"
import { scaleOrdinal } from "d3-scale"
import { schemeCategory10 } from "d3-scale-chromatic"

const colors = scaleOrdinal(schemeCategory10).range()
const fakeData = [
  { date: "Jan", failed: 3, name: "Jan", lossRatio: 80 },
  { date: "Feb", failed: 2, name: "Feb", lossRatio: 70 },
  { date: "March", failed: 3, name: "March", lossRatio: 60 },
  { date: "April", failed: 2, name: "April", lossRatio: 40 },
  { date: "May", failed: 3, name: "May", lossRatio: 90 },
  //   { date: "June", failed: 2, name: "June", lossRatio: 50, processing: 5, succeeded: 3 },
]

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />
}

TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
}

const CustomBarChart = () => {
  //   static demoUrl = "https://codesandbox.io/s/bar-chart-with-customized-shape-dusth"

  return (
    // <ResponsiveContainer width="10rem" height="10rem">
    <BarChart
      width={300}
      height={230}
      data={fakeData}
      // margin={{
      //   top: 20,
      //   right: 30,
      //   left: 20,
      //   bottom: 5,
      // }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <XAxis dataKey="name" />
      <YAxis />
      <Bar dataKey="lossRatio" fill="#181e3b" shape={<TriangleBar />} label={{ position: "top" }}>
        {fakeData.map((entry, index) => (
          <Cell key={`cell-${index}`} />
        ))}
      </Bar>
    </BarChart>
    // </ResponsiveContainer>
  )
}
export default CustomBarChart
