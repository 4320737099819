import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API } from "../API/api"
import { addErrorMessage, addMessage } from "./messageSlice"

/// cfo budget
export const postCfoBudget = createAsyncThunk("budget/post", async (budget, thunkAPI) => {
  try {
    const res = await API.post(`/budget/base/`, budget)
    thunkAPI.dispatch(addMessage("budget added succefully"))
    return res.data
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})
/// get one budget budget for patch
export const getOneBudgetForPatch = createAsyncThunk("budget/getForpatch", async (data, thunkAPI) => {
  try {
    const res = await API.get(`/budget/base/${data.id}`)
    // console.log(res)
    // thunkAPI.dispatch(addMessage("budget patched succefully"))
    return res.data
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})
/// patch one budget budget patch
export const patchCfoBudget = createAsyncThunk("budget/patch", async (data, thunkAPI) => {
  try {
    const res = await API.patch(`/budget/base/${data.id}`, data.budget)
    thunkAPI.dispatch(addMessage("budget patched succefully"))
    return res.data
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})

///get all budgets
export const getBudgets = createAsyncThunk("budget/get", async () => {
  const res = await API.get(`/budget/base`)
  return res.data.results
})

export const getOneBudget = createAsyncThunk("budgetOne/get", async (info, thunkAPI) => {
  // let userInfo = JSON.parse(localStorage.getItem("userInfo"))
  try {
    const res = await API.get(`/budget/preview/${info.id}/${info.filter}/${info.department ? `?department=${info.department}` : ""}`)
    return res.data
  } catch (error) {
    // console.log(error)
    thunkAPI.dispatch(addErrorMessage(error.message))
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const getBudgetPreviewManager = createAsyncThunk("budgetPreviewManager/get", async (info, thunkAPI) => {
  // const { data } = await API.post(`/auth/login/`, {
  //   username: "cfo@example.com",
  //   password: "cfo",
  // })

  let userInfo = JSON.parse(localStorage.getItem("userInfo"))
  try {
    const res = await API.get(`/budget/preview/manager/`)
    return res.data
  } catch (error) {
    // console.log(error)
    thunkAPI.dispatch(addErrorMessage(error.message))
    return thunkAPI.rejectWithValue(error.message)
  }
})

/*************** start get one budget type cfo ********************* */

export const getOneBudgetTypeCfo = createAsyncThunk("budgetOneTypeCfo/get", async (info) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"))

  const res = await API.get(`/budget/${info.type}/?budget=${info.id}`)
  return res.data.results
})

/*************** end get one budget type cfo ********************* */

///get manager budget
export const getManagerBudget = createAsyncThunk("budget/managerBudget", async (id) => {
  const res = await API.get(`/budget/base/department/${id}`)
  return res.data
})

/*************** start activate budget ********************* */

export const activateBudget = createAsyncThunk("budget/activate", async (id, thunkAPI) => {
  try {
    const res = await API.get(`/budget/base/${id}/activate/`)
    thunkAPI.dispatch(addMessage("budget activated succefully"))
    return res.data
  } catch (error) {
    thunkAPI.dispatch(addErrorMessage(error.response.data[0]))
    return thunkAPI.rejectWithValue(error.response.data[0])
  }
})

const initialState = {
  budgets: [],
  budget: "",
  updatedBudget: "",
  managerBudget: "",
  cfoPreviewBudget: "",
  loading: false,
  loadingUpdate: false,
  loadingPreview: false,
  error: null,
}

export const budgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {
    updateCfoPreviewManually: (state, action) => {
      state.cfoPreviewBudget = action.payload
    },
  },
  extraReducers: {
    [postCfoBudget.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [postCfoBudget.fulfilled]: (state, action) => {
      state.loading = false
      state.budget = action.payload
    },
    [postCfoBudget.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    //getting while budget
    [getBudgets.pending]: (state) => {
      state.loading = true
      state.budget = null
    },
    [getBudgets.fulfilled]: (state, action) => {
      state.loading = false
      state.budgets = action.payload
    },
    [getBudgets.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    //getting  budget for patch
    [getOneBudgetForPatch.pending]: (state) => {
      state.loading = true
    },
    [getOneBudgetForPatch.fulfilled]: (state, action) => {
      state.loading = false
      state.updatedBudget = null
      state.budget = action.payload
    },
    [getOneBudgetForPatch.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    // patch budget for cfo
    [patchCfoBudget.pending]: (state) => {
      state.loadingUpdate = true
    },
    [patchCfoBudget.fulfilled]: (state, action) => {
      state.loadingUpdate = false
      state.updatedBudget = action.payload
    },
    [patchCfoBudget.rejected]: (state, action) => {
      state.loadingUpdate = false
      state.error = action.error.message
    },
    //getting manager budget
    [getManagerBudget.pending]: (state) => {
      state.loading = true
    },
    [getManagerBudget.fulfilled]: (state, action) => {
      state.loading = false
      state.managerBudget = action.payload
    },
    [getManagerBudget.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    [getOneBudget.pending]: (state) => {
      state.loadingPreview = true
      state.cfoPreviewBudget = ""
    },
    [getOneBudget.fulfilled]: (state, action) => {
      state.loadingPreview = false
      state.cfoPreviewBudget = action.payload
    },
    [getOneBudget.rejected]: (state, action) => {
      state.loadingPreview = false
      state.error = action.payload
    },
    [getOneBudgetTypeCfo.pending]: (state) => {
      state.loading = true
      state.cfoPreviewBudget = ""
    },
    [getOneBudgetTypeCfo.fulfilled]: (state, action) => {
      state.loading = false
      state.cfoPreviewBudget = action.payload
    },
    [getOneBudgetTypeCfo.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    [getBudgetPreviewManager.pending]: (state) => {
      state.loadingPreview = true
      state.cfoPreviewBudget = null
    },
    [getBudgetPreviewManager.fulfilled]: (state, action) => {
      state.loadingPreview = false
      console.log(action.payload)
      state.cfoPreviewBudget = action.payload
    },
    [getBudgetPreviewManager.rejected]: (state, action) => {
      state.loadingPreview = false
      state.error = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateCfoPreviewManually } = budgetSlice.actions

export default budgetSlice.reducer
