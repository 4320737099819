import React from "react"

const FirstTable = () => {
  return (
    <div className="w-full">
      <p className="border-b mb-5 pb-2 w-[50%]">Title</p>
      <table className="inputsTable w-full">
        <thead>
          <tr className="bg-[#061d5a] stickyRow text-white p-1">
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
            <th className=" border-x border-white p-2 ">Title </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
          </tr>
          <tr>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
            <td className="border-x border-white p-2">PremiumX</td>
          </tr>
        </tbody>{" "}
      </table>
    </div>
  )
}

export default FirstTable
