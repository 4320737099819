import React, { useEffect, useState } from "react"
import styles from "../budget.module.css"
import { FaArrowLeft, FaCheckCircle, FaPenAlt, FaPlus, FaRegTimesCircle } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { getEmployees } from "../../../Redux/slices/dept_employees_slice"
import ReactLoading from "react-loading"
import { getPayrollBudget, patchPayrollBudget } from "../../../Redux/slices/managerBudgetSlice"
import { tax_and_allowance_calc } from "../../../components/pagesSpec/managerBudget/PayrollCalc"
import NoteModal from "../../../components/compound components/Modals/NoteModal"
import Tooltip from "@mui/material/Tooltip"
import CommentsSideBar from "../CommentsSideBar/CommentsSideBar"
import StatusMenu from "../../CFObudgetScreen/budgetTables/StatusMenu"
import { fixNum } from "../../../components/utils/fixNum"

const PayrollTable = ({ back, id, budgetSD, budgetED }) => {
  let { user } = JSON.parse(localStorage.getItem("userInfo"))
  const dispatch = useDispatch()
  // const { loading, employees } = useSelector((state) => state.deptAndEmployeesReducer)
  const { budget, loadingUpdate, loading, updatedBudget } = useSelector((state) => state.managerBudgetReducer)

  const [mounted, setMounted] = useState(false)

  /************** start getting emlpyees *************************/
  const [rows, setRows] = useState([])
  const [cap, setCap] = useState("")
  const [note, setNote] = useState("")
  const [openNote, setOpenNote] = React.useState(false)
  console.log(budget)

  const handleClose = () => {
    setOpenNote(false)
  }

  useEffect(() => {
    dispatch(getPayrollBudget(id))
  }, [])
  useEffect(() => {
    if (budget && mounted) {
      // const data = budget.data.map((d) => d.employee)
      setCap(budget.cap)
      setRows(budget.data)
    }
  }, [budget])

  useEffect(() => {
    if (updatedBudget && mounted) {
      back()
    }
    setMounted(true)
  }, [updatedBudget])

  /************** end getting emlpyees *********************/

  ///////// handle input change
  const handleChange = (e, index, name) => {
    let value = e.target.value
    if (name === "gross_increase" || name === "allowance_increase") {
      value = e.target.value * 0.01
    }

    let newState = [...rows]
    newState[index] = {
      ...newState[index],
      [name]: value,
    }

    setRows(newState)
  }

  /////

  /*************************** start calculation functions **********************/
  const grossIncreaseFn = (i) => {
    if (rows[i].gross_increase) {
      let value = rows[i].employee.gross * (rows[i].gross_increase + 1)
      return parseInt(value)
    } else return parseInt(rows[i].employee.gross)
  }

  const allowanceIncreaseFn = (i) => {
    if (rows[i].allowance_increase) {
      let value = rows[i].employee.allowance * (rows[i].allowance_increase + 1)
      return parseInt(value)
    } else {
      return parseInt(rows[i].employee.allowance)
    }
  }

  /************** start super total function   ************** */
  const superTotalFn = () => {
    // tax_and_allowance_calc(row.employee, grossIncreaseFn(i)).nettax_and_allowance_calc(row.employee, grossIncreaseFn(i)).net

    let superTotalGross = 0
    let superTotalAllowance = 0
    let superTotalNet = 0
    let superTotalTax = 0
    let superTotalActualGross = 0
    let superTotalActualAllowance = 0
    let superTotalActualNet = 0
    let superTotalActualTax = 0
    rows.forEach((row, i) => {
      superTotalGross = superTotalGross + grossIncreaseFn(i)
      superTotalAllowance = superTotalAllowance + allowanceIncreaseFn(i)
      superTotalNet = superTotalNet + tax_and_allowance_calc(row.employee, grossIncreaseFn(i), allowanceIncreaseFn(i)).net
      superTotalTax = superTotalTax + tax_and_allowance_calc(row.employee, grossIncreaseFn(i), allowanceIncreaseFn(i)).tax
      superTotalActualGross = superTotalActualGross + row.employee.gross
      superTotalActualAllowance = superTotalActualAllowance + row.employee.allowance
      superTotalActualNet = superTotalActualNet + row.employee.net
      superTotalActualTax = superTotalActualTax + row.employee.tax
    })
    return {
      superTotalGross,
      superTotalNet,
      superTotalTax,
      superTotalAllowance,
      superTotalActualGross,
      superTotalActualAllowance,
      superTotalActualNet,
      superTotalActualTax,
    }
  }

  /************** end super total function   ************** */

  const capCalcFn = () => {
    let superTotal = 0
    rows.forEach((row, i) => {
      if (grossIncreaseFn(i)) {
        superTotal = superTotal + grossIncreaseFn(i) - row.employee.gross
      }
    })
    if (superTotal) return superTotal
    else return 0
  }
  /*************************** end calculation functions **********************/

  const submit = () => {
    dispatch(
      patchPayrollBudget({
        id,
        update: { data: rows },
      })
    )
  }
  const submitDone = (val) => {
    dispatch(
      patchPayrollBudget({
        id,
        update: { done: true, data: rows },
      })
    )
  }

  /////
  console.log(budget)

  return (
    <>
      {/* <div className="w-full px-10 border-b-2 pb-3 mb-5">
        <h2 className="semi-bold text-lg lightFont">Budget</h2>
      </div> */}
      <div className="w-full px-5 semi-bold border-b-2 pb-2 mb-4 flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-center">
          <p className="mb-2"> Payroll Budget</p>
          <CommentsSideBar type="budget" budgetType="payroll" budgetId={budget.id}>
            <Tooltip title="Add comment" arrow>
              <button className="hover:bg-[#071C59] hover:text-white flex gap-2 items-center text-black flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out">
                <FaPenAlt style={{ width: "90%" }} />
              </button>
            </Tooltip>
          </CommentsSideBar>
        </div>

        {!loading && budget && (
          <div className="flex gap-3 items-center">
            {capCalcFn() > cap && <div className="bg-[#e7cb02] rounded-xl px-3 py-1"> You exceeded the cap!</div>}
            <div>
              Your cap for payroll is <span className="text-[#217245]">{fixNum(cap)} EGP</span>
            </div>
          </div>
        )}
      </div>
      {loadingUpdate && (
        <div className="w-full h-3/4 flex flex-col items-center justify-center">
          <ReactLoading type="bars" color="#061e5b" width={"6%"} height={"6%"} />
          <h2 className="mt-2 text-xl text-[#061e5b]">Sending budget</h2>
        </div>
      )}
      {!loadingUpdate && (
        <>
          {loading || !rows.length ? (
            <div className="w-full flex justify-center">
              <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
            </div>
          ) : (
            <>
              <div className="!h-[65vh] overflow-scroll">
                <table className={`w-full border-b stickyTable  ${styles.inputsTable}`}>
                  <thead>
                    <tr className="bg-[#061d5a] stickyRow text-white p-1">
                      <th className=" border-x border-white p-2 "> </th>
                      <th className=" border-x border-white p-2 "> HR code</th>
                      <th className=" border-x border-white p-2 "> Name</th>
                      <th className=" border-x border-white p-2 "> Gross</th>
                      <th className=" border-x border-white p-2 ">Allowance </th>
                      <th className=" border-x border-white p-2 "> Tax</th>
                      <th className=" border-x border-white p-2 "> Net</th>
                      <th className=" border-x border-white p-2 "> Gross Increase (%)</th>
                      <th className=" border-x border-white p-2 "> Allowance Increase (%)</th>
                      <th className=" border-x border-white p-2 "> Increased Gross</th>
                      <th className=" border-x border-white p-2 "> Increased Allowance</th>
                      <th className=" border-x border-white p-2 "> Increased Tax</th>
                      <th className=" border-x border-white p-2 "> Increased Net</th>
                      <th className=" border-x border-white p-2 "> Effective date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, i) => (
                      <tr key={i} className={`${row.status === "ACCEPTED" && `acceptedRow`} ${row.status === "REJECTED" && "rejectedRow"}`}>
                        <td className={`border-x  text-center border-y p-0 "}`}>
                          <div className="flex justify-center items-center">
                            <div className="flex items-center ">
                              <CommentsSideBar type="record" budgetType="payroll" budgetId={budget.id} recordId={row.id}>
                                <button
                                  className="hover:bg-[#071C59] hover:text-white flex gap-2 items-center text-black flex items-center justify-center rounded-full semi-bold p-1 text-sm transition duration-300 ease-in-out"
                                  onClick={() => {}}
                                >
                                  <FaPenAlt style={{ width: "0.7rem" }} />
                                </button>
                              </CommentsSideBar>
                            </div>
                          </div>
                        </td>
                        <td className="border-x w-[7vw]  text-center p-2">{row.employee.hr_code}</td>
                        <td className=" border-x  text-center p-2">{row.employee.name} </td>
                        <td className=" border-x  text-center p-2">{fixNum(row.employee.gross)} </td>
                        <td className=" border-x  text-center p-2">{fixNum(row.employee.net)}</td>
                        <td className=" border-x  text-center p-2">{fixNum(row.employee.tax)} </td>
                        <td className="border-x  text-center p-2">{fixNum(row.employee.allowance)}</td>
                        <td className="text-center p-2 border-x ">
                          <input
                            onChange={(e) => handleChange(e, i, "gross_increase")}
                            className="pl-4 border border-black w-10/12"
                            defaultValue={row.gross_increase / 0.01}
                            // value={row.gross_increase * 100}
                            type="number"
                            min={0}
                            placeholder="increase"
                          />
                        </td>
                        <td className="text-center p-2 border-x ">
                          <input
                            onChange={(e) => handleChange(e, i, "allowance_increase")}
                            className="pl-4 border border-black w-10/12"
                            defaultValue={row.allowance_increase / 0.01}
                            // value={row.gross_increase * 100}
                            type="number"
                            min={0}
                            placeholder="increase"
                          />
                        </td>
                        <td className="text-center p-2 border-x ">{fixNum(grossIncreaseFn(i))}</td>
                        <td className="text-center p-2 border-x ">
                          {fixNum(tax_and_allowance_calc(row.employee, grossIncreaseFn(i), allowanceIncreaseFn(i)).net)}
                        </td>
                        <td className="text-center p-2 border-x ">
                          {fixNum(tax_and_allowance_calc(row.employee, grossIncreaseFn(i), allowanceIncreaseFn(i)).tax)}
                        </td>
                        <td className="text-center p-2 border-x ">{fixNum(allowanceIncreaseFn(i))}</td>

                        <td className="text-center p-2 border-x ">
                          <input
                            onChange={(e) => handleChange(e, i, "effective_date")}
                            min={budgetSD}
                            max={budgetED}
                            value={row.effective_date}
                            className="pl-1 border border-black w-12/12"
                            type="date"
                            placeholder="trial"
                          />
                        </td>
                      </tr>
                    ))}
                    <tr className="stickyFinalRow">
                      <td className="border-x  text-center p-2">Total</td>
                      <td className="border-x  text-center p-2"></td>
                      <td className="border-x  text-center p-2"></td>
                      <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalActualGross)} </td>
                      <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalActualAllowance)} </td>
                      <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalActualTax)} </td>
                      <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalActualNet)} </td>
                      <td className="border-x  text-center p-2"></td>
                      <td className="border-x  text-center p-2"></td>
                      <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalGross)}</td>
                      <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalAllowance)}</td>
                      <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalTax)}</td>
                      <td className="border-x  text-center p-2">{fixNum(superTotalFn().superTotalNet)}</td>
                      <td className="border-x  text-center p-2"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full my-4 gap-2  flex justify-end">
                {!budget.done ? (
                  <button
                    className="bg-[#39ab35] flex items-center gap-2 text-white rounded-3xl semi-bold px-10 py-1  transition ease-in-out duration-300"
                    onClick={() => submitDone(true)}
                  >
                    mark as Done <FaCheckCircle />
                  </button>
                ) : (
                  <button
                    className="bg-[#f00e0e] flex items-center gap-2 text-white rounded-3xl semi-bold px-10 py-1"
                    onClick={() => submitDone(false)}
                  >
                    unmark as Done <FaRegTimesCircle />
                  </button>
                )}
                <button className="bg-[#061d5a] text-white rounded-3xl semi-bold px-10 py-1" onClick={submit}>
                  Save
                </button>
              </div>
            </>
          )}
        </>
      )}
      <NoteModal setOpenNote={setOpenNote} setNote={setNote} handleClose={handleClose} open={openNote} note={note} />
    </>
  )
}

export default PayrollTable
