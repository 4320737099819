import * as React from "react"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
}

export default function RequestModalReport({ open, handleClose }) {
  const [fromDate, setFromDate] = React.useState(dayjs(new Date()))
  const [toDate, setToDate] = React.useState(dayjs(new Date()))
  const startDate = new Date()
  const [reportType, setReportType] = React.useState("")
  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <h2 className="semi-bold  lightFont py-2 border-b-2">Request New Report</h2>
          <p className="mb-3 mt-8">Report type</p>
          <div className="flex gap-3 w-full ">
            {/* <div className="flex flex-col"> */}
            <select defaultValue="" className="border-2 rounded-lg pointer  pr-14 pl-2" onChange={(e) => setReportType(e.target.value)}>
              <option value="" disabled>
                Type
              </option>
              <option value="sales">Report type</option>
              <option value="sales">Report type</option>
              <option value="sales">Report type</option>
            </select>
            <select defaultValue="" className="border-2 rounded-lg pointer  pr-14 pl-2" onChange={(e) => setReportType(e.target.value)}>
              <option value="" disabled>
                Production Policy
              </option>
              <option value="sales">Retention</option>
              <option value="sales">Self-fund</option>
              <option value="sales">Managment</option>
            </select>
            {/* </div> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                minDate={startDate}
                label="To"
                inputFormat="DD/MM/YYYY"
                value={toDate}
                onChange={(newValue) => setToDate(new Date(newValue))}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="w-100 flex justify-end mt-14">
            <button className="bg-[#fff] text-[#061d5a] border border-[#061d5a] rounded-3xl  px-10 py-1 m-2" onClick={() => handleClose()}>
              Cancel
            </button>
            <button
              className="bg-[#061d5a] text-white rounded-3xl semi-bold px-10 py-2 m-2"
              onClick={() => {
                // setEmployeesTableView(true)
              }}
            >
              Send request
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
