import React, { useState } from "react"
import { fixNum } from "../../../../../../utils/fixNum"

const SecondTable = ({ dashboardData }) => {
  // let rows = []
  // dashboardData.columns.forEach((column) => {
  //   column.values.forEach((value, i) => {
  //     if (!rows[i]) {
  //       rows[i] = {}
  //     }
  //     rows[i][column.header] = value
  //   })
  // })

  return (
    <div className="w-full">
      <p className="border-b mb-5 pb-2 w-[50%]">{dashboardData.title}</p>
      <table className="inputsTable w-full">
        <thead>
          <tr className="bg-[#061d5a] stickyRow text-white p-1">
            {dashboardData?.headers.map((header, index) => (
              <th className=" border-x border-white p-2 ">{header} </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dashboardData?.data.length ? (
            <>
              {dashboardData?.data?.map((row) => (
                <tr>
                  {Object.values(row).map((value) => (
                    <td className="border-x border-white p-2 text-center">{typeof value === "number" ? fixNum(value) : value}</td>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <p className="m-4">no data to be shown</p>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default SecondTable
