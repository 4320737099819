import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API } from "../API/api"
import { addMessage } from "./messageSlice"

/////////////// 1-payroll budget
export const getPayrollBudget = createAsyncThunk("budget/payrollBudget", async (id) => {
  const res = await API.get(`/budget/payroll/${id}`)
  return res.data
})
export const patchPayrollBudget = createAsyncThunk("budget/payrollBudgetPatch", async (data, thunkAPI) => {
  let res = " "
  if (data.update.done) {
    res = await API.get(`/budget/payroll/${data.id}/toggle-done/`)
  } else {
    res = await API.patch(`/budget/payroll/${data.id}/configure/`, data.update)
  }
  thunkAPI.dispatch(addMessage("budget updated succefully"))

  return res.data
})
//////////////////////////

/// 2- capex budget
export const getCapexBudget = createAsyncThunk("budget/capexBudget", async (id) => {
  const res = await API.get(`/budget/capex/${id}`)
  return res.data
})
export const patchCapexBudget = createAsyncThunk("budget/capexBudgetPatch", async (data, thunkAPI) => {
  let res = " "
  if (data.update.done) {
    res = await API.get(`/budget/capex/${data.id}/toggle-done/`)
  } else {
    res = await API.patch(`/budget/capex/${data.id}/configure/`, data.update)
  }
  thunkAPI.dispatch(addMessage("budget updated succefully"))
  return res.data
})
//////////////////////////

/// 3-hiring budget
export const getHiringBudget = createAsyncThunk("budget/hiringBudget", async (id) => {
  const res = await API.get(`/budget/new-hire/${id}`)
  return res.data
})

export const patchHiringBudget = createAsyncThunk("budget/HiringBudgetPatch", async (data, thunkAPI) => {
  let res = " "
  if (data.update.done) {
    res = await API.get(`/budget/new-hire/${data.id}/toggle-done/`)
  } else {
    res = await API.patch(`/budget/new-hire/${data.id}/configure/`, data.update)
  }
  thunkAPI.dispatch(addMessage("budget updated succefully"))
  return res.data
})
///////////////////////////

/// 4- general expenses budget
export const getGeneralExpensesBudget = createAsyncThunk("budget/geBudget", async (id) => {
  const res = await API.get(`/budget/general-expenses/${id}`)

  return res.data
})

export const patchGeneralExpensesBudget = createAsyncThunk("budget/patchGeneralExpensesBudget", async (data, thunkAPI) => {
  let res = " "
  if (data.update.done) {
    res = await API.get(`/budget/general-expenses/${data.id}/toggle-done/`)
  } else {
    res = await API.patch(`/budget/general-expenses/${data.id}/configure`, data.update)
  }

  thunkAPI.dispatch(addMessage("budget updated succefully"))

  return res.data
})
///////////////////////////

/// 5- training budget
export const getTrainingBudget = createAsyncThunk("budget/trainingBudget", async (id) => {
  const res = await API.get(`/budget/training/${id}`)
  return res.data
})
export const patchTrainingBudget = createAsyncThunk("budget/patchTrainingBudget", async (data, thunkAPI) => {
  let res = " "
  if (data.update.done) {
    res = await API.get(`/budget/training/${data.id}/toggle-done/`)
  } else {
    res = await API.patch(`/budget/training/${data.id}/configure`, data.update)
  }
  thunkAPI.dispatch(addMessage("budget updated succefully"))

  return res.data
})

///////////////////////////

const initialState = {
  budget: "",
  updatedBudget: "",
  loadingUpdate: false,
  loading: false,
  error: null,
}

export const budgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {},
  extraReducers: {
    // 1-payroll
    [getPayrollBudget.pending]: (state) => {
      state.loading = true
      state.updatedBudget = ""
      state.error = null
    },
    [getPayrollBudget.fulfilled]: (state, action) => {
      state.loading = false
      state.budget = action.payload
    },
    [getPayrollBudget.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [patchPayrollBudget.pending]: (state) => {
      state.loadingUpdate = true
      state.error = null
    },
    [patchPayrollBudget.fulfilled]: (state, action) => {
      state.loadingUpdate = false
      state.updatedBudget = action.payload
      state.budget = action.payload
    },
    [patchPayrollBudget.rejected]: (state, action) => {
      state.loadingUpdate = false
      state.error = action.error.message
    },
    //////////////////////////
    // 2-hiring
    [getHiringBudget.pending]: (state) => {
      state.loading = true
      state.updatedBudget = ""
      state.error = null
    },
    [getHiringBudget.fulfilled]: (state, action) => {
      state.loading = false
      state.budget = action.payload
    },
    [getHiringBudget.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [patchHiringBudget.pending]: (state) => {
      state.loadingUpdate = true
      state.error = null
    },
    [patchHiringBudget.fulfilled]: (state, action) => {
      state.loadingUpdate = false
      state.updatedBudget = action.payload
      state.budget = action.payload
    },
    [patchHiringBudget.rejected]: (state, action) => {
      console.log(action)
      state.loadingUpdate = false
      state.error = action.error.message
    },
    //////////////////////////////////////
    // 3- training
    [getTrainingBudget.pending]: (state) => {
      state.loading = true
      state.error = null
      state.updatedBudget = ""
    },
    [getTrainingBudget.fulfilled]: (state, action) => {
      state.loading = false
      state.budget = action.payload
    },
    [getTrainingBudget.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [patchTrainingBudget.pending]: (state) => {
      state.loadingUpdate = true
      state.error = null
    },
    [patchTrainingBudget.fulfilled]: (state, action) => {
      state.loadingUpdate = false
      state.updatedBudget = action.payload
      state.budget = action.payload
    },
    [patchTrainingBudget.rejected]: (state, action) => {
      state.loadingUpdate = false
      state.error = action.error.message
    },
    ///////////////////////////// 4- capex
    [getCapexBudget.pending]: (state) => {
      state.loading = true
      state.error = null
      state.updatedBudget = ""
    },
    [getCapexBudget.fulfilled]: (state, action) => {
      state.loading = false
      state.budget = action.payload
    },
    [getCapexBudget.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [patchCapexBudget.pending]: (state) => {
      state.loadingUpdate = true
      state.error = null
    },
    [patchCapexBudget.fulfilled]: (state, action) => {
      state.loadingUpdate = false
      state.updatedBudget = action.payload
      state.budget = action.payload
    },
    [patchCapexBudget.rejected]: (state, action) => {
      state.loadingUpdate = false
      state.error = action.error.message
    },
    ///////////////////// 5- general expenses
    [getGeneralExpensesBudget.pending]: (state) => {
      state.loading = true
      state.error = null
      state.updatedBudget = ""
    },
    [getGeneralExpensesBudget.fulfilled]: (state, action) => {
      state.loading = false
      state.budget = action.payload
    },
    [getGeneralExpensesBudget.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [patchGeneralExpensesBudget.pending]: (state) => {
      state.loadingUpdate = true
      state.error = null
    },
    [patchGeneralExpensesBudget.fulfilled]: (state, action) => {
      state.loadingUpdate = false
      state.updatedBudget = action.payload
      state.budget = action.payload
    },
    [patchGeneralExpensesBudget.rejected]: (state, action) => {
      state.loadingUpdate = false
      state.error = action.error.message
    },
  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = postsSlice.actions

export default budgetSlice.reducer
