import React, { useEffect, useState } from "react"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import PreviewPayrollCfo from "../PreviewCfoBudget/PreviewPayrollCfo"
import MainCfoPreviewTab from "../PreviewCfoBudget/MainCfoPreviewTab"
import { getDepartments } from "../../../../Redux/slices/dept_employees_slice"
import { useDispatch } from "react-redux"
import PreviewHiringCfo from "../PreviewCfoBudget/PreviewHiringCfo"
import PreviewTrainingCfo from "../PreviewCfoBudget/PreviewTrainingCfo"
import PreviewCapexCfo from "../PreviewCfoBudget/PreviewCapexCfo"
import PreviewGeneralExpenses from "../PreviewCfoBudget/PreviewGeneralExpenses"
import EditBudgetTable from "../../../../screens/CFObudgetScreen/budgetTables/EditBudgetTable"
import { useParams } from "react-router-dom"

const CfoBudgetTabs = () => {
  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
  }
  const { state } = useParams()
  const dispatch = useDispatch()
  const [value, setValue] = useState(state === "open" ? "0" : "1")

  useEffect(() => {
    dispatch(getDepartments())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 0, borderColor: "divider", width: "100%" }}>
        <TabList
          TabIndicatorProps={{ sx: { height: 4, backgroundColor: "#af1027" } }}
          sx={{
            "& button": { fontSize: "80%" },
            // width: "100%",
            "& button.Mui-selected": { fontWeight: "600", color: "#af1027" },
          }}
          onChange={handleChangeTab}
          aria-label="lab API tabs example"
        >
          {state === "open" && <Tab label="Edit" value="0" />} <Tab label="Preview" value="1" />
          <Tab label="Payroll" value="2" />
          <Tab label="Hiring" value="3" />
          <Tab label="Training" value="4" />
          <Tab label="Capex" value="5" />
          <Tab label="General Expenses" value="6" />
          {/* <Tab label="Category 4" value="4" /> */}
        </TabList>
      </Box>

      <>
        <TabPanel value="0" index={1}>
          <EditBudgetTable />
        </TabPanel>
        <TabPanel value="1" index={1}>
          <MainCfoPreviewTab />
        </TabPanel>
        <TabPanel value="2" index={2}>
          <PreviewPayrollCfo state={state} />
        </TabPanel>
        <TabPanel value="3" index={3}>
          <PreviewHiringCfo state={state} />
        </TabPanel>
        <TabPanel value="4" index={3}>
          <PreviewTrainingCfo state={state} />
        </TabPanel>
        <TabPanel value="5" index={3}>
          <PreviewCapexCfo state={state} />
        </TabPanel>
        <TabPanel value="6" state={state} index={3}>
          <PreviewGeneralExpenses />
        </TabPanel>
      </>
    </TabContext>
  )
}

export default CfoBudgetTabs
