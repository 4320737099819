import React, { useState } from "react"
import styles from "../inputs.module.css"
import uploadsvg from "../../../assets/models-images/uploadimg.svg"
import devicesvg from "../../../assets/models-images/device.svg"
import { ReactComponent as ExcelSvg } from "../../../assets/excelIcon.svg"
import { readExcel } from "../../../components/upload/uploadExcel"
import { useDispatch } from "react-redux"
import { insertFile } from "../../../Redux/slices/inputsSlice"

const SecondStep = ({ handleComplete, setData, files, setFiles, selectedType }) => {
  const [path, setPath] = useState("")

  // const handleFiles = async (e) => {
  //   setPath(e.target.files[0].name)
  //   let newFiles = await uploadImage(e)
  //   setFiles(newFiles)
  // }

  const dispatch = useDispatch()
  // const handleInsert = () => {
  //   dispatch(
  //     insertFile({
  //       excel: files,
  //       settings: selectedType.default_settings.id,
  //     })
  //   )
  // }
  return (
    <div className="w-full px-20">
      {files && <h3 className="my-3 mb-8 text-lg lightFont">Please select your excel sheet</h3>}
      {files ? (
        <div className="flex i justify-center w-full">
          <div className="flex  items-center justify-between border-2 rounded-lg w-3/4 px-5 ">
            <div className="flex items-center gap-3">
              <ExcelSvg className="w-14 h-20" />
              <p> {path}</p>
            </div>
            <div>
              <button className="-white rounded-3xl border-[#000000] border	mx-4 px-5 py-1" onClick={() => setFiles("")}>
                Change file
              </button>
              {/* <button className="bg-[#061d5a] text-white rounded-3xl semi-bold	 px-10 py-1" onClick={handleComplete}> */}
              <button className="bg-[#061d5a] text-white rounded-3xl semi-bold	 px-10 py-1" onClick={handleComplete}>
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <label htmlFor="uploadedimg">
          <div className={styles.innerUploadZone}>
            <input
              onChange={(e) => {
                const file = e.target.files[0]
                setFiles(file)
                setPath(file.name)
                readExcel(file, setData)
              }}
              type="file"
              id="uploadedimg"
              className="hidden"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <img className="m-4" src={uploadsvg} alt="upload" />
            <span className="lightFont">Click here to upload sheet from your device</span>
            <img className={`my-4 ${styles.deviceImg}`} src={devicesvg} alt="add files icon" />
          </div>
        </label>
      )}
    </div>
  )
}

export default SecondStep
