import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

export const data = {
  labels: ["Retinition", "Self-Fund", "Ins Com"],
  datasets: [
    {
      label: "Total is",
      data: [12, 19, 3],
      backgroundColor: ["#1d2448", "#023d68", "#007ea7"],
    },
    // {
    //   label: "Total is",
    //   data: [12, 19, 3],
    //   backgroundColor: ["#1d2448", "#023d68", "#007ea7"],
    // },
  ],
}

export default function DoughnutChart4SecondRow() {
  return (
    <div>
      <p className="border-b">Title</p>
      <div className="m-0 p-0" style={{ width: "20rem", height: "20rem" }}>
        <Doughnut
          options={{
            plugins: {
              legend: {
                position: "right",
              },
            },
          }}
          data={data}
        />
      </div>
    </div>
  )
}
