import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API } from "../API/api"

export const getDepartments = createAsyncThunk("inputs/getDept", async () => {
  const res = await API.get(`/core/department/`)
  return res.data.results
})
export const getEmployees = createAsyncThunk("inputs/getEmployees", async (id) => {
  const res = await API.get(`/core/employee/?section__department=${id}`)
  return res.data.results
})
export const getVacancies = createAsyncThunk("inputs/getVac", async () => {
  const res = await API.get(`/core/vacancy/`)
  return res.data.results
})
export const getTrainings = createAsyncThunk("inputs/getTrainings", async () => {
  const res = await API.get(`/core/training-course/`)
  return res.data.results
})

export const getAssets = createAsyncThunk("inputs/getAssets", async () => {
  const res = await API.get(`/core/asset/`)
  return res.data.results
})
export const getAccounts = createAsyncThunk("inputs/getAccounts", async () => {
  const res = await API.get(`/core/account/`)
  return res.data.results
})
export const getTitles = createAsyncThunk("inputs/getTitles", async () => {
  const res = await API.get(`/core/employee-title/`)
  return res.data.results
})

//get reasons
export const getReasons = createAsyncThunk("inputs/getReasons", async () => {
  const res = await API.get(`/budget/capex-reason/`)
  console.log(res)
  return res.data.results
})
const initialState = {
  posts: [],
  departments: [],
  employees: [],
  vacancies: [],
  training: [],
  assets: [],
  accounts: [],
  titles: [],
  getReasons: [],
  loading: false,
  loadingTrainings: false,
  loadingTitles: false,
  loadingReasons: false,
  error: null,
}

export const inputsSlice = createSlice({
  name: "inserting ",
  initialState,
  reducers: {},
  extraReducers: {
    //posting file
    [getDepartments.pending]: (state) => {
      state.loading = true
    },
    [getDepartments.fulfilled]: (state, action) => {
      state.loading = false
      state.departments = action.payload
    },
    [getDepartments.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    //getting emp
    [getEmployees.pending]: (state) => {
      state.loading = true
    },
    [getEmployees.fulfilled]: (state, action) => {
      state.loading = false
      state.employees = action.payload
    },
    [getEmployees.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    //getting vac
    [getVacancies.pending]: (state) => {
      state.loading = true
    },
    [getVacancies.fulfilled]: (state, action) => {
      state.loading = false
      state.vacancies = action.payload
    },
    [getVacancies.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    //getting training
    [getTrainings.pending]: (state) => {
      state.loadingTrainings = true
    },
    [getTrainings.fulfilled]: (state, action) => {
      state.loadingTrainings = false
      state.training = action.payload
    },
    [getTrainings.rejected]: (state, action) => {
      state.loadingTrainings = false
      state.error = action.error.message
    },
    //getting assets
    [getAssets.pending]: (state) => {
      state.loading = true
    },
    [getAssets.fulfilled]: (state, action) => {
      state.loading = false
      state.assets = action.payload
    },
    [getAssets.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    //getting accounts
    [getAccounts.pending]: (state) => {
      state.loading = true
    },
    [getAccounts.fulfilled]: (state, action) => {
      state.loading = false
      state.accounts = action.payload
    },
    [getAccounts.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
    //getting titles
    [getTitles.pending]: (state) => {
      state.loadingTitles = true
    },
    [getTitles.fulfilled]: (state, action) => {
      state.loadingTitles = false
      state.titles = action.payload
    },
    [getTitles.rejected]: (state, action) => {
      state.loadingTitles = false
      state.error = action.error.message
    },
    //getting reasons
    [getReasons.pending]: (state) => {
      state.loadingReasons = true
    },
    [getReasons.fulfilled]: (state, action) => {
      state.loadingReasons = false
      state.reasons = action.payload
    },
    [getReasons.rejected]: (state, action) => {
      state.loadingReasons = false
      state.error = action.error.message
    },
  },
})

export default inputsSlice.reducer
