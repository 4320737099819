import React from "react"
import { animated, useTransition } from "react-spring"

const FlagMessage = ({ show,message }) => {
  const transition = useTransition(show, {
    // config: { duration: 100 },
    from: { x:100, opacity: 0 },
    enter: { x: 10, opacity: 1 },
    // leave: { x: 100, opacity: 0},

  })
  return (
    <>
      {transition((style, item) =>
      
        item ? (
          <animated.div style={style} className="border-2  border-[#209648] bg-[#209648] text-white  semi-bold rounded-lg text-md px-5 py-1 absolute top-[4%] right-[2%] ">
            {message}
          </animated.div>
        ) : (
          ""
        )
      )}
    </>
  )
}

export default FlagMessage
