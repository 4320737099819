import React from "react"
import styles from "../budget.module.css"
import { animated, useSpring, useTransition } from "react-spring"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import AddBudgetTable from "../budgetTables/AddBudgetTable"
import { FaArrowLeft } from "react-icons/fa"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"

const AddNewBudget = ({ setnewBudgetView }) => {
  const [fromDate, setFromDate] = React.useState(dayjs(new Date()))
  const [toDate, setToDate] = React.useState(dayjs(new Date()))
  const [deadline, setDeadline] = React.useState(dayjs(new Date()))
  const [cfoTableView, setCfoTableView] = React.useState(true)

  const startDate = new Date()

  const handleChange = (newValue) => {
    setFromDate(new Date(newValue))
  }
  const transitionSecond = useTransition(cfoTableView, {
    config: { duration: 100 },
    from: { x: 500, opacity: 0 },
    enter: { x: 0, opacity: 1 },
  })

  /******************* start radion handling ***************** */
  const [radioValue, setRadioValue] = React.useState("default")

  const handleChangeRadio = (event) => {
    setRadioValue(event.target.value)
  }
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full px-10 border-b-2 pb-3 mb-5 flex justify-between">
        <h2 className="semi-bold text-lg lightFont">Budget</h2>
        {/* {cfoTableView && ( */}
        <FormControl>
          <RadioGroup
            value={radioValue}
            onChange={handleChangeRadio}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="default" control={<Radio />} label="Per budget" />
            <FormControlLabel value="department" control={<Radio />} label="Per department" />
          </RadioGroup>
        </FormControl>
        {/* )} */}
      </div>
      <div className="w-full">
        {/* {!cfoTableView && ( */}
        <div className="pb-5 w-full">
          <div>
            {/* <h2 className="mb-7 pb-3 border-b w-6/12 font-medium flex items-center gap-2">
              Select Duration
            </h2> */}
            <div className="flex gap-5">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  // minDate={startDate}
                  label="Starting from"
                  inputFormat="DD/MM/YYYY"
                  value={fromDate}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                  minDate={fromDate}
                  label="Ending on"
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={(newValue) => setToDate(new Date(newValue))}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                  minDate={fromDate}
                  label="Deadline"
                  inputFormat="DD/MM/YYYY"
                  value={deadline}
                  onChange={(newValue) => setDeadline(new Date(newValue))}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          {/* <div className="w-2/5 my-5 flex justify-start">
            <button
              className="bg-[#061d5a] text-white rounded-3xl semi-bold  mr-20 px-10 py-1"
              onClick={() => {
                setCfoTableView(true)
              }}
            >
              Next
            </button>
          </div> */}
        </div>
        {/* )} */}
        <div className="w-full">
          {transitionSecond((style, item) =>
            item ? (
              <animated.div style={style}>
                <AddBudgetTable fromDate={fromDate} radioValue={radioValue} toDate={toDate} deadline={deadline} setCfoTableView={setCfoTableView} />
              </animated.div>
            ) : (
              ""
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default AddNewBudget
