import React, { useEffect, useState } from "react"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { getBudgetPreviewManager, getOneBudget } from "../../../../Redux/slices/budgetSlice"
import ReactLoading from "react-loading"
import { fixNum } from "../../../utils/fixNum"

const PreviewManagerBudget = () => {
  var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  // const months = diff()
  const [months, setMonths] = useState([])
  const [budget, setBudget] = useState("")
  function diff() {
    var arr = []
    var datFrom = new Date(cfoPreviewBudget.data[0]?.months[0]?.month)
    var monthsLength = cfoPreviewBudget.data[0]?.months?.length
    var datTo = new Date(cfoPreviewBudget.data[0]?.months[monthsLength - 1]?.month)
    var fromYear = datFrom.getFullYear()
    var toYear = datTo.getFullYear()
    var diffYear = 12 * (toYear - fromYear) + datTo.getMonth()

    for (var i = datFrom.getMonth(); i <= diffYear; i++) {
      arr.push(monthNames[i % 12])
    }
    return arr
  }
  const dispatch = useDispatch()
  const [mounted, setmounted] = useState(false)
  const { loadingPreview: loadingBudget, cfoPreviewBudget } = useSelector((state) => state.budgetReducer)
  useEffect(() => {
    if (!mounted) {
      // dispatch(getOneBudget({ id: 4, filter: "category" }))
    }
    dispatch(getBudgetPreviewManager())
  }, [])

  useEffect(() => {
    if (mounted && cfoPreviewBudget?.data?.length) {
      setBudget(cfoPreviewBudget)
      setMonths(diff())
    }
    setmounted(true)
  }, [cfoPreviewBudget])

  if (loadingBudget) {
    return (
      <div className="w-full flex justify-center">
        <ReactLoading type="spin" color="" width={"3%"} height={"3%"} />
      </div>
    )
  }

  return (
    <div className="!h-[75vh]  overflow-scroll w-full">
      {!cfoPreviewBudget?.data?.length && <p>no open budget to preview</p>}
      {budget?.data?.length ? (
        <table className=" stickyTable w-full border-b  tableTrial">
          {/* <thead>
           
          </thead> */}
          <tbody>
            <tr className="bg-[#061d5a] text-white p-1 stickyRow">
              <td rowSpan="2" style={{ width: "20%" }} className=" text-center font-bold border-x-2 !bg-[#061d5a] border-white p-2 ">
                <div className="flex justify-center">
                  <p className="w-[10vw] r">Category</p>
                </div>
              </td>
              {months.map((month, index) => (
                <td colSpan="3" className=" text-center font-bold border-x border-white p-2 ">
                  {month}
                </td>
              ))}
            </tr>
            <tr className="stickyRow2">
              {/* <th className=" border-x border-white p-2 ">Category</th> */}
              {months.map((item, index) => (
                // {[...Array(12)].map((item, index) => (
                <>
                  <td style={{ zIndex: "-1" }} key={1} className="text-center p-2 px-7 border-y border-x ">
                    Actual
                  </td>
                  <td key={2} className="text-center p-2 px-7 border-y border-x ">
                    Budget
                  </td>
                  <td key={3} className="text-center p-2  px-7 border-y border-x ">
                    Variance
                  </td>
                </>
              ))}
            </tr>

            {budget.data.map((row) => (
              <tr>
                <td className="text-center p-2 px-7  border-y border-x ">{row.category}</td>

                {row.months?.map((month, index) => (
                  <>
                    <td key={1} className="text-center p-2 border ">
                      {fixNum(month.expenses)}
                    </td>
                    <td key={2} className="text-center p-2 border ">
                      {fixNum(month.budget)}
                    </td>
                    {(!month.expenses || !month.variance) && <td className="text-center p-2 border-x !bg-[#ddd] border-b "> _ </td>}
                    {month.variance < 0 && (
                      <td className="text-center p-2 border-x !bg-[#39ab35] text-white border-b flex !pl-4 items-center gap-3">
                        <p className="flex items-center gap-3 w-[3.5vw]"> {month.variance * 100} %</p>
                        <FaArrowDown />
                      </td>
                    )}
                    {month.variance > 0 && (
                      <td className="text-center p-2 border-x !bg-[#f00e0e] text-white border-b flex !pl-4 items-center gap-3">
                        <p className="flex items-center gap-3 w-[3.5vw]">{month.variance * 100} %</p>
                        <FaArrowUp />
                      </td>
                    )}
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  )
}

export default PreviewManagerBudget
