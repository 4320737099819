import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDashboardData } from "../../../../Redux/slices/dashboardSlices/dashboardSlice"
import Cards from "./Cards"
import FirstDashRows from "./Rows"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

const FirstPageX = () => {
  const { dashboardData, loading } = useSelector((state) => state.dashboardReducer)
  console.log("here is data first page", dashboardData)
  const dispatch = useDispatch()
  const [mounted, setmounted] = useState(false)
  useEffect(() => {
    dispatch(getDashboardData({ params: "revenue/revenue/" }))
    setmounted(true)
  }, [])
  return (
    <div>
      {dashboardData && mounted && (
        <>
          <Cards dashboardData={dashboardData} />
          <FirstDashRows dashboardData={dashboardData} />
        </>
      )}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default FirstPageX
